import { Component, OnInit, Inject, ViewChild, ElementRef, HostListener, OnDestroy } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { FuseConfirmDialogComponent } from 'src/@fuse/components/confirm-dialog/confirm-dialog.component';
import * as $ from 'jquery';
import { round } from 'lodash';
import { CaseDetailComponent } from './case-detail/case-detail.component';
import { ClientComponent } from './client/client.component';
import { RatesComponent } from './rates/rates.component';
import { environment } from 'src/environments/environment';
import { ConfirmPopupComponent } from 'src/app/main/confirm-popup/confirm-popup.component';
import { ContactSelectDialogComponent } from '../../contact/contact-select-dialog/contact-select-dialog.component';
// import { RatesComponent } from '';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith, takeUntil, withLatestFrom } from 'rxjs/operators';
import { UserSelectPopupComponent } from '../user-select-popup/user-select-popup.component';
import { DomSanitizer } from '@angular/platform-browser';
import { ContactDialogComponent } from '../../contact/contact-dialog/contact-dialog.component';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ToolbarServiceService } from 'src/app/layout/components/toolbar/toolbar-service.service';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { BehaviorService } from '@_services/Behavior.service';
import { TimersService } from '@_services/timers.service';
import { TooltipService } from '@_services/tooltip.service';
import { GlobalFunctionsService } from '@_services/global-functions.service';
import { CurrentUser } from 'src/app/_models/CurrentUser';
import { TierPermissionPipe } from '@_pipes/tier-permission.pipe';
import { SocketService } from '@_services/socket.service';
@Component({
    selector: 'app-matter-popup',
    templateUrl: './matter-popup.component.html',
    styleUrls: ['./matter-popup.component.scss'],
    providers: [TierPermissionPipe],

})
export class MatterPopupComponent implements OnInit, OnDestroy {

    deliverablepermission = this.tierpermission.transform("Matter Deliverables");
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
    public saveFamilyData = new BehaviorSubject<boolean>(false);
    testBool = false;
    errorWarningData: any = {};
    errorWarningDataArray: any = {}
    Classdata: any[];
    filteredOptions: Observable<string[]>;
    active: any;
    isLoadingResults: boolean = false;
    action: any;
    rateDetail: any = [];
    isEditMatter: any = "";
    dialogTitle: string;
    isspiner: boolean = false;
    // isDefultMatter: boolean = false;
    FormAction: string;
    classtype: any = 'General';
    isEditRate: boolean = true;
    BILLINGMETHODVAL: any = '';
    GSTTYPEVAL: any = '';
    userType: any = '';
    CorrespondDetail: any = [];
    currentuser = JSON.parse(localStorage.getItem('currentUser'));
    barristerTitle: any = this.currentuser.PRODUCTTYPE == "Solicitor" ? 'Matter Num' : 'Short Name';
    addressDetail: any = {};
    tabTitle: any = '';
    MatterStatusLookupsData: any[];
    GetSetMixRetry: boolean = true;
    @ViewChild(CaseDetailComponent, { static: false }) caseDetailchild: CaseDetailComponent;
    @ViewChild(ClientComponent, { static: false }) Clientchild: ClientComponent;
    @ViewChild(RatesComponent, { static: false }) userRatechild: RatesComponent;
    confirmDialogRef1: MatDialogRef<ConfirmPopupComponent, any>;
    clientField = this.currentuser.PRODUCTTYPE == "Barrister" ? 'Solicitor' : 'Client';
    LeasingDetail: any = {};
    toolTipList: any;
    toolTipPostion = "above";
    step = 0;
    DescData: any[] = []
    SECURITYOPTION: boolean;
    matterDes: any = ''
    ClassNameForDetail: any = ''
    matterAccessTier: any
    isNoneSelect: boolean = false
    MatterDropData: any[] = [];
    @ViewChild('MatterInput1', { static: false }) MatterInput: ElementRef<HTMLInputElement>;
    selectedData: any = [];
    mattername: any = [];
    temVal1: any
    openWebsite: any;
    //use for tier permission
    accessTierPermission: any = JSON.parse(localStorage.getItem('set_tier_permission'));
    matterData: any = {};
    CONTACTTYPE: string = '';
    fxFlexForCol1: number;
    barristerVersionFieldFlag: any = {};
    MATTERVESSELGUID: string = "";
    searchContact: any;
    exportdatavalue: any;
    searchData: any;
    matterlist: any[];
    result: any;
    separatorKeysCodes: number[] = [ENTER, COMMA];
    appPermissions = JSON.parse(localStorage.getItem('app_permissions'));

    ShowContactName: boolean = false;
    DestryedContactdata: Subscription;
    CallHostlistner: boolean = false;
    private _unsubscribeAll$: Subject<void> = new Subject();
    sub1: Subscription;
    sub2: Subscription;
    sub3: Subscription;
    sub: Subscription;
    sub4: Subscription;
    sub5: Subscription;
    sub6: Subscription;
    sub7: Subscription;
    sub8: Subscription;
    sub9: Subscription;
    sub10: Subscription;
    sub11: Subscription;
    sub12: Subscription;
    sub13: Subscription;
    sub14: Subscription;
    sub15: Subscription;
    sub16: Subscription;
    sub17: Subscription;
    sub18: Subscription;
    sub19: Subscription;
    sub20: Subscription;
    currentUser: CurrentUser = JSON.parse(localStorage.getItem('currentUser'));
    EstatetoolTipList: any;
    MultyDeliverables: any=[];
    constructor(
        private _mainAPiServiceService: MainAPiServiceService,
        private toastr: ToastrService,
        private _formBuilder: FormBuilder,
        public dialogRef: MatDialogRef<MatterPopupComponent>,
        public datepipe: DatePipe,
        public _matDialog: MatDialog,
        public behaviorService: BehaviorService,
        @Inject(MAT_DIALOG_DATA) public _data: any,
        private Timersservice: TimersService,
        private sanitizer: DomSanitizer,
        private tierpermission: TierPermissionPipe,
        public tooltipService: TooltipService,
        public globalFunService: GlobalFunctionsService, public toolbarServiceService: ToolbarServiceService, public socketService: SocketService
    ) {

        this.matterAccessTier = this.getPermission_1('Matter Access');
        localStorage.setItem('istrackid', 'MatterPopupComponent');
        let UserData = JSON.parse(localStorage.getItem('currentUser')).PRODUCTTYPE;
        // this.tabTitle = UserData == "Barrister" ? 'Solicitor & Corres' : 'Client & Corres';
        this.tabTitle = UserData == "Barrister" ? 'Correspondents and Parties' : 'Correspondents and Parties';

        this.userType = UserData == 'Barrister' ? 0 : 1;
        this.action = _data.action;

        if (this.currentuser.PRODUCTTYPE == "Barrister") {
            this.fxFlexForCol1 = 25;
            if (this.action == 'new') {
                this.barristerVersionFieldFlag = {
                    ClientNameField: 23,
                    ClientReferenceField: 22
                }
            } else {
                this.barristerVersionFieldFlag = {
                    ClientNameField: 25,
                    ClientReferenceField: 25
                }
            }
        } else {
            if (this.action == 'new') {
                this.fxFlexForCol1 = 46;
            } else {
                this.fxFlexForCol1 = 50;
            }

            this.barristerVersionFieldFlag = {
                ClientNameField: 25,
                ClientReferenceField: 25
            }
        }

        if (this.action === 'edit') {
            this.isEditRate = false;
            this.dialogTitle = 'Update Matter';
            this.clientField = this.currentuser.PRODUCTTYPE == "Barrister" ? 'Instructing Solicitor/Direct Brief' : 'Client';
        } else if (this.action == 'new') {
            this.isEditRate = true;
            this.dialogTitle = 'New Matter'
            this.isLoadingResults = true;
            this.loadDefultMatter({ FIRMGUID: '', OWNERGUID: '', PRIMARYFEEEARNERGUID: '' });
            this.AfterViewInitNewMatter();
            setTimeout(() => {
                this.isLoadingResults = false;
            }, 2000);
        } else {
            this.isEditRate = true;
            this.dialogTitle = 'Duplicate Matter';
        }
        this.isEditMatter = this._data.matterGuid;
        this.sub = this.behaviorService.dialogClose$.subscribe(result => {
            if (result != null) {
                if (result.MESSAGE == 'Not logged in') {
                    this.dialogRef.close(false);
                }
            }
        });
        this.sub4 = this.behaviorService.MatterLoding$.subscribe(result => {
            this.isspiner = result;
        });
        this.sub5 = this.behaviorService.APIretryCall$.subscribe((result) => {
            if (localStorage.getItem('istrackid') == 'MatterPopupComponent' && this.action == 'new' && this.GetSetMixRetry && result['click'] && (result['data']['matter'] || result['data']['lookup'])) {
                this.loadDefultMatter({ FIRMGUID: '', OWNERGUID: '', PRIMARYFEEEARNERGUID: '' });
                this.ngOnInit();
                this.caseDetailchild.ngOnInit();
            }
            if (localStorage.getItem('istrackid') == 'MatterPopupComponent' && this.action == 'edit' && result['click'] && (result['data']['matter'] || result['data']['matter-contact'] || result['data']['user-rate'] || result['data']['activity-rate'] || result['data']['lookup'])) {
                this.ngOnInit();
                this.Clientchild.loadData(this.isEditMatter, '');
                this.caseDetailchild.ngOnInit();
                this.userRatechild.ngOnInit();
            }
            if (localStorage.getItem('istrackid') == 'MatterPopupComponent' && this.GetSetMixRetry == false && result['click'] && result['data']['matter']) {
                this.ondialogSaveClick();
            }
        });
    }
    matterdetailForm: FormGroup;

    /**
     * This function is used to Rate Detals back the data
     * @param event -event data value
     */
    rateDetailBack(event: any) {
        const value = Array.isArray(event);
        if (value) {
            this.rateDetail = event;
        } else {
            this.rateDetail.push(event);
        }
    }

    /**
     * This function is used to Set the tooltip data value. 
     */
    async setTooltipData() {
        let TooltipData = JSON.parse(localStorage.getItem('FieldTooltipData'));
        if (TooltipData && TooltipData['Matter']) {
            this.toolTipList = TooltipData['Matter'];
        } else {
            this.tooltipService.setToolTipData = ('Matter');
        }

        if(this.classtype == 'Estate' || this.classtype == 'Wills, POA & EG' || this.classtype == 'Wills and Estate'){
            if (TooltipData && TooltipData['Estate']) {
                this.EstatetoolTipList = TooltipData['Estate'];
            } else {
                this.tooltipService.setToolTipData = ('Estate');
            }
        }
    };

    /**
     * This function is used to Toggle for update
     */
    ToggleForUpadte() {
        this.testBool = !this.testBool;
        this.setTooltipData()
    };

    /**
     * This unction is used to Save the rate details 
     * @param contactGuid -contact guid data value
     */
    saveRateDetail(contactGuid: any):void {
        this.rateDetail.forEach(value => {
            let details: any = {};
            if (value.type == 1) {
                details = {
                    RATETYPE: value.RATETYPE,
                    LINKGUID: contactGuid,
                    USERID: value.USERID,
                    GSTTYPE: value.GSTTYPE,

                    RATEPERHOUR_1: value.RATEPERHOUR_1,
                    RATEPERHOUR_2: value.RATEPERHOUR_2,
                    RATEPERHOUR_3: value.RATEPERHOUR_3,
                    RATEPERHOUR_4: value.RATEPERHOUR_4,
                    RATEPERHOUR_5: value.RATEPERHOUR_5,
                    RATEPERHOUR_6: value.RATEPERHOUR_6,

                    RATEPERDAY_1: value.RATEPERDAY_1,
                    RATEPERDAY_2: value.RATEPERDAY_2,
                    RATEPERDAY_3: value.RATEPERDAY_3,
                    RATEPERDAY_4: value.RATEPERDAY_4,
                    RATEPERDAY_5: value.RATEPERDAY_5,
                    RATEPERDAY_6: value.RATEPERDAY_6
                };
            } else {
                details = {
                    RATETYPE: value.RATETYPE, LINKGUID: contactGuid, GSTTYPE: value.GSTTYPE, ACTIVITYGUID: value.ACTIVITYGUID, RATEPERUNIT: value.RATEPERUNIT
                };
            }
           this.sub6 = this._mainAPiServiceService.getSetData({ FORMACTION: 'insert', VALIDATEONLY: false, DATA: details }, value.APIUrl).subscribe((response: { CODE: number; STATUS: string; }) => {
                if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
                }
            }, (error: any) => { console.log(error); });
        });
    }

    ngOnDestroy(): void {
        this.behaviorService.setMatterEditData(null);
        this.MATTERVESSELGUID = "";
        this.DestryedContactdata?.unsubscribe();
        this.behaviorService.newContactData$.next(null);
        this._unsubscribeAll$.next();
        this._unsubscribeAll$.complete();
        this.sub1?.unsubscribe();
        this.sub2?.unsubscribe();
        this.sub3?.unsubscribe();
        this.sub?.unsubscribe();
        this.sub4?.unsubscribe();
        this.sub5?.unsubscribe();
        this.sub6?.unsubscribe();
        this.sub7?.unsubscribe();
        this.sub8?.unsubscribe();
        this.sub9?.unsubscribe();
        this.sub10?.unsubscribe();
        this.sub11?.unsubscribe();
        this.sub12?.unsubscribe();
        this.sub13?.unsubscribe();
        this.sub14?.unsubscribe();
        this.sub15?.unsubscribe();
        this.sub16?.unsubscribe();
        this.sub17?.unsubscribe();
        this.sub18?.unsubscribe();
        this.sub19?.unsubscribe();
        this.sub20?.unsubscribe();
    }

    /**
     * This function is used to load the default Matter
     * @param dataDefult -dataDefault Matter
     */
    loadDefultMatter(dataDefult: any):void {
        this.isLoadingResults = true;
        let payload = {
            Action: 'default', VALIDATEONLY: true, DATA: dataDefult
        }
       this.sub7 = this._mainAPiServiceService.getSetData(payload, 'matter').subscribe(res => {
            if (res.CODE == 200 && res.STATUS == "success") {
                this.isLoadingResults = false;
                setTimeout(() => {
                    this.toolTipList = res.DATA.FIELDTIPS;
                }, 500);


                const Matterdata = res.DATA.DEFAULTVALUES;
                if (this.action != 'duplicate') {
                    this.behaviorService.SetDefultMatterData(Matterdata);
                    this.behaviorService.ONCHARGEDISBURSEMENTGST$.next(Matterdata.BILLINGGROUP['ONCHARGEDISBURSEMENTGST'])
                    this.matterdetailForm.patchValue({
                        SHORTNAME: Matterdata['SHORTNAME'],
                        GSTTYPE: Matterdata.BILLINGGROUP['GSTTYPE'],
                        BILLINGMETHOD: Matterdata.BILLINGGROUP['BILLINGMETHOD'],
                        ONCHARGEDISBURSEMENTGST: Matterdata.BILLINGGROUP['ONCHARGEDISBURSEMENTGST'],
                        RATEPERHOUR: round(Matterdata.BILLINGGROUP['RATEPERHOUR']).toFixed(2),
                        RATEPERDAY: round(Matterdata.BILLINGGROUP['RATEPERDAY']).toFixed(2),
                        // ESTIMATEFROMTOTALEXGST:0,
                        // ESTIMATEFROMTOTALINCGST:0,
                        // MAXESTIMATEFROMTOTALEXGST: 0,
                        // MAXESTIMATEFROMTOTALINCGST: 0

                        
                        // ASSISTANTGUID: (Matterdata['ASSISTANTGUID']) ? Matterdata['ASSISTANTGUID'] : '',
                        // ASSISTANTNAME: (Matterdata['ASSISTANTNAME']) ? Matterdata['ASSISTANTNAME'] :'',
                        // PRIMARYFEEEARNERGUID: (Matterdata['PRIMARYFEEEARNERGUID']) ? Matterdata['PRIMARYFEEEARNERGUID'] : '',
                        // PRIMARYFEEEARNERNAME: (Matterdata['PRIMARYFEEEARNERNAME']) ? Matterdata['PRIMARYFEEEARNERNAME'] : '',
                        // OWNERGUID: (Matterdata['OWNERGUID'])? Matterdata['OWNERGUID'] : '',
                        // OWNERNAME: (Matterdata['OWNERNAME'])? Matterdata['OWNERNAME'] : '',

                    });
                    if (this.currentuser.PRODUCTTYPE == "Barrister" && Matterdata.RATEPERHOURGROUP) {
                        this.matterdetailForm.patchValue({

                            RATEPERHOUR_1: Number(Matterdata.RATEPERHOURGROUP['RATEPERHOUR_1']).toFixed(2),
                            RATEPERHOUR_2: Number(Matterdata.RATEPERHOURGROUP['RATEPERHOUR_2']).toFixed(2),
                            RATEPERHOUR_3: Number(Matterdata.RATEPERHOURGROUP['RATEPERHOUR_3']).toFixed(2),
                            RATEPERHOUR_4: Number(Matterdata.RATEPERHOURGROUP['RATEPERHOUR_4']).toFixed(2),
                            RATEPERHOUR_5: Number(Matterdata.RATEPERHOURGROUP['RATEPERHOUR_5']).toFixed(2),
                            RATEPERHOUR_6: Number(Matterdata.RATEPERHOURGROUP['RATEPERHOUR_6']).toFixed(2),
                            RATEPERDAY_1: Number(Matterdata.RATEPERDAYGROUP['RATEPERDAY_1']).toFixed(2),
                            RATEPERDAY_2: Number(Matterdata.RATEPERDAYGROUP['RATEPERDAY_2']).toFixed(2),
                            RATEPERDAY_3: Number(Matterdata.RATEPERDAYGROUP['RATEPERDAY_3']).toFixed(2),
                            RATEPERDAY_4: Number(Matterdata.RATEPERDAYGROUP['RATEPERDAY_4']).toFixed(2),
                            RATEPERDAY_5: Number(Matterdata.RATEPERDAYGROUP['RATEPERDAY_5']).toFixed(2),
                            RATEPERDAY_6: Number(Matterdata.RATEPERDAYGROUP['RATEPERDAY_6']).toFixed(2),
                        })
                    }
                    this.matterdetailForm.controls['ACTIVE'].setValue('Active');
                    // setTimeout(() => {
                    //   let temVal = this.Classdata?.find(c => c['LOOKUPFULLVALUE'] == Matterdata['MATTERCLASS']);
                    this.classtype = "General";
                    if (Matterdata && Matterdata.MATTERCLASS && Matterdata.MATTERCLASS != undefined && Matterdata.MATTERCLASS != null) {
                        this.matterdetailForm.controls['MATTERCLASS'].setValue((Matterdata.MATTERCLASS) ? Matterdata?.MATTERCLASS : this.classtype);
                    } else {
                        this.matterdetailForm.controls['MATTERCLASS'].setValue(this.classtype);
                    }

                    // }, 100);
                } else {
                    this.matterdetailForm.patchValue({
                        SHORTNAME: Matterdata['SHORTNAME']
                    });
                }
            } else if (res.MESSAGE === 'Not logged in') {
                this.isLoadingResults = false;
                this.dialogRef.close(false);
            } else {
                this.isLoadingResults = false;
            }
            this.CheckdefultValidation(res.DATA.VALIDATIONS);
            this.getMatterDropData()
        }, error => {
            this.isLoadingResults = false;
            this.toastr.error(error);
            this.getMatterDropData()
        });
    }
    ngOnInit() {
        this.setTooltipData()
        let MatterStatusLookups = JSON.parse(localStorage.getItem('matter_status_lookups'));
        if (MatterStatusLookups && MatterStatusLookups != null) {
            this.MatterStatusLookupsData = MatterStatusLookups;
            // this.isLoadingResults = false;
        } else {
        this.isLoadingResults = true;
          this.sub8 = this._mainAPiServiceService.getSetData({ 'Action': 'getdata', "Filters": { 'LookupType': 'Matter Status' } }, 'lookup').subscribe(responses => {
                if (responses.CODE === 200 && responses.STATUS === 'success') {
                    this.isLoadingResults = false;
                    this.MatterStatusLookupsData = responses.DATA.LOOKUPS;
                    localStorage.setItem('matter_status_lookups', JSON.stringify(responses.DATA.LOOKUPS));
                } else if (responses.MESSAGE == 'Not logged in') {
                    this.isLoadingResults = false;
                    this.dialogRef.close(false);
                } else {
                    this.isLoadingResults = false;
                }
                // this.isLoadingResults = false;
            });
        }
        this.isLoadingResults = true;
        this.matterFormBuild();
        // let MatterClassLookups = JSON.parse(localStorage.getItem('matter_class_lookups'));
        // if (MatterClassLookups && MatterClassLookups != null) {
        //   this.Classdata = MatterClassLookups;
        //   this.isLoadingResults = false;
        // } else {
      this.sub9 = this._mainAPiServiceService.getSetData({ Action: "getdata", "Filters": { 'LookupType': 'Matter Class', IncludeAll: false } }, 'lookup').subscribe(async responses => {
            if (responses.CODE === 200 && responses.STATUS === 'success') {
                this.isLoadingResults = false;
                this.Classdata = await responses.DATA.LOOKUPS;
                // localStorage.setItem('matter_class_lookups', JSON.stringify(responses.DATA.LOOKUPS));
            } else if (responses.MESSAGE == 'Not logged in') {
                this.isLoadingResults = false;
                this.dialogRef.close(false);
            } else {
                this.isLoadingResults = false;
            }
        });
        // }
        let MatterDescLookups = JSON.parse(localStorage.getItem('matter_desc_lookups'));
        if (MatterDescLookups && MatterDescLookups != null) {
            this.DescData = MatterDescLookups;
            this.filteredOptions = this.f.MATTER.valueChanges.pipe(startWith(''), map(value => this._filter(value)));
        } else {
            this.isLoadingResults = true;
           this.sub10 = this._mainAPiServiceService.getSetData({ Action: 'getdata', "Filters": { 'LookupType': 'Matter Desc' } }, 'lookup').subscribe(responses => {
                if (responses.CODE === 200 && responses.STATUS === 'success') {
                    this.isLoadingResults = false;
                    this.DescData = responses.DATA.LOOKUPS;
                    this.filteredOptions = this.f.MATTER.valueChanges.pipe(startWith(''), map(value => this._filter(value)));
                    localStorage.setItem('matter_desc_lookups', JSON.stringify(responses.DATA.LOOKUPS));
                } else if (responses.MESSAGE == 'Not logged in') {
                    this.isLoadingResults = false;
                    this.dialogRef.close(false);
                } else {
                    this.isLoadingResults = false;
                }
            });
        }
        if (this.action === 'edit' || this.action === 'duplicate') {
            this.behaviorService.setMatterLoding(false);
            this.isLoadingResults = true;
            // this._mainAPiServiceService.getSetData({ "Action": "GetData", "Filters": { "MATTERGUID": this._data.matterGuid, 'GETALLFIELDS': true } }, 'GetMatter').subscribe(response => {
            let Payload = {
                "Action": "GetData",
                "Filters": { MATTERGUID: this._data.matterGuid, 'GETALLFIELDS': true }
            }
           this.sub11 = this._mainAPiServiceService.getSetData(Payload, 'matter').subscribe(response => {



               if (response.CODE === 200 && response.STATUS === 'success') {
                   let matterData = response.DATA.RECORDS[0];
                    this.matterData = response.DATA.RECORDS[0];
                    // this.toolTipList = response.DATA.FIELDTIPS;
                    // if( this.action == 'duplicate'){
                    //   this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
                    //     disableClose: true,
                    //     width: '100%',
                    //   });
                    //   this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to copy Additional Details?';
                    //   this.confirmDialogRef.afterClosed().subscribe(result => {
                    //     if (result) {
                    //       this.behaviorService.setChildTableData({ MATTERGUID: this._data.matterGuid, 'classType': matterData.MATTERCLASS, action: 'getData', CONTACTGUID: matterData.CONTACTGUID ,DuplicateResult:result});
                    //     }else{
                    //       this.behaviorService.setChildTableData({ MATTERGUID: this._data.matterGuid, 'classType': matterData.MATTERCLASS, action: 'getData', CONTACTGUID: matterData.CONTACTGUID,DuplicateResult:result });

                    //     }
                    //   });
                    // }
                    this.behaviorService.setChildTableData({ MATTERGUID: this._data.matterGuid, 'classType': matterData?.MATTERCLASS, action: 'getData', CONTACTGUID: matterData?.CONTACTGUID, mode: this.action });
                    this.behaviorService.MatterData(response.DATA.RECORDS[0]);
                    setTimeout(() => {

                        this.temVal1 = this.Classdata.find(c => c['LOOKUPFULLVALUE'] == matterData.MATTERCLASS);
                        this.classtype = this.temVal1.LOOKUPFULLVALUE;
                        this.matterDes = matterData.MATTERCLASSDESC
                        this.matterdetailForm.controls['MATTERCLASS'].setValue(this.classtype);

                        // get maritime if classtype is 20
                        if (this.classtype == 'Maritime') {
                            // this.getMaritime(this._data.matterGuid)
                        }
                        // ends here ~ get maritime if classtype is 20
                    }, 1000);
                    this.behaviorService.setMatterEditData(matterData);
                    if (this.action != 'duplicate') {
                        this.matterdetailForm.controls['SHORTNAME'].setValue(matterData?.SHORTNAME);
                        this.matterdetailForm.controls['MATTERGUID'].setValue(matterData?.MATTERGUID);
                    }

                    this.matterdetailForm.controls['ACTIVE'].setValue((matterData?.ACTIVE).toString());

                    // let matterClass: any = '';
                    // if (matterData.MATTERCLASS) {
                    //   matterClass = matterData.MATTERCLASS
                    // } else {
                    //   matterClass = matterData.MATTERCLASSDESC
                    // }




                    if (this.action == 'duplicate') {

                        if(this.deliverablepermission.ALLOWED == 1 && this.appPermissions[32]?.Create ==1){
                            this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
                                disableClose: true,
                                width: '100%',
                            });
                            this.confirmDialogRef.componentInstance.confirmMessage = 'Do you want to copy Matter deliverable ?';
                            this.confirmDialogRef.afterClosed().subscribe(result => {
                                if (result) {
                                   
                                    this._mainAPiServiceService.getSetData({Action:'GetData',Filters:{MATTERGUID:this.isEditMatter}}, 'matter-deliverable').pipe(takeUntil(this._unsubscribeAll$)).subscribe((res) => {
                                        if (res.CODE == 200 && res.STATUS == "success") {
                                           this.MultyDeliverables=res.DATA.RECORDS;
                                        }
                                    }, (err) => {
                                        this.isLoadingResults = false;
                                        this.toastr.error(err);
                                    });
    
    
                                }
                                else {
                                    return false
                                }
                            });

                        }

                        this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
                            disableClose: true,
                            width: '100%',
                        });
                        this.confirmDialogRef.componentInstance.confirmMessage = 'Do you want to copy Rate Overrides?';
                        this.confirmDialogRef.afterClosed().subscribe(result => {
                            if (result) {
                                let id = [1, 2, 3]
                                id.forEach(element => {
                                    if (element == 1) {
                                        this.userRatechild.loadRateDate('user-rate', element, result);
                                    } else {
                                        this.userRatechild.loadRateDate('activity-rate', element, result);
                                    }
                                });
                            }
                            else {
                                return false
                            }
                        });


                        this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
                            disableClose: true,
                            width: '100%',
                        });
                        this.confirmDialogRef.componentInstance.confirmMessage = 'Do you want to copy Matter Contacts?';
                        this.confirmDialogRef.afterClosed().subscribe(result => {
                            if (result) {
                                this.Clientchild.loadData(this.isEditMatter, result);
                                // this.Clientchild.loadData(this.isEditMatter);
                            }
                        });

                        // to copy additional details
                        this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
                            disableClose: true,
                            width: '100%',
                        });
                        // this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to copy Additional Details?';
                        this.confirmDialogRef.componentInstance.confirmMessage = 'Do you want to copy Additional Details?';

                        this.confirmDialogRef.afterClosed().subscribe(result => {
                            if (!result) {
                                this.behaviorService.additionalFieldsValues(false);
                                this.behaviorService.additionalFieldsValues(null);
                            } else {
                                this.behaviorService.additionalFieldsValues(true);
                                this.behaviorService.additionalFieldsValues(null);
                            }
                        });
                        // ends here ~ to copy additional details

                    }


                    this.matterdetailForm.controls['MATTER'].setValue(matterData.MATTER);
                    //client
                    this.matterdetailForm.controls['Clientmattertext'].setValue(matterData.CONTACTNAME);
                    this.matterdetailForm.controls['FIRMGUID'].setValue(matterData.FIRMGUID);
                    this.mattername = [this.f.Clientmattertext.value]
                    this.matterdetailForm.controls['CONTACTTYPE'].setValue(matterData.CONTACTTYPE);
                    //matter client
                    this.matterdetailForm.controls['FIRMROLE'].setValue(matterData.FIRMROLE);
                    this.matterdetailForm.controls['OTHERSIDEROLE'].setValue(matterData.OTHERSIDEROLE);
                    this.matterdetailForm.controls['FIRMDONTSHOWADDRESS'].setValue((matterData.FIRMDONTSHOWADDRESS == 0) ? false : true);
                    this.matterdetailForm.controls['RELINECLIENT'].setValue(matterData.RELINECLIENT);
                    this.matterdetailForm.controls['RELINECLIENTCLIENTASPREFIX'].setValue((matterData.RELINECLIENTCLIENTASPREFIX == 0) ? false : true);
                    this.matterdetailForm.controls['RELINECLIENTOTHERSIDEASSUFFIX'].setValue((matterData.RELINECLIENTOTHERSIDEASSUFFIX == 0) ? false : true);
                    this.matterdetailForm.controls['RELINEOTHERPARTIES'].setValue(matterData.RELINEOTHERPARTIES);
                    this.matterdetailForm.controls['RELINEOTHERPARTIESCLIENTASPREFIX'].setValue((matterData.RELINEOTHERPARTIESCLIENTASPREFIX == 0) ? false : true);
                    this.matterdetailForm.controls['RELINEOTHERPARTIESOTHERSIDEASSUFFIX'].setValue((matterData.RELINEOTHERPARTIESOTHERSIDEASSUFFIX == 0) ? false : true);
                    this.matterdetailForm.controls['SECURITYOPTION'].setValue((matterData.SECURITYOPTION == 0) ? false : true);
                    if (matterData.MATTERACCESSLIST) {
                        const MatterAccessList = matterData.MATTERACCESSLIST
                        let Array = []
                        for (let index = 0; index < MatterAccessList.length; index++) {
                            const element = MatterAccessList[index];
                            Array.push(element.USERGUID);
                        }
                        this.matterdetailForm.controls['MATTERACCESSLIST'].setValue(Array)
                    }

                    //Rates
                    if (matterData.BILLINGGROUP) {
                        this.matterdetailForm.controls['GSTTYPE'].setValue(matterData.BILLINGGROUP.GSTTYPEDESC);
                        this.matterdetailForm.controls['ONCHARGEDISBURSEMENTGST'].setValue(matterData.BILLINGGROUP.ONCHARGEDISBURSEMENTGST == 1 ? true : false);
                        this.matterdetailForm.controls['BILLINGMETHOD'].setValue(matterData.BILLINGGROUP.BILLINGMETHODDESC);
                        this.matterdetailForm.controls['RATEPERHOUR'].setValue(matterData.BILLINGGROUP.RATEPERHOUR);
                        this.matterdetailForm.controls['RATEPERDAY'].setValue(matterData.BILLINGGROUP.RATEPERDAY);
                        this.matterdetailForm.controls['FIXEDRATEEXGST'].setValue(matterData.BILLINGGROUP.FIXEDRATEEXGST);
                        this.matterdetailForm.controls['FIXEDRATEINCGST'].setValue(matterData.BILLINGGROUP.FIXEDRATEINCGST);
                    } if (matterData.RATEPERHOURGROUP || matterData.RATEPERDAYGROUP) {

                        this.matterdetailForm.controls['RATEPERHOUR_1'].setValue(matterData.RATEPERHOURGROUP.RATEPERHOUR_1);
                        this.matterdetailForm.controls['RATEPERDAY_1'].setValue(matterData.RATEPERDAYGROUP.RATEPERDAY_1);
                        this.matterdetailForm.controls['RATEPERHOUR_2'].setValue(matterData.RATEPERHOURGROUP.RATEPERHOUR_2);
                        this.matterdetailForm.controls['RATEPERDAY_2'].setValue(matterData.RATEPERDAYGROUP.RATEPERDAY_2);
                        this.matterdetailForm.controls['RATEPERHOUR_3'].setValue(matterData.RATEPERHOURGROUP.RATEPERHOUR_3);
                        this.matterdetailForm.controls['RATEPERDAY_3'].setValue(matterData.RATEPERDAYGROUP.RATEPERDAY_3);
                        this.matterdetailForm.controls['RATEPERHOUR_4'].setValue(matterData.RATEPERHOURGROUP.RATEPERHOUR_4);
                        this.matterdetailForm.controls['RATEPERDAY_4'].setValue(matterData.RATEPERDAYGROUP.RATEPERDAY_4);
                        this.matterdetailForm.controls['RATEPERHOUR_5'].setValue(matterData.RATEPERHOURGROUP.RATEPERHOUR_5);
                        this.matterdetailForm.controls['RATEPERDAY_5'].setValue(matterData.RATEPERDAYGROUP.RATEPERDAY_5);
                        this.matterdetailForm.controls['RATEPERHOUR_6'].setValue(matterData.RATEPERHOURGROUP.RATEPERHOUR_6);
                        this.matterdetailForm.controls['RATEPERDAY_6'].setValue(matterData.RATEPERDAYGROUP.RATEPERDAY_6);

                    }
                    //other
                    if (matterData.LEGALDETAILS) {
                        this.matterdetailForm.controls['MATTERTYPE'].setValue(matterData.LEGALDETAILS.MATTERTYPE);
                    }
                    if (matterData.MARKETINGGROUP) {
                        this.matterdetailForm.controls['CLIENTSOURCE'].setValue(matterData.MARKETINGGROUP.CLIENTSOURCE);
                        this.matterdetailForm.controls['FIELDOFLAW'].setValue(matterData.MARKETINGGROUP.FIELDOFLAW);
                        this.matterdetailForm.controls['INDUSTRY'].setValue(matterData.MARKETINGGROUP.INDUSTRY);
                        this.matterdetailForm.controls['REFERRERGUID'].setValue(matterData.MARKETINGGROUP.REFERRERGUID);
                        this.matterdetailForm.controls['REFERRERNAME'].setValue(matterData.MARKETINGGROUP.REFERRERNAME);
                    }
                    this.matterdetailForm.controls['ARCHIVENO'].setValue(matterData.ARCHIVENO);
                    this.matterdetailForm.controls['NETDOCSWORKSPACEID'].setValue(matterData.NETDOCSWORKSPACEID);
                    this.matterdetailForm.controls['DOCUMENTFOLDER'].setValue(matterData.DOCUMENTFOLDER);
                    if (matterData.ARCHIVEDATE) {
                        let archivedate = matterData.ARCHIVEDATE.split("/");
                        this.matterdetailForm.controls['ARCHIVEDATETEXT'].setValue(new Date(archivedate[1] + '/' + archivedate[0] + '/' + archivedate[2]));
                        this.matterdetailForm.controls['ARCHIVEDATE'].setValue(matterData.ARCHIVEDATE);
                    }
                    if (matterData.ARCHIVEDESTROYEDDUEDATE) {
                        let archivedate1 = matterData.ARCHIVEDESTROYEDDUEDATE.split("/");
                        this.matterdetailForm.controls['ARCHIVEDESTROYEDDUEDATETEXT'].setValue(new Date(archivedate1[1] + '/' + archivedate1[0] + '/' + archivedate1[2]));
                        this.matterdetailForm.controls['ARCHIVEDESTROYEDDUEDATE'].setValue(matterData.ARCHIVEDESTROYEDDUEDATE);
                    }
                    if (matterData.ARCHIVEDESTROYEDDATE) {
                        let archivedate1 = matterData.ARCHIVEDESTROYEDDATE.split("/");
                        this.matterdetailForm.controls['ARCHIVEDESTROYEDDATETEXT'].setValue(new Date(archivedate1[1] + '/' + archivedate1[0] + '/' + archivedate1[2]));
                        this.matterdetailForm.controls['ARCHIVEDESTROYEDDATE'].setValue(matterData.ARCHIVEDESTROYEDDATE);
                    }
                    // General
                    if (matterData.DATEGROUP && matterData.DATEGROUP.COMPLETEDDATE) {
                        let COMPLETEDDATE1 = matterData.DATEGROUP.COMPLETEDDATE.split("/");
                        this.matterdetailForm.controls['COMPLETEDDATETEXT'].setValue(new Date(COMPLETEDDATE1[1] + '/' + COMPLETEDDATE1[0] + '/' + COMPLETEDDATE1[2]));
                        this.matterdetailForm.controls['COMPLETEDDATE'].setValue(matterData.DATEGROUP.COMPLETEDDATE);
                    }
                    if (matterData.DATEGROUP && matterData.DATEGROUP.DUEDATE) {
                        let DUEDATE1 = matterData.DATEGROUP.DUEDATE.split("/");
                        this.matterdetailForm.controls['DUEDATEForm'].setValue(new Date(DUEDATE1[1] + '/' + DUEDATE1[0] + '/' + DUEDATE1[2]));
                        this.matterdetailForm.controls['DUEDATE'].setValue(matterData.DATEGROUP.DUEDATE);
                    }
                    if (matterData.DATEGROUP && matterData.DATEGROUP.DATE) {
                        let DATE1 = matterData.DATEGROUP.DATE.split("/");
                        this.matterdetailForm.controls['DATETEXT'].setValue(new Date(DATE1[1] + '/' + DATE1[0] + '/' + DATE1[2]));
                        this.matterdetailForm.controls['DATE'].setValue(matterData.DATEGROUP.DATE);
                    }
                    if (matterData.DATEGROUP && matterData.DATEGROUP.FEEAGREEMENTDATE) {
                        let FeeAgreementDate1 = matterData.DATEGROUP.FEEAGREEMENTDATE.split("/");
                        this.matterdetailForm.controls['FeeAgreementDateText'].setValue(new Date(FeeAgreementDate1[1] + '/' + FeeAgreementDate1[0] + '/' + FeeAgreementDate1[2]));
                        this.matterdetailForm.controls['FEEAGREEMENTDATE'].setValue(matterData.DATEGROUP.FEEAGREEMENTDATE);
                    }
                    if (matterData && matterData.LASTREVIEWDATE) {
                        let LASTREVIEWDATE1 = matterData.LASTREVIEWDATE.split("/");
                        this.matterdetailForm.controls['LASTREVIEWDATETEXT'].setValue(new Date(LASTREVIEWDATE1[1] + '/' + LASTREVIEWDATE1[0] + '/' + LASTREVIEWDATE1[2]));
                        this.matterdetailForm.controls['LASTREVIEWDATE'].setValue(matterData.DATEGROUP.LASTREVIEWDATE);
                    }
                    if (matterData.DATEGROUP && matterData.DATEGROUP.FEEAGREEMENTRECEIVEDDATE) {
                        let FEEAGREEMENTRECEIVEDDATE1 = matterData.DATEGROUP.FEEAGREEMENTRECEIVEDDATE.split("/");
                        this.matterdetailForm.controls['FEEAGREEMENTRECEIVEDDATETEXT'].setValue(new Date(FEEAGREEMENTRECEIVEDDATE1[1] + '/' + FEEAGREEMENTRECEIVEDDATE1[0] + '/' + FEEAGREEMENTRECEIVEDDATE1[2]));
                        this.matterdetailForm.controls['FEEAGREEMENTRECEIVEDDATE'].setValue(matterData.DATEGROUP.FEEAGREEMENTRECEIVEDDATE);
                    }
                    this.matterdetailForm.controls['REFERENCE'].setValue(matterData.REFERENCE);
                    this.matterdetailForm.controls['OTHERREFERENCE'].setValue(matterData.OTHERREFERENCE);

                    if (matterData.SUMMARYTOTALS) {
                        this.matterdetailForm.controls['ESTIMATEFROMTOTALEXGST'].setValue(matterData.SUMMARYTOTALS.ESTIMATEFROMTOTALEXGST);
                        this.matterdetailForm.controls['ESTIMATEFROMTOTALINCGST'].setValue(matterData.SUMMARYTOTALS.ESTIMATEFROMTOTALINCGST);
                        this.matterdetailForm.controls['MAXESTIMATEFROMTOTALEXGST'].setValue(matterData.SUMMARYTOTALS.ESTIMATETOTOTALEXGST);
                        this.matterdetailForm.controls['MAXESTIMATEFROMTOTALINCGST'].setValue(matterData.SUMMARYTOTALS.ESTIMATETOTOTALINCGST);
                    }
                    this.matterdetailForm.controls['MATTERSTATUS'].setValue(matterData.MATTERSTATUS)
                    this.matterdetailForm.controls['NOTES'].setValue(matterData.NOTES);

                    if (this.userType) {
                        this.matterdetailForm.controls['OWNERGUID'].setValue(matterData.OWNERGUID);
                        this.matterdetailForm.controls['OWNERNAME'].setValue(matterData.OWNERNAME);
                        this.matterdetailForm.controls['PRIMARYFEEEARNERGUID'].setValue(matterData.PRIMARYFEEEARNERGUID);
                        this.matterdetailForm.controls['PRIMARYFEEEARNERNAME'].setValue(matterData.PRIMARYFEEEARNERNAME);

                        this.matterdetailForm.controls['ASSISTANTGUID'].setValue(matterData.ASSISTANTGUID);
                        this.matterdetailForm.controls['ASSISTANTNAME'].setValue(matterData.ASSISTANTNAME);
                        this.matterdetailForm.controls['MATTERSTATUS'].setValue(matterData.MATTERSTATUS);

                    }
                    //if (matterData.MATTERCLASS == 'Litigation' || matterData.MATTERCLASS == 'Business Purchase' || matterData.MATTERCLASS == 'General'|| matterData.MATTERCLASS == 'Family Law' || matterData.MATTERCLASS == 'Advice') {
                    // Details -> litigation
                    if (matterData.LEGALDETAILS) {
                        this.matterdetailForm.controls['CLIENTNAME'].setValue(matterData.LEGALDETAILS.CLIENTNAME);
                        this.matterdetailForm.controls['MatterNo'].setValue(matterData.LEGALDETAILS.MATTERNO);
                        this.matterdetailForm.controls['COURT'].setValue(matterData.LEGALDETAILS.COURT);
                        this.matterdetailForm.controls['DIVISION'].setValue(matterData.LEGALDETAILS.DIVISION);
                        this.matterdetailForm.controls['CourtList'].setValue(matterData.LEGALDETAILS.COURTLIST);
                        this.matterdetailForm.controls['REGISTRY'].setValue(matterData.LEGALDETAILS.REGISTRY);
                        this.matterdetailForm.controls['ClientType'].setValue(matterData.LEGALDETAILS.CLIENTSTATUS);
                        this.matterdetailForm.controls['MATTERTITLEBELOW'].setValue(matterData.LEGALDETAILS.MATTERTITLEBELOW);
                        this.matterdetailForm.controls['COURTBELOW'].setValue(matterData.LEGALDETAILS.COURTBELOW);
                        this.matterdetailForm.controls['CASENUMBERBELOW'].setValue(matterData.LEGALDETAILS.CASENUMBERBELOW);
                        this.matterdetailForm.controls['DECISION'].setValue(matterData.LEGALDETAILS.DECISION);
                        //  }
                        if (matterData.SUMMARYTOTALS) {
                            this.matterdetailForm.controls['COSTESTIMATEIFWINEXGST'].setValue(matterData.SUMMARYTOTALS.COSTESTIMATEIFWINEXGST);
                            this.matterdetailForm.controls['CostEstimateIfWinIncGST'].setValue(matterData.SUMMARYTOTALS.COSTESTIMATEIFWININCGST);
                            this.matterdetailForm.controls['CostEstimateIfFailExGST'].setValue(matterData.SUMMARYTOTALS.COSTESTIMATEIFFAILEXGST);
                            this.matterdetailForm.controls['CostEstimateIfFailIncGST'].setValue(matterData.SUMMARYTOTALS.COSTESTIMATEIFFAILINCGST);
                        }
                        if (matterData.LEGALDETAILS && matterData.LEGALDETAILS.DATEOFHEARINGS) {
                            // let DATEOFHEARINGS1 = matterData.LEGALDETAILS.DATEOFHEARINGS.split("/");
                            // this.matterdetailForm.controls['DATEOFHEARINGSTEXT'].setValue(new Date(DATEOFHEARINGS1[1] + '/' + DATEOFHEARINGS1[0] + '/' + DATEOFHEARINGS1[2]));
                            this.matterdetailForm.controls['DATEOFHEARINGS'].setValue(matterData.LEGALDETAILS.DATEOFHEARINGS);
                        }
                        if (matterData.LEGALDETAILS && matterData.LEGALDETAILS.MATERIALDATE) {
                            let MATERIALDATE1 = matterData.LEGALDETAILS.MATERIALDATE.split("/");
                            this.matterdetailForm.controls['MATERIALDATETEXT'].setValue(new Date(MATERIALDATE1[1] + '/' + MATERIALDATE1[0] + '/' + MATERIALDATE1[2]));
                            this.matterdetailForm.controls['MATERIALDATE'].setValue(matterData.LEGALDETAILS.MATERIALDATE);
                        }
                    }
                    if (matterData.MATTERCLASS == 'Compulsory Acquisition') {   // Details ->compulsory-acquisition
                        if (matterData.COMPULSORYACQUISITIONGROUP) {
                            this.matterdetailForm.controls['CLIENTVALUATION'].setValue(matterData.COMPULSORYACQUISITIONGROUP.CLIENTVALUATION);
                            this.matterdetailForm.controls['AUTHORITYVALUATION'].setValue(matterData.COMPULSORYACQUISITIONGROUP.AUTHORITYVALUATION);
                            this.matterdetailForm.controls['FIELDNAME '].setValue(matterData.COMPULSORYACQUISITIONGROUP.FIELDNAME);
                            this.matterdetailForm.controls['DATEOFACQUISITION '].setValue(matterData.COMPULSORYACQUISITIONGROUP.DATEOFACQUISITION);
                            this.matterdetailForm.controls['COMPENSATIONAMOUNT '].setValue(matterData.COMPULSORYACQUISITIONGROUP.COMPENSATIONAMOUNT);
                            this.matterdetailForm.controls['DATENOTICESENT '].setValue(matterData.COMPULSORYACQUISITIONGROUP.DATENOTICESENT);
                            this.matterdetailForm.controls['DETERMINATIONISSUED '].setValue(matterData.COMPULSORYACQUISITIONGROUP.DETERMINATIONISSUED);
                        }
                        this.matterdetailForm.controls['Address'].setValue(matterData.MATTERADDRESS);

                    } else if (matterData.MATTERCLASS == 'maritime') {            //Details -> maritime
                        if (matterData.VESSELGROUP) {
                            this.matterdetailForm.controls['VESSELNAME'].setValue(matterData.VESSELGROUP.VESSELNAME);
                            this.matterdetailForm.controls['VESSELFLAG'].setValue(matterData.VESSELGROUP.VESSELFLAG);
                            this.matterdetailForm.controls['VESSELTYPE'].setValue(matterData.VESSELGROUP.VESSELTYPE);
                            this.matterdetailForm.controls['TONNAGE'].setValue(matterData.VESSELGROUP.TONNAGE);
                            this.matterdetailForm.controls['VESSELMASTER'].setValue(matterData.VESSELGROUP.VESSELMASTER);
                            this.matterdetailForm.controls['VESSELLOCATION'].setValue(matterData.VESSELGROUP.VESSELLOCATION);
                            this.matterdetailForm.controls['PURCHASEPRICE'].setValue(matterData.CONVEYANCINGGROUP.PURCHASEPRICE);
                        }
                        if (matterData.VESSELGROUP && matterData.VESSELGROUP.INCIDENTDATE) {
                            let INCIDENTDATE1 = matterData.VESSELGROUP.INCIDENTDATE.split("/");
                            this.matterdetailForm.controls['INCIDENTDATETEXTM'].setValue(new Date(INCIDENTDATE1[1] + '/' + INCIDENTDATE1[0] + '/' + INCIDENTDATE1[2]));
                            this.matterdetailForm.controls['INCIDENTDATE'].setValue(matterData.VESSELGROUP.INCIDENTDATE);
                        }
                        if (matterData.CONVEYANCINGGROUP && matterData.CONVEYANCINGGROUP.EXCHANGEDATE) {
                            let EXCHANGEDATE1 = matterData.CONVEYANCINGGROUP.EXCHANGEDATE.split("/");
                            this.matterdetailForm.controls['EXCHANGEDATETEXTM'].setValue(new Date(EXCHANGEDATE1[1] + '/' + EXCHANGEDATE1[0] + '/' + EXCHANGEDATE1[2]));
                            this.matterdetailForm.controls['EXCHANGEDATE'].setValue(matterData.CONVEYANCINGGROUP.EXCHANGEDATE);
                        }
                        if (matterData.CONVEYANCINGGROUP && matterData.CONVEYANCINGGROUP.SETTLEMENTDATE) {
                            let SETTLEMENTDATE1 = matterData.CONVEYANCINGGROUP.SETTLEMENTDATE.split("/");
                            this.matterdetailForm.controls['SETTLEMENTDATETEXTM'].setValue(new Date(SETTLEMENTDATE1[1] + '/' + SETTLEMENTDATE1[0] + '/' + SETTLEMENTDATE1[2]));
                            this.matterdetailForm.controls['SETTLEMENTDATE'].setValue(matterData.CONVEYANCINGGROUP.SETTLEMENTDATE);
                        }
                    } else if (matterData.MATTERCLASS == '"Wills, POA & EG') {//Details -> wills-estate
                        if (matterData.LEGALDETAILS)
                            this.matterdetailForm.controls['MatterNo'].setValue(matterData.LEGALDETAILS.MATTERNO);

                    }
                    if (this.action == 'duplicate') {
                        this.matterdetailForm.controls['DATETEXT'].setValue(new Date());
                        this.matterdetailForm.controls['DUEDATEForm'].setValue('');
                        this.matterdetailForm.controls['COMPLETEDDATETEXT'].setValue('');
                        this.matterdetailForm.controls['LASTREVIEWDATETEXT'].setValue('');

                        this.matterdetailForm.controls['ARCHIVEDATETEXT'].setValue('');
                        this.matterdetailForm.controls['ARCHIVENO'].setValue('');
                        this.matterdetailForm.controls['ARCHIVEDESTROYEDDUEDATETEXT'].setValue('');
                        this.matterdetailForm.controls['ARCHIVEDESTROYEDDATETEXT'].setValue('');
                        this.matterdetailForm.controls['ARCHIVEDESTROYEDDUEDATE'].setValue('');
                        this.matterdetailForm.controls['ARCHIVEDESTROYEDDATE'].setValue('');



                        this.loadDefultMatter({ FIRMGUID: matterData.FIRMGUID, OWNERGUID: matterData.OWNERGUID, PRIMARYFEEEARNERGUID: matterData.PRIMARYFEEEARNERGUID });
                    }
                    if (this.action == 'edit') {
                        this.changeForControl(this.f)
                    }
                    this.isLoadingResults = false;

                } else if (response.MESSAGE == 'Not logged in') {
                    this.isLoadingResults = false;
                    this.dialogRef.close(false);
                } else {
                    this.isLoadingResults = false;
                }
            }, error => {
                this.toastr.error(error);
                this.isLoadingResults = false;
            });

        } else if (this.action === 'duplicate') {

        } else {
            this.matterdetailForm.controls['OWNERGUID'].setValue(this.matterData.OWNERGUID);
           // this.matterdetailForm.controls['OWNERNAME'].setValue(matterData.OWNERNAME);
            this.matterdetailForm.controls['PRIMARYFEEEARNERGUID'].setValue(this.matterData.PRIMARYFEEEARNERGUID);
           // this.matterdetailForm.controls['PRIMARYFEEEARNERNAME'].setValue(matterData.PRIMARYFEEEARNERNAME);

            this.matterdetailForm.controls['ASSISTANTGUID'].setValue(this.matterData.ASSISTANTGUID);

           // this.matterdetailForm.controls['ASSISTANTNAME'].setValue(matterData.ASSISTANTNAME);
            // this.matterdetailForm.controls['ACTIVE'].setValue('active');
            this.matterdetailForm.controls['DATETEXT'].setValue(new Date());
            this.matterdetailForm.controls['DATE'].setValue(this.datepipe.transform(new Date(), 'dd/MM/yyyy'));
        }
        this.behaviorService.resizeTableForAllView();
        $(window).resize(() => {
            this.behaviorService.resizeTableForAllView();
        });

        this.getDropValue();
        this.getContactDataUsingBehaviour();

        /**
        * Used to Reduced the more API calls.
        */

        // this.sub1 = this.matterdetailForm.controls.Clientmattertext.valueChanges
        // .pipe(distinctUntilChanged(),
        //  debounceTime(570),
        //  tap((x) => console.log('search value',x)),
        //  switchMap((dataVal)=>
        //  withLatestFrom(this.selectMatterFormSearch1(dataVal))),
        //  mergeMap(([res,response])=>
        //     iif(()=> (response.STATUS == "success" && response.DATA.RECORDS.length === 0), of([{ CONTACTNAME: res }]),of(response.DATA.RECORDS))
        //  )).subscribe(responseData=>{
        //  })
       this.sub1 = this.matterdetailForm.controls.Clientmattertext.valueChanges.pipe(
            distinctUntilChanged(),
            debounceTime(570),
            withLatestFrom(this.matterdetailForm.controls.Clientmattertext.statusChanges)
        ).subscribe(([formvalue, formStatus]) => {
            if(formvalue && formStatus == 'VALID') {
                this.selectMatterFormSearch(formvalue)
            }
        })

    //   this.sub1 = this.matterdetailForm.controls.Clientmattertext.valueChanges.pipe(distinctUntilChanged(), debounceTime(700)).subscribe((clientMatterdata: string) => {
    //         this.selectMatterFormSearch(clientMatterdata)
    //     })

    }
    /**
     * This function is used to get the ContactDataUsingBehaviour data value
     */
    getContactDataUsingBehaviour() {
        this.DestryedContactdata = this.behaviorService.newContactData$.subscribe(result => {
            if(result && result?.flag != undefined && result?.flag == 'Correspondent'){
                return;
            }
            this.matterlist = [];
            localStorage.setItem('istrackid', 'MatterPopupComponent');
            if (result && result != null) {
                if (this._data.action == "new" || this._data.action == "duplicate") {
                    this.Clientchild.CoommonMatterNum(result.CONTACTGUID);
                }
                this.matterdetailForm.controls['FIRMGUID'].setValue(result.CONTACTGUID);
                this.matterdetailForm.controls['Clientmattertext'].setValue(result.CONTACTNAME);
                this.matterdetailForm.controls['CONTACTTYPE'].setValue(result.CONTACTTYPE);
                this.mattername = [this.f.Clientmattertext.value];
                // if (result.CONTACTTYPE == "Person") {
                this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, { disableClose: true, width: '100%', });
                this.confirmDialogRef.componentInstance.confirmMessage = 'Do you want to add ' + result.CONTACTNAME + ' to the Correspondent list?';
                this.confirmDialogRef.afterClosed().subscribe(result2 => {
                    if (result2) {
                        let matterData: any = {};
                        this.behaviorService.MatterEditData$.subscribe(result => {
                            matterData = result;
                        });


                        if (this._data.action != "new") {
                            let details: any = {
                                TYPE: "Correspondent", SOLICITORGUID: result.CONTACTGUID,
                                REFERENCE: "", PERSONGUID: result.CONTACTGUID, MATTERGUID: matterData.MATTERGUID
                            };
                            this._mainAPiServiceService.getSetData({ FORMACTION: 'insert', VALIDATEONLY: false, DATA: details }, 'matter-contact').subscribe(response => {
                                if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
                                    this.toastr.success('Matter Contact save successfully');
                                    this.Clientchild.loadData('', '');
                                    this.isspiner = false;
                                } else if (response.CODE == 451 && response.STATUS == 'warning') {
                                    this.isspiner = false;
                                    this.toastr.warning(response.MESSAGE);
                                } else if (response.CODE == 450 && response.STATUS == 'error') {
                                    this.isspiner = false;
                                    this.toastr.error(response.MESSAGE);
                                } else if (response.MESSAGE == 'Not logged in') {
                                    this.isspiner = false;
                                }
                            }, (error: any) => {
                                console.log(error);
                            });
                        } else {
                            this.Clientchild.CorrespondDetail.emit({
                                CorrespondRoleAndReference: [{ role: "Correspondent", ref: '' }], TYPE: "Correspondent", TYPENAME: "Correspondent", SOLICITORGUID: result.CONTACTGUID,
                                REFERENCE: "", PERSONGUID: result.CONTACTGUID, MATTERGUID: "", action: 'add'
                            });
                            this.CorrespondDetail
                            let itemData: any = {
                                SOLICITORGUID: '',
                                REFERENCE: '',
                                PERSONGUID: result.CONTACTGUID,
                                MATTERGUID: undefined,
                                RELATIONSHIP: '',
                                SHAREOFESTATE: '',
                                CONTACTNAME: result.CONTACTNAME,
                                TYPENAME: 'Correspondent',
                                SOLICITORNAME: ''
                            };
                            this.Clientchild.Correspond.push({ 'type': "Correspondent", 'Text': result.CONTACTNAME, ItemData: itemData });
                        }
                    }
                    this.confirmDialogRef = null;
                });
                // }
            }
        })
    }


    /**
     * This function is used to _filter the value 
     */
    private _filter(value: string): string[] {
        const filterValue = value.toLowerCase();
        return this.DescData.filter(option => (option.LOOKUPFULLVALUE.toLowerCase().includes(filterValue)));
    }

    /**
     * This unction is used to LookupsChanges data
     * @param value -value data value
     */
    LookupsChange(value) {
        this.matterdetailForm.controls['MATTER'].setValue(value);
    }

/**
 * this unctuion is used to matterormBuild
 */

    matterFormBuild() {
        this.matterdetailForm = this._formBuilder.group({
            MATTERGUID: [''],
            MATTERCLASS: [''],
            ACTIVE: [''],
            SHORTNAME: [''],
            MATTER: [''],
            CLIENTNAME: [''],
            // case Tab
            MatterNo: [''],
            COURT: [''],
            DIVISION: [''],
            CourtList: [''],
            REGISTRY: [''],
            // general
            NOTES: [''],
            DATE: [],
            DATETEXT: [],
            COMMENCEMENTDATE: [],
            COMMENCEMENTDATETEXT: [],
            REFERENCE: [''],
            OTHERREFERENCE: [''],
            COMPLETEDDATE: [''],
            DUEDATE: [''],
            DUEDATEForm: [''],
            COMPLETEDDATETEXT: [''],
            PRIMARYFEEEARNERGUID: [''],
            PRIMARYFEEEARNERNAME: [''],
            OWNERGUID: [''],
            OWNERNAME: [''],
            FEEAGREEMENTDATE: [],
            FeeAgreementDateText: [],
            ESTIMATEFROMTOTALEXGST: ['', {updateOn: 'blur'}],
            ESTIMATEFROMTOTALINCGST: ['', {updateOn: 'blur'}],
            MAXESTIMATEFROMTOTALEXGST: ['',{updateOn: 'blur'}],
            MAXESTIMATEFROMTOTALINCGST: ['',{updateOn: 'blur'}],
            FEEAGREEMENTRECEIVEDDATE: [''],
            FEEAGREEMENTRECEIVEDDATETEXT: [''],
            LASTREVIEWDATE: [''],
            LASTREVIEWDATETEXT: [''],
            MATTERSTATUS: [''],
            ASSISTANTNAME: [''],
            ASSISTANTGUID: [''],
            // client
            FIRMGUID: [''],
            CONTACTTYPE: [''],
            Clientmattertext: [''],
            //matter client
            FIRMROLE: [''],
            OTHERSIDEROLE: [''],
            FIRMDONTSHOWADDRESS: [''],
            RELINECLIENT: [''],
            RELINECLIENTCLIENTASPREFIX: [''],
            RELINECLIENTOTHERSIDEASSUFFIX: [''],
            RELINEOTHERPARTIES: [''],
            RELINEOTHERPARTIESCLIENTASPREFIX: [''],
            RELINEOTHERPARTIESOTHERSIDEASSUFFIX: [''],
            // rates
            BILLINGMETHOD: ['Time Costed'],
            ONCHARGEDISBURSEMENTGST: [''],
            GSTTYPE: ['GST Exclusive'],
            RATEPERHOUR: [''],
            RATEPERHOUR_1: ['', {updateOn: 'blur'}],
            RATEPERHOUR_2: ['', {updateOn: 'blur'}],
            RATEPERHOUR_3: ['', {updateOn: 'blur'}],
            RATEPERHOUR_4: ['', {updateOn: 'blur'}],
            RATEPERHOUR_5: ['', {updateOn: 'blur'}],
            RATEPERHOUR_6: ['', {updateOn: 'blur'}],
            RATEPERDAY: [''],
            RATEPERDAY_1: ['', {updateOn: 'blur'}],
            RATEPERDAY_2: ['', {updateOn: 'blur'}],
            RATEPERDAY_3: ['', {updateOn: 'blur'}],
            RATEPERDAY_4: ['', {updateOn: 'blur'}],
            RATEPERDAY_5: ['', {updateOn: 'blur'}],
            RATEPERDAY_6: ['', {updateOn: 'blur'}],
            FIXEDRATEEXGST: ['', {updateOn: 'blur'}],
            FIXEDRATEINCGST: ['', {updateOn: 'blur'}],
            // Details ->compulsory-acquisition
            Address: [''],
            CLIENTVALUATION: [''],
            AUTHORITYVALUATION: [''],
            FIELDNAME: [''],
            DATEOFACQUISITION: [''],
            COMPENSATIONAMOUNT: [''],
            DATENOTICESENT: [''],
            DETERMINATIONISSUED: [''],
            Address2: [''],

            //Details -> litigation
            MATTERTITLEBELOW: [''],
            COURTBELOW: [''],
            CASENUMBERBELOW: [''],
            DATEOFHEARINGS: [''],
            // DATEOFHEARINGSTEXT: [''],
            MATERIALDATE: [''],
            MATERIALDATETEXT: [''],
            DECISION: [''],
            ClientType: [''],
            COSTESTIMATEIFWINEXGST: ['',{updateOn: 'blur'}],
            CostEstimateIfWinIncGST: ['',{updateOn: 'blur'}],
            CostEstimateIfFailExGST: ['',{updateOn: 'blur'}],
            CostEstimateIfFailIncGST: ['',{updateOn: 'blur'}],

            //Details -> maritime
            VESSELNAME: [''],
            VESSELFLAG: [''],
            VESSELTYPE: [''],
            TONNAGE: [''],
            VESSELMASTER: [''],
            VESSELLOCATION: [''],
            INCIDENTDATE: [''],
            INCIDENTDATETEXTM: [''],
            SETTLEMENTDATETEXTM: [],
            EXCHANGEDATETEXTM: [],
            EXCHANGEDATE: [''],
            CourtMatter2: [''],
            PURCHASEPRICE: [''],
            SETTLEMENTDATE: [''],

            // //Details -> strata
            // others
            MATTERTYPE: [''],
            CLIENTSOURCE: [''],
            FIELDOFLAW: [''],
            INDUSTRY: [''],
            REFERRERGUID: [''],
            REFERRERNAME: [''],
            ARCHIVENO: [''],
            ARCHIVEDATE: [''],
            ARCHIVEDATETEXT: [''],
            ARCHIVEDESTROYEDDATE: [''],
            ARCHIVEDESTROYEDDATETEXT: [''],
            ARCHIVEDESTROYEDDUEDATE: [''],
            ARCHIVEDESTROYEDDUEDATETEXT: [''],
            DOCUMENTFOLDER: [''],
            SECURITYOPTION: [''],
            MATTERACCESSLIST: [''],
            NETDOCSWORKSPACEID: ['']
        });
        this.sub2 = this.matterdetailForm.get("BILLINGMETHOD").valueChanges.subscribe(x => {
            let tempError: any = [];
            let tempWarning: any = [];
            if (this.action == 'new') {
                tempError['Matter'] = {};
                tempError['FirmGuid'] = {};
            }
            if (x != 'Fixed') {
                this.matterdetailForm.get('FIXEDRATEEXGST').disable();
                this.matterdetailForm.get('FIXEDRATEINCGST').disable();
                this.matterdetailForm.get('RATEPERHOUR').enable();
                this.matterdetailForm.get('RATEPERDAY').enable();
            } else {
                tempError['FixedRateExGST'] = {};
                tempError['FixedRateIncGST'] = {};
                this.errorWarningData = { "Error": tempError, 'Warning': tempWarning };
                this.errorWarningDataArray = { "Error": tempError, 'Warning': tempWarning };
                this.matterdetailForm.get('FIXEDRATEEXGST').enable();
                this.matterdetailForm.get('FIXEDRATEINCGST').enable();
                this.matterdetailForm.controls['FIXEDRATEEXGST'].setValue(0);
                this.matterdetailForm.controls['FIXEDRATEINCGST'].setValue(0);
                this.matterdetailForm.get('RATEPERHOUR').disable();
                this.matterdetailForm.get('RATEPERDAY').disable();
            }

        });
        //   this.matterdetailForm.valueChanges.subscribe(formControl => {
        //     const dubObj ={...this.errorWarningDataArray.Error};
        //     let element = this.errorWarningData.Error;
        //     for (const [key, value] of Object.entries(dubObj)) {
        //       const KEY = (key).toLocaleUpperCase();
        //      if (formControl[KEY] != undefined && formControl[KEY] != null && formControl[KEY]) {
        //        delete element[key];
        //        dubObj[key] = value;
        //        this.errorWarningDataArray.Error = {...dubObj}
        //      } else if (!formControl[KEY]) {
        //         element[key] = dubObj[key];
        //      }
        //     }
        // })
        this.sub3 = this.matterdetailForm.valueChanges.subscribe(formControl => {
            this.changeForControl(formControl);
        })
    }

    /**
     * This unction is used to Change the form control data
     * @param formControl -ormcontrol data value
     */
    changeForControl(formControl) {
        const dubObj = { ...this.errorWarningDataArray.Error };
        let element = this.errorWarningData.Error;
        for (const [key, value] of Object.entries(dubObj)) {
            const KEY = (key).toLocaleUpperCase();
            if (formControl[KEY] != undefined && formControl[KEY] != null && formControl[KEY]) {
                delete element[key];
                dubObj[key] = value;
                this.errorWarningDataArray.Error = { ...dubObj }
            } else if (!formControl[KEY]) {
                element[key] = dubObj[key];
            }
        }

        const dubObj_1 = { ...this.errorWarningDataArray.Warning };
        let element_1 = this.errorWarningData.Warning;
        for (const [key, value] of Object.entries(dubObj_1)) {
            const KEY = (key).toLocaleUpperCase();
            if (formControl[KEY] != undefined && formControl[KEY] != null && formControl[KEY]) {
                delete element_1[key];
                dubObj_1[key] = value;
                this.errorWarningDataArray.Warning = { ...dubObj_1 }
            } else if (!formControl[KEY]) {
                element_1[key] = dubObj_1[key];
            }
        }
    }
    get f() {
        return this.matterdetailForm.controls;
    }

    /**
     * This function is used to Check the class type data value.
     */
    Classtype(value: any) {

        value = value == "General" ? 'General' : value;
        this.classtype = value;
        setTimeout(() => {
            let val: any = this.Classdata?.find(c => c['LOOKUPFULLVALUE'] == value);
            this.ClassNameForDetail = val?.LOOKUPFULLVALUE
            this.behaviorService.matterClassData(val);
            this.behaviorService.matterClassAPICallData$.next(val);
            this.CoommonMatterNum(val?.LOOKUPFULLVALUE);
        }, 1000);

        this.setTooltipData();
    }

    /**
     * This function is used to onDialogSaveclick 
     */
    ondialogSaveClick(): void {
        this.GetSetMixRetry = false;
        this.FormAction = this.action !== 'edit' ? 'insert' : 'update';
        this.isspiner = true;
        let details: any = {

            MATTERCLASS: this.f.MATTERCLASS.value,
            ACTIVE: this.f.ACTIVE.value,
            SHORTNAME: this.f.SHORTNAME.value,
            MATTER: this.f.MATTER.value,
            // client
            FIRMGUID: this.f.FIRMGUID.value,
            // rates
            BILLINGMETHOD: this.f.BILLINGMETHOD.value,
            ONCHARGEDISBURSEMENTGST: this.f.ONCHARGEDISBURSEMENTGST.value == true ? 1 : 0,
            GSTTYPE: this.f.GSTTYPE.value,
            RATEPERHOUR: this.f.RATEPERHOUR.value,
            RATEPERHOUR_1: this.f.RATEPERHOUR_1.value,
            RATEPERHOUR_2: this.f.RATEPERHOUR_2.value,
            RATEPERHOUR_3: this.f.RATEPERHOUR_3.value,
            RATEPERHOUR_4: this.f.RATEPERHOUR_4.value,
            RATEPERHOUR_5: this.f.RATEPERHOUR_5.value,
            RATEPERHOUR_6: this.f.RATEPERHOUR_6.value,
            RATEPERDAY_1: this.f.RATEPERDAY_1.value,
            RATEPERDAY_2: this.f.RATEPERDAY_2.value,
            RATEPERDAY_3: this.f.RATEPERDAY_3.value,
            RATEPERDAY_4: this.f.RATEPERDAY_4.value,
            RATEPERDAY_5: this.f.RATEPERDAY_5.value,
            RATEPERDAY_6: this.f.RATEPERDAY_6.value,
            RATEPERDAY: this.f.RATEPERDAY.value,
            FIXEDRATEINCGST: this.f.FIXEDRATEINCGST.value,
            FIXEDRATEEXGST: this.f.FIXEDRATEEXGST.value,
            // others
            MATTERTYPE: this.f.MATTERTYPE.value,
            CLIENTSOURCE: this.f.CLIENTSOURCE.value,
            FIELDOFLAW: this.f.FIELDOFLAW.value,
            INDUSTRY: this.f.INDUSTRY.value,
            REFERRERGUID: this.f.REFERRERGUID.value,
            NETDOCSWORKSPACEID: this.f.NETDOCSWORKSPACEID.value,
            // REFERRERNAME:this.f.REFERRERNAME.value,
            ARCHIVENO: this.f.ARCHIVENO.value,
            ARCHIVEDATE: this.f.ARCHIVEDATE.value,
            ARCHIVEDESTROYEDDATE: this.f.ARCHIVEDESTROYEDDATE.value,
            ARCHIVEDESTROYEDDUEDATE: this.f.ARCHIVEDESTROYEDDUEDATE.value,

            SECURITYOPTION: this.f.SECURITYOPTION.value,
            ESTIMATETOTOTALEXGST: this.f.MAXESTIMATEFROMTOTALEXGST.value,
            ESTIMATETOTOTALINCGST: this.f.MAXESTIMATEFROMTOTALINCGST.value,
        };
        if (this.f.SECURITYOPTION.value) {
            details.MATTERACCESSLIST = this.f.MATTERACCESSLIST.value
        }
        if (this.action === 'edit') {
            details.DOCUMENTFOLDER = this.f.DOCUMENTFOLDER.value;
            details.MATTERGUID = this.f.MATTERGUID.value;
        }
        //matter client
        details.FIRMROLE = this.f.FIRMROLE.value;
        details.OTHERSIDEROLE = this.f.OTHERSIDEROLE.value;
        details.FIRMDONTSHOWADDRESS = this.f.FIRMDONTSHOWADDRESS.value;
        details.RELINECLIENT = this.f.RELINECLIENT.value;
        details.RELINECLIENTCLIENTASPREFIX = this.f.RELINECLIENTCLIENTASPREFIX.value;
        details.RELINECLIENTOTHERSIDEASSUFFIX = this.f.RELINECLIENTOTHERSIDEASSUFFIX.value;
        details.RELINEOTHERPARTIES = this.f.RELINEOTHERPARTIES.value;
        details.RELINEOTHERPARTIESCLIENTASPREFIX = this.f.RELINEOTHERPARTIESCLIENTASPREFIX.value;
        details.RELINEOTHERPARTIESOTHERSIDEASSUFFIX = this.f.RELINEOTHERPARTIESOTHERSIDEASSUFFIX.value;
        // general
        details.NOTES = this.f.NOTES.value;
        details.DATE = this.f.DATE.value;
        details.REFERENCE = this.f.REFERENCE.value;
        details.OTHERREFERENCE = this.f.OTHERREFERENCE.value;
        details.COMPLETEDDATE = this.f.COMPLETEDDATE.value;
        details.DUEDATE = this.f.DUEDATE.value;
        details.FEEAGREEMENTDATE = this.f.FEEAGREEMENTDATE.value;
        details.ESTIMATEFROMTOTALEXGST = this.f.ESTIMATEFROMTOTALEXGST.value;
        details.ESTIMATEFROMTOTALINCGST = this.f.ESTIMATEFROMTOTALINCGST.value;
        details.FEEAGREEMENTRECEIVEDDATE = this.f.FEEAGREEMENTRECEIVEDDATE.value;
        details.LASTREVIEWDATE = this.f.LASTREVIEWDATE.value;
        details.MATTERSTATUS = this.f.MATTERSTATUS.value;
        details.ASSISTANTGUID = this.f.ASSISTANTGUID.value;
       // details.ASSISTANTNAME = this.f.ASSISTANTNAME.value;
        if (this.userType) {
            details.PRIMARYFEEEARNERGUID = this.f.PRIMARYFEEEARNERGUID.value;
          //  details.PRIMARYFEEEARNERNAME = this.f.PRIMARYFEEEARNERNAME.value;
            details.OWNERGUID = this.f.OWNERGUID.value;
          //  details.OWNERNAME = this.f.OWNERNAME.value;
        }
        // if (this.classtype == 'Litigation'|| this.classtype == 'Family Law' || this.classtype == 'General' ||this.classtype== 'Advice' || this.classtype == 'Business Purchase' ) {
        //Details -> litigation
        details.MATTERNO = this.f.MatterNo.value;
        details.COURT = this.f.COURT.value;
        details.DIVISION = this.f.DIVISION.value;
        details.CourtList = this.f.CourtList.value;
        details.REGISTRY = this.f.REGISTRY.value;
        details.CLIENTSTATUS = this.f.ClientType.value;
        details.MATTERTITLEBELOW = this.f.MATTERTITLEBELOW.value;
        details.COURTBELOW = this.f.COURTBELOW.value;
        details.CASENUMBERBELOW = this.f.CASENUMBERBELOW.value;
        details.DATEOFHEARINGS = this.f.DATEOFHEARINGS.value;
        details.MATERIALDATE = this.f.MATERIALDATE.value;
        details.DECISION = this.f.DECISION.value;
        details.COSTESTIMATEIFWINEXGST = this.f.COSTESTIMATEIFWINEXGST.value;
        details.CostEstimateIfWinIncGST = this.f.CostEstimateIfWinIncGST.value;
        details.CostEstimateIfFailExGST = this.f.CostEstimateIfFailExGST.value;
        details.CostEstimateIfFailIncGST = this.f.CostEstimateIfFailIncGST.value;
        details.CLIENTNAME = this.f.CLIENTNAME.value;
        // }
        // else if (this.classtype == 'Maritime') {
        //   //Details -> maritime
        //   details.VESSELNAME = this.f.VESSELNAME.value;
        //   details.VESSELFLAG = this.f.VESSELFLAG.value;
        //   details.VESSELTYPE = this.f.VESSELTYPE.value;
        //   details.TONNAGE = this.f.TONNAGE.value;
        //   details.VESSELMASTER = this.f.VESSELMASTER.value;
        //   details.VESSELLOCATION = this.f.VESSELLOCATION.value;
        //   details.PURCHASEPRICE = this.f.PURCHASEPRICE.value;

        //   details.INCIDENTDATE = this.f.INCIDENTDATE.value;
        //   details.EXCHANGEDATE = this.f.EXCHANGEDATE.value;
        //   details.SETTLEMENTDATE = this.f.SETTLEMENTDATE.value;
        // }
        //For min mac GSt
        // if(this.f.ESTIMATEFROMTOTALEXGST.value && this.f.MAXESTIMATEFROMTOTALEXGST.value){
        //   if(this.f.ESTIMATEFROMTOTALEXGST.value > 0 && this.f.MAXESTIMATEFROMTOTALEXGST.value !==0 && this.f.MAXESTIMATEFROMTOTALEXGST.value < this.f.ESTIMATEFROMTOTALEXGST.value ){
        //     this.toastr.error("Estimate Minimum Ex-GST is not grater then Estimate Maximum Inc-GST");
        //     this.isspiner = false;
        //     return;
        //   }
        // }
        let court_lookups = JSON.parse(localStorage.getItem('court_lookups'));
        let Court_lookupresult = court_lookups.some(item => item.LOOKUPFULLVALUE == this.f.COURT.value);
        if (this.f.COURT.value && !Court_lookupresult) {
            this.confirmDialogRef1 = this._matDialog.open(ConfirmPopupComponent, { disableClose: true, width: "100%", data: {} });
            this.confirmDialogRef1.componentInstance.confirmMessage = this.f.COURT.value + " does not appear in your court list, do you want SILQ to add it so it is available next time?";
            this.confirmDialogRef1.componentInstance.confirmTitle = 'Add Court';
            this.confirmDialogRef1.componentInstance.confirm1 = 'Create';
            this.confirmDialogRef1.componentInstance.confirm2 = 'Don`t Create';
            this.confirmDialogRef1.afterClosed().subscribe((result) => {
                if (result) {
                    this.checkmattedata(details);
                    let newLookupDate: any = { Action: 'insert', VALIDATEONLY: false, "DATA": { "LOOKUPTYPE": "Court", "LOOKUPFULLVALUE": this.f.COURT.value } };
                    this._mainAPiServiceService.getSetData(newLookupDate, 'lookup').subscribe(response => {
                        if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
                            this._mainAPiServiceService.getSetData({ Action: 'getdata', "Filters": { 'LookupType': 'court' } }, 'lookup').subscribe(responses => {
                                if (responses.CODE === 200 && responses.STATUS === 'success') {
                                    localStorage.setItem('court_lookups', JSON.stringify(responses.DATA.LOOKUPS));
                                }
                            });
                        } else if (response.MESSAGE == 'Not logged in') {
                            this.dialogRef.close(false);
                        }
                    }, error => {
                        this.toastr.error(error);
                    });
                } else {
                    this.checkmattedata(details);
                }
                this.confirmDialogRef = null;
            });
        } else {
            this.checkmattedata(details);
        }
    }

    /**
     * This function is used to checkMatterData
     * @param details 
     */
    checkmattedata(details) {
        // let matterPostData: any = { FormAction: this.FormAction, VALIDATEONLY: true, Data: details };
        let matterPostData: any = { Action: this.FormAction, VALIDATEONLY: true, Data: details };
      this.sub12 = this._mainAPiServiceService.getSetData(matterPostData, 'matter').subscribe(response => {
            matterPostData = { FormAction: this.FormAction, VALIDATEONLY: true, Data: details };
            if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
                this.checkValidation(response.DATA.VALIDATIONS, matterPostData);
            } else if (response.CODE == 451 && response.STATUS == 'warning') {
                this.checkValidation(response.DATA.VALIDATIONS, matterPostData);
            } else if (response.CODE == 450 && response.STATUS == 'error') {
                this.checkValidation(response.DATA.VALIDATIONS, matterPostData);
            } else if (response.MESSAGE == 'Not logged in') {
                this.dialogRef.close(false);
            }
        }, error => {
            this.isspiner = false;
            this.toastr.error(error);
        });
    }

    /**
     * This function is used to Commencement data
     * @param type -type of the data value
     * @param event -event data value
     */
    CommencementDate(type: string, event: MatDatepickerInputEvent<Date>) {
        this.matterdetailForm.controls['DATE'].setValue(this.datepipe.transform(event.value, 'dd/MM/yyyy'));
    }

    /**
     * This function is used to set the due date value
     * @param type -type of the date  
     * @param event -event data value
     */
    DueDate(type: string, event: MatDatepickerInputEvent<Date>) {
        this.matterdetailForm.controls['DUEDATE'].setValue(this.datepipe.transform(event.value, 'dd/MM/yyyy'));
    }

    /**
     * This function is used to set the cost Agreement date
     * @param type -type of the date
     * @param event -event data value
     */
    CostsAgreementDate(type: string, event: MatDatepickerInputEvent<Date>) {
        this.matterdetailForm.controls['FEEAGREEMENTDATE'].setValue(this.datepipe.transform(event.value, 'dd/MM/yyyy'));
    }

    /**
     * This function is used to set the completed Date.
     * @param type -type of the date
     * @param event-event data value
     */
    CompletedDate(type: string, event: MatDatepickerInputEvent<Date>) {
        this.matterdetailForm.controls['COMPLETEDDATE'].setValue(this.datepipe.transform(event.value, 'dd/MM/yyyy'));
    }

    /**
     * This function is used to set the last review picker data
     * @param type -type of the LastReview picker
     * @param event -event data value.
     */
    LastReviewpicker(type: string, event: MatDatepickerInputEvent<Date>) {
        this.matterdetailForm.controls['LASTREVIEWDATE'].setValue(this.datepipe.transform(event.value, 'dd/MM/yyyy'));
    }

    /**
     * This function is used to Check the validation data
     * @param bodyData -body data value
     * @param details -details value
     */
    async checkValidation(bodyData: any, details: any) {
        // let errorData: any = [];
        // let warningData: any = [];
        // let tempError: any = [];
        // let tempWarning: any = [];
        // bodyData.forEach(function (value: { VALUEVALID: string; ERRORDESCRIPTION: any; FIELDNAME: any; }) {
        //   if (value.VALUEVALID == 'No') {
        //     errorData.push(value.ERRORDESCRIPTION);
        //     tempError[value.FIELDNAME] = value;
        //   } else if (value.VALUEVALID == 'Warning') {
        //     tempWarning[value.FIELDNAME] = value;
        //     warningData.push(value.ERRORDESCRIPTION);
        //   }
        // });
        // this.errorWarningData = { "Error": tempError, 'Warning': tempWarning };
        // this.errorWarningDataArray = { "Error": tempError, 'Warning': tempWarning };
        // if (Object.keys(errorData).length != 0) {
        //   this.toastr.error(errorData);
        //   this.isspiner = false;
        // } else if (Object.keys(warningData).length != 0) {
        //   this.isspiner = false;
        //   this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
        //     disableClose: true,
        //     width: '100%',
        //     data: warningData
        //   });
        //   this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to Save?';
        //   this.confirmDialogRef.afterClosed().subscribe(result => {
        //     if (result) {
        //       this.isspiner = true;
        //       this.saveMatterData(details);
        //     }
        //     this.confirmDialogRef = null;
        //   });
        // } else if (Object.keys(warningData).length == 0 && Object.keys(errorData).length == 0) {
        //   this.saveMatterData(details);
        // }
        this.isspiner = false;
        await this.globalFunService.checkValidation(bodyData, details)
            .subscribe(data => {
                if (data) {
                    this.errorWarningData = data.errorWarningData;
                    this.errorWarningDataArray = data.errorWarningData;
                    if (data.callback) {
                        this.saveMatterData(details);
                    }
                }
            });
    }

    /**
     * This function is used to set the family Law Details
     */
    setFamilyLawDetails() {
        this.behaviorService.SaveFamilyLawDetailFlag$.next(true);
    }

    /**
     * This function is used to set the criminal details value
     */
    setCriminalDetails() {
        this.behaviorService.SaveCriminalDetailFlag$.next(true);
    }


    /**
     * This function is used to saveMatterData value
     * @param data -data value
     */
    async saveMatterData(data: any) {
        data.VALIDATEONLY = false;
        this._mainAPiServiceService.getSetData(data, 'matter').subscribe(response => {
            if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
        //  if((this.classtype==='Estate' || this.classtype==='Wills, POA & EG')){
        // //  $('#SaveEstate').click();
        //   }
        // https://apitest.silq.online/websocket-send-data
                if (this.action !== 'edit') {
                    this.toastr.success('Matter save successfully');
                } else {
                    this.toastr.success('Matter updated successfully');
                }
                // let json = {
                //     "ACTION": "default",
                //     "DATA": {
                //         "changeTopic": "Matter-DataChange",
                //         "message": JSON.stringify(data.Data)
                //     },
                // }
                // this._mainAPiServiceService.getSetData(json,'websocket-send-data').subscribe((response1) =>{
                //     if(response1.STATUS == "success") {
                //     }   
                // })
                this.dialogRef.close(false);
                this.saveCorDetail(response.DATA.MATTERGUID);
                if(this.MultyDeliverables.length >0){
                    this.callMultipleDeliverables(response.DATA.MATTERGUID)
                }
                this.saveRateDetail(response.DATA.MATTERGUID);
                this.saveAddressData(response.DATA.MATTERGUID);
                this.saveMatterLeasingData(response.DATA.MATTERGUID);
                // this.setFamilyLawDetails();
                // this.setCriminalDetails();
                // save maritime
                if (this.classtype == 'Maritime') {
                    // this.saveMaritime(response.DATA.MATTERGUID)
                }
                // ends here ~ save maritime

                // this code for runtime active matter update
                // let JsonDATA = JSON.parse(localStorage.getItem('set_active_matters'));
                // JsonDATA.MATTERCLASS = this.classtype;
                // JsonDATA.MATTERCLASSDESC = this.classtype;
                // localStorage.setItem('set_active_matters' , JSON.stringify(JsonDATA));

                this.behaviorService.setChildTableData({ MATTERGUID: response.DATA.MATTERGUID, 'classType': this.f.MATTERCLASS.value, action: 'setData', CONTACTGUID: response.DATA.CONTACTGUID });
            } else if (response.CODE == 451 && response.STATUS == 'warning') {
                this.toastr.warning(response.MESSAGE);
                this.isspiner = false;
            } else if (response.CODE == 450 && response.STATUS == 'error') {
                this.toastr.error(response.MESSAGE);
                this.isspiner = false;
            } else if (response.CODE == 432 || response.CODE == '432') {
                this.isspiner = false;
                let currentUser: CurrentUser = JSON.parse(localStorage.getItem('currentUser'));
        //         window.open(environment.APIEndpoint + 'OAuth2Start?AuthType=NetDrive&SessionToken=' + currentUser.SessionToken, 'targetWindow',
        //             `toolbar=no,
        //  location=no,
        //  status=no,
        //  menubar=no,
        //  scrollbars=yes,
        //  resizable=yes,
        //  width=800,
        //  height=600`);
                let payload = {
                    "Action": "OAuth2Start",
                    "VALIDATEONLY": false,
                    "Data": {
                        "USERGUID": currentUser.UserGuid,
                        "AuthType": "NetDrive"
                    }
                }
                this._mainAPiServiceService
                .getSetData(payload, 'oauth-connections')
                .subscribe((response) => {
                    if(response.CODE == 200 && response.STATUS == "success") {
                        window.open(
                            response.DATA[0].AuthorizationUrl,
                            "targetWindow",
                            `toolbar=no,
                            location=no,
                            status=no,
                            menubar=no,
                            scrollbars=yes,
                            resizable=yes,
                            width=800,
                            height=600`
                        );
                    }
                });
            } else if (response.MESSAGE == 'Not logged in') {
                this.dialogRef.close(false);
            }
        }, error => {
            this.toastr.error(error);
        });
    }

    /**
     * This function is used to save the coreDeatails data.
     * @param MatterId -MatterId data value
     */
    saveCorDetail(MatterId: any) {
        let matterService = this._mainAPiServiceService;

        this.CorrespondDetail.forEach(function (value: { MATTERGUID: any, TYPE: any; REFERENCE: any; CorrespondRoleAndReference: any[]; action: string }) {
            const correspondingDetailsRole = value.CorrespondRoleAndReference
            correspondingDetailsRole.forEach(element => {
                value.TYPE = element.role;
                element.ref ? value.REFERENCE = element.ref : '';
                value.MATTERGUID = MatterId;
                value.action = 'insert'
                matterService.getSetData({ FORMACTION: 'insert', VALIDATEONLY: false, DATA: value }, 'matter-contact').subscribe((response: { CODE: number; STATUS: string; }) => {
                    if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
                    }
                }, (error: any) => { console.log(error); });
            });
        });
        $('#refreshMatterTab').click();
        this.dialogRef.close(MatterId);
    }

  callMultipleDeliverables(matterguid){
    this.MultyDeliverables.forEach((element,index) => {
    element.MATTERGUID=matterguid
    let PostsaveData: any = { FormAction: 'insert', VALIDATEONLY: false, Data: element };
    this.DeliverableSaveData(PostsaveData);
});



  }
    
  /**
   * This function is used to save the Deliverable data.
   */
  DeliverableSaveData(data: any) {
    data.VALIDATEONLY = false;
    this._mainAPiServiceService.getSetData(data, 'matter-deliverable').subscribe(response => {
      if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
        if (this.action !== 'edit') {
        //  this.toastr.success(' save successfully');
        } else {
        //  this.toastr.success(' update successfully');
        }
        this.dialogRef.close(true);
      } else if (response.CODE == 451 && response.STATUS == 'warning') {
        this.toastr.warning(response.MESSAGE);
        this.isspiner = false;
      } else if (response.CODE == 450 && response.STATUS == 'error') {
        this.toastr.error(response.MESSAGE);
        this.isspiner = false;
      } else if (response.MESSAGE == 'Not logged in') {
        this.dialogRef.close(false);
      }
    }, error => {
      this.toastr.error(error);
    });
  }
    /**
     * This function is used to get coDetailBack data.
     * @param event -event data value.
     */
    corDetailBack(event: any) {
        if (event.action == 'add') {
            this.CorrespondDetail.push(event);
        } else if (event.action == 'edit') {
            this.CorrespondDetail[event.index] = event;
        } else if (event.action == 'remove') {
            this.CorrespondDetail.splice(event.index, 1);
        } else if (event.ItemData) {
            if (event.ItemData.action == "add") {
                this.CorrespondDetail.push(event.ItemData);
            }
        }
    }

    /**
     * This function is used to AfterviewinitNewMatter
     */
    AfterViewInitNewMatter() {
        let tempError: any = [];
        let tempWarning: any = [];
        tempWarning['FEEAGREEMENTDATE'] = {};
        tempWarning['RATEPERHOUR'] = {};
        tempWarning['RATEPERDAY'] = {};

        tempError['MATTER'] = {};
        tempError['FirmGuid'] = {};
        this.errorWarningData = { "Error": tempError, 'Warning': tempWarning };
        this.errorWarningDataArray = { "Error": tempError, 'Warning': tempWarning };
    }

    /**
     * This unction is used to check Deault validation data
     * @param bodyData 
     */
    async CheckdefultValidation(bodyData: any) {
        let errorData: any = [];
        let warningData: any = [];
        let tempError: any = [];
        let tempWarning: any = [];
        bodyData.forEach(function (value: { VALUEVALID: string; ERRORDESCRIPTION: any; FIELDNAME: any; }) {
            if (value.VALUEVALID == 'No') {
                errorData.push(value.ERRORDESCRIPTION);
                tempError[value.FIELDNAME] = value;
            } else if (value.VALUEVALID == 'Warning') {
                tempWarning[value.FIELDNAME] = value;
                warningData.push(value.ERRORDESCRIPTION);
            }
        });
        this.errorWarningData = await { "Error": tempError, 'Warning': tempWarning };
        this.errorWarningDataArray = await { "Error": tempError, 'Warning': tempWarning };
        await this.changeForControl(this.f);

    }

    /**
     * This function is used to Address Detail back
     * @param event -event data value
     */
    AddressDetailBack(event: any) {
        this.addressDetail = event;
    }

    /**
     * This function is used to get the leasing data back
     * @param event -event
     */
    LeasingDetailBack(event: any) {
        this.LeasingDetail = event;
    }

    /**
     * This function is used to saveAddressData
     * @param MatterId -Matter Id
     */
    saveAddressData(MatterId: any) {
        if (this.addressDetail.VALIDATEONLY) {
            this.addressDetail.VALIDATEONLY = false;
            this.addressDetail.Data.MATTERGUID = MatterId;
           this.sub13 = this._mainAPiServiceService.getSetData(this.addressDetail, 'matter-property').subscribe(response => {
                if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
                    // this.toastr.success('Address save successfully');
                }
                this.isspiner = false;
            }, error => {
                this.toastr.error(error);
            });
        }
    }

    /**
     * This unction is used to save the Matter leasing data
     * @param MatterId -MatterId data
     */
    saveMatterLeasingData(MatterId: any) {
        if (this.LeasingDetail.VALIDATEONLY) {
            this.LeasingDetail.VALIDATEONLY = false;
            this.LeasingDetail.Data.MATTERGUID = MatterId;
           this.sub14 = this._mainAPiServiceService.getSetData(this.LeasingDetail, 'matter-leasing').subscribe(response => {
                if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
                    this.toastr.success('Leasing Detail save successfully');
                }
                this.isspiner = false;
            }, error => {
                this.toastr.error(error);
            });
        }
    }

    /**
     * This function is used to ge the Contact matter data
     */
    ContactMatter():void {

        this.searchData = [];
        this.matterlist = this.searchData;
        const dialogRef = this._matDialog.open(ContactSelectDialogComponent, {
            width: '100%', disableClose: true, data: { type: "", 'default': this.tabTitle }
        });
        dialogRef.afterClosed().subscribe(result => {
            localStorage.setItem('istrackid', 'MatterPopupComponent');
            if (result) {
                if (this._data.action == "new" || this._data.action == "duplicate") {
                    this.Clientchild.CoommonMatterNum(result.CONTACTGUID);
                }
                this.matterdetailForm.controls['FIRMGUID'].setValue(result.CONTACTGUID);
                this.matterdetailForm.controls['Clientmattertext'].setValue(result.CONTACTNAME);
                this.matterdetailForm.controls['CONTACTTYPE'].setValue(result.CONTACTTYPE);
                this.mattername = [this.f.Clientmattertext.value];
                if (result.CONTACTTYPE != "Company") {
                    this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, { disableClose: true, width: '100%', });
                    this.confirmDialogRef.componentInstance.confirmMessage = 'Do you want to add ' + result.CONTACTNAME + ' to the Correspondent list?';
                    this.confirmDialogRef.afterClosed().subscribe(result2 => {
                        if (result2) {
                            let matterData: any = {};
                            this.behaviorService.MatterEditData$.subscribe(result => {
                                matterData = result;
                            });


                            if (this._data.action != "new") {
                                let details: any = {
                                    TYPE: "Correspondent", SOLICITORGUID: result.CONTACTGUID,
                                    REFERENCE: "", PERSONGUID: result.CONTACTGUID, MATTERGUID: matterData.MATTERGUID
                                };
                                this._mainAPiServiceService.getSetData({ FORMACTION: 'insert', VALIDATEONLY: false, DATA: details }, 'matter-contact').subscribe(response => {
                                    if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
                                        this.toastr.success('Matter Contact save successfully');
                                        this.Clientchild.loadData('', '');
                                        this.isspiner = false;
                                    } else if (response.CODE == 451 && response.STATUS == 'warning') {
                                        this.isspiner = false;
                                        this.toastr.warning(response.MESSAGE);
                                    } else if (response.CODE == 450 && response.STATUS == 'error') {
                                        this.isspiner = false;
                                        this.toastr.error(response.MESSAGE);
                                    } else if (response.MESSAGE == 'Not logged in') {
                                        this.isspiner = false;
                                    }
                                }, (error: any) => {
                                    console.log(error);
                                });
                            } else {
                                this.Clientchild.CorrespondDetail.emit({
                                    CorrespondRoleAndReference: [{ role: "Correspondent", ref: '' }], TYPE: "Correspondent", TYPENAME: "Correspondent", SOLICITORGUID: result.CONTACTGUID,
                                    REFERENCE: "", PERSONGUID: result.CONTACTGUID, MATTERGUID: "", action: 'add'
                                });
                                this.CorrespondDetail
                                let itemData: any = {
                                    SOLICITORGUID: '',
                                    REFERENCE: '',
                                    PERSONGUID: result.CONTACTGUID,
                                    MATTERGUID: undefined,
                                    RELATIONSHIP: '',
                                    SHAREOFESTATE: '',
                                    CONTACTNAME: result.CONTACTNAME,
                                    TYPENAME: 'Correspondent',
                                    SOLICITORNAME: ''
                                };
                                this.Clientchild.Correspond.push({ 'type': "Correspondent", 'Text': result.CONTACTNAME, ItemData: itemData });
                            }
                        }
                        this.confirmDialogRef = null;
                    });
                }
            }
        });
    }

    /**
     * This function is used to CommonMatterNum data value
     * @param MatterClassid -id data value
     */
    CoommonMatterNum(MatterClassid) {
        const shortName = this.matterdetailForm.controls['SHORTNAME'].value
        if (!shortName) {
            this.isLoadingResults = true;
            let ownerGuid = this.matterdetailForm.controls['OWNERGUID'].value;
            let FeeEanerGuid = this.matterdetailForm.controls['PRIMARYFEEEARNERGUID'].value;
            let FirmGuid = this.matterdetailForm.controls['FIRMGUID'].value;
            // let MatterClassid = this.matterdetailForm.controls['MATTERCLASS'].value;
          this.sub15 = this._mainAPiServiceService.getSetData({ Action: 'GetMatterNum', VALIDATEONLY: true, DATA: { FIRMGUID: FirmGuid, OWNERGUID: ownerGuid, PRIMARYFEEEARNERGUID: FeeEanerGuid, MATTERCLASS: MatterClassid } }, 'matter').subscribe(res => {
                if (res.CODE == 200 && res.STATUS == "success") {
                    const Matterdata = res.DATA;
                    if (this._data.action == 'new') {
                        this.matterdetailForm.patchValue({
                            SHORTNAME: Matterdata['SHORTNAME'],
                            // GSTTYPE: Matterdata.BILLINGGROUP['GSTTYPE'],
                            // BILLINGMETHOD: Matterdata.BILLINGGROUP['BILLINGMETHOD'],
                            // ONCHARGEDISBURSEMENTGST: Matterdata.BILLINGGROUP['ONCHARGEDISBURSEMENTGST'],
                            // RATEPERHOUR: round(Matterdata.BILLINGGROUP['RATEPERHOUR']).toFixed(2),
                            // RATEPERDAY: round(Matterdata.BILLINGGROUP['RATEPERDAY']).toFixed(2)
                        });
                    } else {
                        this.matterdetailForm.patchValue({
                            SHORTNAME: Matterdata['SHORTNAME'],
                        });
                    }
                } else if (res.MESSAGE === 'Not logged in') {
                    this.dialogRef.close(false);
                } else {
                    this.toastr.error(res.MESSAGE);
                    // this.matterdetailForm.controls['SHORTNAME'].setValue(res.DATA.DEFAULTVALUES['SHORTNAME']);
                }
                this.isLoadingResults = false;
            }, error => { this.toastr.error(error); this.isLoadingResults = false; });
        }
    }

/**
 * This function is used to get the permission
 * @param permission -permission data
 * @returns permission data value
 */
    getPermission(permission) {
        return this.accessTierPermission.filter(e => e.FEATURENAME == permission)[0];
    }

    /**
     * This function is used to select the matter data value
     */
    selectMatter() {
        const dialogRef = this._matDialog.open(UserSelectPopupComponent, { width: '100%', disableClose: true });
        dialogRef.afterClosed().subscribe(result => {
            localStorage.setItem('istrackid', 'MatterPopupComponent');
            if (result) {
                this.matterdetailForm.controls['OWNERGUID'].setValue(result.USERGUID);
                this.matterdetailForm.controls['OWNERNAME'].setValue(result.USERID);
                if (this._data.action == "new" || this._data.action == "duplicate") {
                    this.CoommonMatterNum({});
                }
            }
        });
    }

    /**
     * This function is used to select the fee earner data
     */
    selectFeeEarner() {
        const dialogRef = this._matDialog.open(UserSelectPopupComponent, { width: '100%', disableClose: true });
        dialogRef.afterClosed().subscribe(result => {
            localStorage.setItem('istrackid', 'MatterPopupComponent');
            if (result) {
                this.matterdetailForm.controls['PRIMARYFEEEARNERGUID'].setValue(result.USERGUID);
                this.matterdetailForm.controls['PRIMARYFEEEARNERNAME'].setValue(result.USERID);
                if (this._data.action == "new" || this._data.action == "duplicate") {
                    this.CoommonMatterNum({});
                }
            }
        });
    }

    /**
     * This function is used to select the person assist
     */
    selectPersonAssist() {
        const dialogRef = this._matDialog.open(UserSelectPopupComponent, { width: '100%', disableClose: true });
        dialogRef.afterClosed().subscribe(result => {
            localStorage.setItem('istrackid', 'MatterPopupComponent');
            if (result) {
                this.matterdetailForm.controls['ASSISTANTGUID'].setValue(result.USERGUID);
                this.matterdetailForm.controls['ASSISTANTNAME'].setValue(result.USERID);
            }
        });
    }

    /**
     * This function is used to get the dropData matter value
     */
    getMatterDropData(){
        this.isLoadingResults = false;
        this.sub1 = this._mainAPiServiceService.getSetData({Action : 'GetData',Filters :{'Active': 'yes'}}, 'user').subscribe(response => {
            if (response.CODE === 200 && (response.STATUS === "OK" || response.STATUS === "success")) {
             let USERDATA=response.DATA.USERS;
             if(USERDATA){
                if(this.currentuser && this.currentuser.DEFAULTASSISTANTGUID != undefined && this.currentuser.DEFAULTASSISTANTGUID != null && this.currentuser.DEFAULTASSISTANTGUID !=''){
                USERDATA.forEach(element => {
                    if(element.USERGUID == this.currentuser.DEFAULTASSISTANTGUID){
                        this.matterdetailForm.controls['ASSISTANTGUID'].setValue(element.USERGUID);
                        this.matterdetailForm.controls['ASSISTANTNAME'].setValue(element.USERID);
                    }
                });
            }

            if(this.currentuser && this.currentuser.DEFAULTPRIMARYFEEEARNERGUID != undefined && this.currentuser.DEFAULTPRIMARYFEEEARNERGUID != null && this.currentuser.DEFAULTPRIMARYFEEEARNERGUID !=''){
                USERDATA.forEach(element => {
                if(element.USERGUID == this.currentuser.DEFAULTPRIMARYFEEEARNERGUID){
                    this.matterdetailForm.controls['PRIMARYFEEEARNERGUID'].setValue(element.USERGUID);
                    this.matterdetailForm.controls['PRIMARYFEEEARNERNAME'].setValue(element.USERID);
                }
            }); 
            }

            if(this.currentuser && this.currentuser.DEFAULTOWNERGUID != undefined && this.currentuser.DEFAULTOWNERGUID != null && this.currentuser.DEFAULTOWNERGUID !=''){
                USERDATA.forEach(element => {
                if(element.USERGUID == this.currentuser.DEFAULTOWNERGUID){
                    this.matterdetailForm.controls['OWNERGUID'].setValue(element.USERGUID);
                    this.matterdetailForm.controls['OWNERNAME'].setValue(element.USERID);
                }
            });    
            }
            
             }
            
            } else if (response.MESSAGE == 'Not logged in') {
            }
            this.isLoadingResults = false;
          }, error => {
            this.isLoadingResults = false;
          });
    }

/**
 * This function is used to get the permission 
 * @param permission -permission data value
 * @returns permission data value
 */
    getPermission_1(permission) {
        return this.accessTierPermission.filter(e => e.FEATURENAME == permission)[0];
    }

    /**
     * This function is used to get the drop value
     */
    getDropValue() {
        let userList = JSON.parse(localStorage.getItem('users_list'));
        if (userList && userList != null) {
            this.MatterDropData = this.Timersservice.removeUSerWithoutId(userList);
        } else {
            let d = {};
           this.sub16 = this.Timersservice.GetUsers(d).subscribe(response => {
                if (response.CODE == 200 && response.STATUS == "success") {
                    this.MatterDropData = this.Timersservice.removeUSerWithoutId(response.DATA.USERS);
                    localStorage.setItem('users_list', JSON.stringify(response.DATA.USERS));
                }
            }, err => {
                console.log(err);
            });
        }
    }

    /**
     * This function is used to Change the matterUserchange data 
     * @param value -value data
     */
    MatterUserChange(value) {
        if (value && value.includes("")) {
            if (value.length > 1) {
                if (this.isNoneSelect) {
                    const none = ['']
                    this.matterdetailForm.controls['MATTERACCESSLIST'].setValue(none)
                    this.isNoneSelect = false;
                } else {
                    if (value.includes("all")) {
                        const array = []
                        this.MatterDropData.forEach((user: any) => {
                            array.push(user.USERGUID)
                        })
                        array.push("all");
                        this.matterdetailForm.controls['MATTERACCESSLIST'].setValue(array);
                        this.isNoneSelect = true;
                    } else {
                        var filteredAry = value.filter(e => e !== '');
                        this.matterdetailForm.controls['MATTERACCESSLIST'].setValue(filteredAry);
                        this.isNoneSelect = true;
                    }
                }
            } else {
                const none = ['']
                this.matterdetailForm.controls['MATTERACCESSLIST'].setValue(none)
                this.isNoneSelect = true;
            }
        } else {
            if (value.includes("all")) {
                const array = []
                this.MatterDropData.forEach((user: any) => {
                    array.push(user.USERGUID)
                })
                array.push("all");
                this.matterdetailForm.controls['MATTERACCESSLIST'].setValue(array);
                this.isNoneSelect = true;
            }
        }
    }

    /**
     * This function is used to onkeypress Text message
     * @param event -event data value
     */
    onKeyPressTextMessage(event) {
        var textArea = event.currentTarget;
        textArea.style.height = 'auto';
        textArea.style.height = textArea.scrollHeight + 'px';
    };

    /**
     * This unction is used to goTo URl data value.
     * @param url -url value data
     */
    goTo(url) {
        if (url) {
            this.openWebsite = this.sanitizer.bypassSecurityTrustResourceUrl(url.replace('http://', 'https://'))
        } else {
            this.openWebsite = undefined
        }

        if (this.openWebsite) {
            window.open(this.openWebsite.changingThisBreaksApplicationSecurity, "_blank");
        }
    }

    async AddContactsDialog() {

        this.behaviorService.MatterpopupContactData$.next(null);
        const dialogRef = await this._matDialog.open(ContactDialogComponent, {
            disableClose: true, panelClass: 'contact-dialog', data: { action: 'new', isReturn: true, contect_details: this._data.contect_details, flagNewConactMatter: true }
        });
        dialogRef.afterClosed().subscribe(async result => {
            if (result) {
                if (result.CONTACTGUID) {
                    let contectIdIs = await this.getContactNewId();
                    this.behaviorService.MatterpopupContactData$.next(contectIdIs);
                    if (result) {
                        if (this._data.action == "new" || this._data.action == "duplicate") {
                            this.Clientchild.CoommonMatterNum(result.CONTACTGUID);
                        }
                        this.matterdetailForm.controls['FIRMGUID'].setValue(result.CONTACTGUID);
                        this.matterdetailForm.controls['Clientmattertext'].setValue(result.CONTACTNAME);
                        this.matterdetailForm.controls['CONTACTTYPE'].setValue(result.CONTACTTYPE);
                        this.mattername = [result.CONTACTNAME + ' - ' + result.SUBURB];

                        // if (result.CONTACTTYPE == "Person") {
                        this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, { disableClose: true, width: '100%', });
                        this.confirmDialogRef.componentInstance.confirmMessage = 'Do you want to add ' + result.CONTACTNAME + ' to the Correspondent list?';
                        this.confirmDialogRef.afterClosed().subscribe(result2 => {
                            if (result2) {
                                let matterData: any = {};
                                this.behaviorService.MatterEditData$.subscribe(result => {
                                    matterData = result;
                                });


                                if (this._data.action != "new") {
                                    let details: any = {
                                        TYPE: "Correspondent", SOLICITORGUID: result.CONTACTGUID,
                                        REFERENCE: "", PERSONGUID: result.CONTACTGUID, MATTERGUID: matterData.MATTERGUID
                                    };
                                    this._mainAPiServiceService.getSetData({ FORMACTION: 'insert', VALIDATEONLY: false, DATA: details }, 'matter-contact').subscribe(response => {
                                        if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
                                            this.toastr.success('Matter Contact save successfully');
                                            this.Clientchild.loadData('', '');
                                            this.isspiner = false;
                                        } else if (response.CODE == 451 && response.STATUS == 'warning') {
                                            this.isspiner = false;
                                            this.toastr.warning(response.MESSAGE);
                                        } else if (response.CODE == 450 && response.STATUS == 'error') {
                                            this.isspiner = false;
                                            this.toastr.error(response.MESSAGE);
                                        } else if (response.MESSAGE == 'Not logged in') {
                                            this.isspiner = false;
                                        }
                                    }, (error: any) => {
                                        console.log(error);
                                    });
                                } else {
                                    this.Clientchild.CorrespondDetail.emit({
                                        CorrespondRoleAndReference: [{ role: "Correspondent", ref: '' }], TYPE: "Correspondent", TYPENAME: "Correspondent", SOLICITORGUID: result.CONTACTGUID,
                                        REFERENCE: "", PERSONGUID: result.CONTACTGUID, MATTERGUID: "", action: 'add'
                                    });
                                    this.CorrespondDetail
                                    let itemData: any = {
                                        SOLICITORGUID: '',
                                        REFERENCE: '',
                                        PERSONGUID: result.CONTACTGUID,
                                        MATTERGUID: undefined,
                                        RELATIONSHIP: '',
                                        SHAREOFESTATE: '',
                                        CONTACTNAME: result.CONTACTNAME,
                                        TYPENAME: 'Correspondent',
                                        SOLICITORNAME: ''
                                    };
                                    this.Clientchild.Correspond.push({ 'type': "Correspondent", 'Text': result.CONTACTNAME, ItemData: itemData });
                                }
                            }
                            this.confirmDialogRef = null;
                        });
                        // }
                    }
                } else {
                    this.behaviorService.MatterpopupContactData$.next(null)
                }
            }
        });
    }

    getContactNewId() {
        let contecttId = '';
        let postData = {
            "Action": "GetContactId",
            "VALIDATEONLY": true,
            "Data": {},
        };

        return new Promise((response, reject) => {
           this.sub17 = this._mainAPiServiceService.getSetData(postData, 'contact').subscribe(res => {
                if (res.CODE == 200) {
                    contecttId = res.DATA.CONTACTID;
                    response(contecttId);
                } else {
                    response(null);
                }
            });
        })
    }
    // save maritime
    saveMaritime(MATTERGUID, VesselId?) {
        let details = {};
        details["MatterGuid"] = MATTERGUID;
        details["VesselName"] = this.f.VESSELNAME.value;
        details["VesselFlag"] = this.f.VESSELFLAG.value;
        details["VesselType"] = this.f.VESSELTYPE.value;
        details["Tonnage"] = this.f.TONNAGE.value;
        details["VesselMaster"] = this.f.VESSELMASTER.value;
        details["VesselLocation"] = this.f.VESSELLOCATION.value;
        details["PURCHASEPRICE"] = this.f.PURCHASEPRICE.value;

        details["IncidentDate"] = this.f.INCIDENTDATE.value;
        details["EXCHANGEDATE"] = this.f.EXCHANGEDATE.value;
        details["SETTLEMENTDATE"] = this.f.SETTLEMENTDATE.value;
        details["MatterVesselGuid"] = this.MATTERVESSELGUID;


       this.sub18 = this._mainAPiServiceService.getSetData({ Action: this.MATTERVESSELGUID == '' ? 'Insert' : 'Update', VALIDATEONLY: false, DATA: details }, 'matter-vessel').subscribe(response => {
            if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
                this.toastr.success('Mairitime Detail save successfully');
            }
            this.isspiner = false;
        }, error => {
            this.toastr.error(error);
        });


    }
    // ends here ~ save maritime

    // get maritime on load
    getMaritime(MATTERGUID) {
       this.sub19 = this._mainAPiServiceService.getSetData({ Action: 'GetData', "Filters": { "MatterGuid": MATTERGUID } }, 'matter-vessel').subscribe(response => {
            if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
                let matterDetailsData = response.DATA.RECORDS[0];

                this.MATTERVESSELGUID = matterDetailsData.MATTERVESSELGUID
                let incidentDateSplit = [];
                if (matterDetailsData.INCIDENTDATE) {
                    incidentDateSplit = matterDetailsData.INCIDENTDATE.split("/")
                }
                const newIncidentDate = new Date(incidentDateSplit[1] + '/' + incidentDateSplit[0] + '/' + incidentDateSplit[2]);

                if (matterDetailsData.EXCHANGEDATE) {
                    let EXCHANGEDATE1 = matterDetailsData.EXCHANGEDATE.split("/");
                    this.matterdetailForm.controls['EXCHANGEDATETEXTM'].setValue(new Date(EXCHANGEDATE1[1] + '/' + EXCHANGEDATE1[0] + '/' + EXCHANGEDATE1[2]));
                    this.matterdetailForm.controls['EXCHANGEDATE'].setValue(matterDetailsData.EXCHANGEDATE);
                }
                if (matterDetailsData.SETTLEMENTDATE) {
                    let SETTLEMENTDATE1 = matterDetailsData.SETTLEMENTDATE.split("/");
                    this.matterdetailForm.controls['SETTLEMENTDATETEXTM'].setValue(new Date(SETTLEMENTDATE1[1] + '/' + SETTLEMENTDATE1[0] + '/' + SETTLEMENTDATE1[2]));
                    this.matterdetailForm.controls['SETTLEMENTDATE'].setValue(matterDetailsData.SETTLEMENTDATE);
                }

                // this.matterdetailForm.patchValue({
                //   VESSELNAME: matterDetailsData.VESSELNAME,
                //   VESSELFLAG: matterDetailsData.VESSELFLAG,
                //   VESSELTYPE: matterDetailsData.VESSELTYPE,
                //   TONNAGE: matterDetailsData.TONNAGE,
                //   VESSELMASTER: matterDetailsData.VESSELMASTER,
                //   VESSELLOCATION: matterDetailsData.VESSELLOCATION,
                //   PURCHASEPRICE: matterDetailsData.PURCHASEPRICE,
                //   INCIDENTDATETEXTM: newIncidentDate,
                //   INCIDENTDATE: matterDetailsData.INCIDENTDATE,
                // })



            }
            this.isspiner = false;
        }, error => {
            this.toastr.error(error);
        });
    }
    // ends here ~ get maritime on load
    selectMatterFormSearch(event: string) {
        this.matterlist = [];
        if (event.length == 0) {
            this.matterlist = [];
        }
        // let searchobj = { FastSearch: 'True', Search: event }
        let payload = {
            "ACTION": "GetData",
            "FILTERS": {
                "FASTSEARCH": 1,
                "SEARCH": event
            },
        }
      this.sub20 = this._mainAPiServiceService.getSetData(payload, 'contact').subscribe(response => {
            if (response.CODE == 200 && response.STATUS == "success") {
                this.exportdatavalue = response.DATA;
                this.searchData = response.DATA.RECORDS;
                if (response.DATA.RECORDS.length == 0) {
                    this.matterlist.push({ 'CONTACTNAME': event });
                } else {
                    this.matterlist = this.searchData;
                }
            } else if (response.CODE == 406 && response.STATUS == "error") {
                this.toastr.error(response.MESSAGE);
            }
        })
    }

    datashow(event) {
        this.matterlist = [];
        this.CallHostlistner = true;
        if (this.exportdatavalue && this.exportdatavalue.RECORDS && this.exportdatavalue.RECORDS.length != 0) {
            this.result = this.exportdatavalue.RECORDS[0];
            this.matterlist = [this.result.CONTACTNAME]
            this.matterdetailForm.controls['Clientmattertext'].setValue(null);
            if (event.input) {
                event.input.value = '';
            } else {
                this.MatterInput.nativeElement.value = '';
            }
            if (this.result) {
                if (this._data.action == "new" || this._data.action == "duplicate") {
                    this.Clientchild.CoommonMatterNum(this.result.CONTACTGUID);
                }
                this.matterdetailForm.controls['FIRMGUID'].setValue(this.result.CONTACTGUID);
                this.matterdetailForm.controls['Clientmattertext'].setValue(this.result.CONTACTNAME);
                this.matterdetailForm.controls['CONTACTTYPE'].setValue('');
                this.mattername = [this.f.Clientmattertext.value]
                // if (result.CONTACTTYPE == "Person") {
                this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, { disableClose: true, width: '100%', });
                this.confirmDialogRef.componentInstance.confirmMessage = 'Do you want to add ' + this.result.CONTACTNAME + ' to the Correspondent list?';
                this.confirmDialogRef.afterClosed().subscribe(result2 => {
                    if (result2) {
                        let matterData: any = {};
                        this.behaviorService.MatterEditData$.subscribe(result => {
                            matterData = result;
                        });


                        if (this._data.action != "new") {
                            let details: any = {
                                TYPE: "Correspondent", SOLICITORGUID: this.result.CONTACTGUID,
                                REFERENCE: "", PERSONGUID: this.result.CONTACTGUID, MATTERGUID: matterData.MATTERGUID
                            };
                            this._mainAPiServiceService.getSetData({ FORMACTION: 'insert', VALIDATEONLY: false, DATA: details }, 'matter-contact').subscribe(response => {
                                if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
                                    this.toastr.success('Matter Contact save successfully');
                                    this.Clientchild.loadData('', '');
                                    this.isspiner = false;
                                } else if (response.CODE == 451 && response.STATUS == 'warning') {
                                    this.isspiner = false;
                                    this.toastr.warning(response.MESSAGE);
                                } else if (response.CODE == 450 && response.STATUS == 'error') {
                                    this.isspiner = false;
                                    this.toastr.error(response.MESSAGE);
                                } else if (response.MESSAGE == 'Not logged in') {
                                    this.isspiner = false;
                                }
                            }, (error: any) => {
                                console.log(error);
                            });
                        } else {
                            this.Clientchild.CorrespondDetail.emit({
                                CorrespondRoleAndReference: [{ role: "Correspondent", ref: '' }], TYPE: "Correspondent", TYPENAME: "Correspondent", SOLICITORGUID: this.result.CONTACTGUID,
                                REFERENCE: "", PERSONGUID: this.result.CONTACTGUID, MATTERGUID: "", action: 'add'
                            });
                            this.CorrespondDetail
                            let itemData: any = {
                                SOLICITORGUID: '',
                                REFERENCE: '',
                                PERSONGUID: this.result.CONTACTGUID,
                                MATTERGUID: undefined,
                                RELATIONSHIP: '',
                                SHAREOFESTATE: '',
                                CONTACTNAME: this.result.CONTACTNAME,
                                TYPENAME: 'Correspondent',
                                SOLICITORNAME: ''
                            };
                            this.Clientchild.Correspond.push({ 'type': "Correspondent", 'Text': this.result.CONTACTNAME, ItemData: itemData });
                        }
                    }
                    this.confirmDialogRef = null;
                });
                // }
            }

        } else {
            this.toolbarServiceService.ContactsDialog('new', this.MatterInput.nativeElement.value);
            this.mattername = [];
            this.matterlist = [];
            this.MatterInput.nativeElement.value = '';
        }
        this.CallHostlistner = false;
    }

    @HostListener('document:click', ['$event'])
    documentClick(event: MouseEvent) {
        let result = this.MatterInput.nativeElement.value;
        if (result && result != null && result != undefined && this.CallHostlistner == false) {
            this.datashow(this.MatterInput.nativeElement);
            this.MatterInput.nativeElement.value = '';
            this.matterdetailForm.controls['Clientmattertext'].setValue(null);
        }
    }

    remove(fruit: any) {
        this.behaviorService.newContactData$.next(null);
        const index = this.mattername.indexOf(fruit);
        if (index >= 0) {
            this.mattername.splice(index, 1);
            this.matterdetailForm.controls['FIRMGUID'].setValue('');
            //this.Deliverable = [];
        }
        let sortname = fruit.split('-')[0]
        this.rm(sortname)
    };

    rm(sortname) {
        this.selectedData.forEach((element: any, index: any) => {
            if (element.CONTACTNAME.toString() == sortname.replace(/^\s+|\s+$/gm, '')) {
                this.selectedData.splice(index, 1);
            }
        })
    }

    add(event: MatChipInputEvent): void {
        const value = (event.value || '').trim();
        this.matterlist.forEach((element: any) => {
            if (element.CONTACTNAME == event.value) {
                // Add our fruit
                const array3 = this.mattername;
                if (value) {
                    if (this.mattername.indexOf(value.replace(/^\s+|\s+$/gm, '')) == -1) {
                        this.mattername = [value]
                    }
                }
            }
        });

        // Clear the input value
        if (event.input) {
            event.input.value = '';
        }
        this.matterdetailForm.controls['Clientmattertext'].setValue(null);
    };

    selectedValue(event: MatAutocompleteSelectedEvent): void {
        if (this.mattername.indexOf(event.option.viewValue.replace(/^\s+|\s+$/gm, '')) == -1) {
            this.mattername = [event.option.viewValue]
            this.MatterInput.nativeElement.value = '';
            this.matterdetailForm.controls['Clientmattertext'].setValue(null);
        }
    }

    selectedmatterguid(result: any) {
        this.MatterInput.nativeElement.value = '';
        if (Object.keys(result).length == 1) {
            this.toolbarServiceService.ContactsDialog('new', result.CONTACTNAME);
            this.mattername = [];
            this.matterlist = [];
        } else {
            if (result) {
                if (this._data.action == "new" || this._data.action == "duplicate") {
                    this.Clientchild.CoommonMatterNum(result.CONTACTGUID);
                }
                this.matterdetailForm.controls['FIRMGUID'].setValue(result.CONTACTGUID);
                this.matterdetailForm.controls['Clientmattertext'].setValue(result.CONTACTNAME);
                this.matterdetailForm.controls['CONTACTTYPE'].setValue('');
                this.mattername = [this.f.Clientmattertext.value]
                // if (result.CONTACTTYPE == "Person") {
                this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, { disableClose: true, width: '100%', });
                this.confirmDialogRef.componentInstance.confirmMessage = 'Do you want to add ' + result.CONTACTNAME + ' to the Correspondent list?';
                this.confirmDialogRef.afterClosed().subscribe(result2 => {
                    if (result2) {
                        let matterData: any = {};
                        this.behaviorService.MatterEditData$.subscribe(result => {
                            matterData = result;
                        });
                        if (this._data.action != "new") {
                            let details: any = {
                                TYPE: "Correspondent", SOLICITORGUID: result.CONTACTGUID,
                                REFERENCE: "", PERSONGUID: result.CONTACTGUID, MATTERGUID: matterData.MATTERGUID
                            };
                            this._mainAPiServiceService.getSetData({ FORMACTION: 'insert', VALIDATEONLY: false, DATA: details }, 'matter-contact').subscribe(response => {
                                if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
                                    this.toastr.success('Matter Contact save successfully');
                                    this.Clientchild.loadData('', '');
                                    this.isspiner = false;
                                } else if (response.CODE == 451 && response.STATUS == 'warning') {
                                    this.isspiner = false;
                                    this.toastr.warning(response.MESSAGE);
                                } else if (response.CODE == 450 && response.STATUS == 'error') {
                                    this.isspiner = false;
                                    this.toastr.error(response.MESSAGE);
                                } else if (response.MESSAGE == 'Not logged in') {
                                    this.isspiner = false;
                                }
                            }, (error: any) => {
                                console.log(error);
                            });
                        } else {
                            this.Clientchild.CorrespondDetail.emit({
                                CorrespondRoleAndReference: [{ role: "Correspondent", ref: '' }], TYPE: "Correspondent", TYPENAME: "Correspondent", SOLICITORGUID: result.CONTACTGUID,
                                REFERENCE: "", PERSONGUID: result.CONTACTGUID, MATTERGUID: "", action: 'add'
                            });
                            this.CorrespondDetail
                            let itemData: any = {
                                SOLICITORGUID: '',
                                REFERENCE: '',
                                PERSONGUID: result.CONTACTGUID,
                                MATTERGUID: undefined,
                                RELATIONSHIP: '',
                                SHAREOFESTATE: '',
                                CONTACTNAME: result.CONTACTNAME,
                                TYPENAME: 'Correspondent',
                                SOLICITORNAME: ''
                            };
                            this.Clientchild.Correspond.push({ 'type': "Correspondent", 'Text': result.CONTACTNAME, ItemData: itemData });
                        }
                    }
                    this.confirmDialogRef = null;
                });
            }
        }
    };
    RestFolderPath(data: any) {

        this.confirmDialogRef = this._matDialog.open(
            FuseConfirmDialogComponent,
            {
                disableClose: true,
                width: "100%",

            }
        );

        let field_type = "";
        let person_name = "";
        let formControlName = '';
        let formControlNameGUID = '';
        switch (data) {
            case 'OWNERNAME':
                field_type = 'Matter Owner';
                person_name = this.matterdetailForm.controls['OWNERNAME'].value
                formControlName = 'OWNERNAME';
                formControlNameGUID ='OWNERGUID'
                break;
            case 'PRIMARYFEEEARNERNAME':
                field_type = 'Primary Fee Earner';
                person_name = this.matterdetailForm.controls['PRIMARYFEEEARNERNAME'].value
                formControlName = 'PRIMARYFEEEARNERNAME';
                formControlNameGUID = 'PRIMARYFEEEARNERGUID'
                break;
            case 'ASSISTANTNAME':
                field_type = 'Person Assisting';
                person_name = this.matterdetailForm.controls['ASSISTANTNAME'].value
                formControlName = 'ASSISTANTNAME';
                formControlNameGUID = 'ASSISTANTGUID'
                break;

            default:
                break;
        }
        this.confirmDialogRef.componentInstance.confirmMessage =
             'Are you sure you want to remove  "'  + person_name + '" as the "'+ field_type+ '" ?'
        //`Are you sure you want to remove <strong>${person_name}</strong> as the <strong>${field_type}</strong>?`
        this.confirmDialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.matterdetailForm.controls[formControlName].setValue('');
                this.matterdetailForm.controls[formControlNameGUID].setValue('');

            }
        });

    }
}
