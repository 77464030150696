import { Component, OnInit, Input, ViewChild, ViewEncapsulation, Output, EventEmitter, OnDestroy, ViewChildren, QueryList, ElementRef, Renderer2 } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { fuseAnimations } from 'src/@fuse/animations';
import { SelectionModel } from '@angular/cdk/collections';
import { MatSort } from '@angular/material/sort';
import { ResizeEvent } from 'angular-resizable-element';
import { Router } from "@angular/router";
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { BehaviorService } from '@_services/Behavior.service';
import { SortingBehaviourService } from '@_services/sorting-behaviour.service';
import { Subject, map, takeUntil, tap } from 'rxjs';
import { CurrentUser } from 'src/app/_models/CurrentUser';
import { MatDialog } from '@angular/material/dialog';
import { SpendMoneyAddComponent } from '@pages/spend-money/spend-money-add-dialog/spend-money-add.component';
import * as $ from 'jquery';
import { GlobalFunctionsService } from '@_services/global-functions.service';
import { ToastrService } from 'ngx-toastr';
import { TimersService } from '@_services/timers.service';
import { WriteOffTimeEntryComponent } from '@pages/time-entries/write-off-time-entry/write-off-time-entry.component';
import { ToolbarServiceService } from 'src/app/layout/components/toolbar/toolbar-service.service';
import { MoveEntryComponent } from '@pages/time-entries/move-entry/move-entry.component';
import { SplitTimeEntryComponent } from '@pages/split-time-entry/split-time-entry.component';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
export class Group {
    level = 0;
    expanded = false;
    totalCounts = 0;
}
@Component({
    selector: 'app-details',
    templateUrl: './details.component.html',
    styleUrls: ['./details.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class DetailsComponent implements OnInit, OnDestroy {
    @ViewChildren('textAreas') textAreas: QueryList<ElementRef>;
    public bulkInvoiceWorkItems$=this.behaviorService.bulkInvoiceWorkItemslist$.asObservable().pipe(map(data=>({invoiceBulkWorkItems:data})))
    @Input() addInvoiceForm: FormGroup;
    @Output() totalDataOut: EventEmitter<any> = new EventEmitter<any>();
    @Input() MattersData
    invoiceData: any = [];
    uninvoicedData = []; // uninvoiced and un write off data List for chekcboc
    displayedColumnsTime: string[]
    // displayedColumnsTime: string[] = ['select', 'ITEMDATE','FEEEARNER', 'ADDITIONALTEXT', 'PRICE', 'GST', 'PRICEINCGST'];
    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: false }) sort: MatSort;
    selection = new SelectionModel(true, []);
    groupByColumns = ['ITEMTYPEDESC'];
    allData: any = [];
    currentMatter: any;
    _allGroup: any[];
    expandedSubCar: any[] = [];
    tempTotalData = {};
    expandedCar: any[] = [];
    windowNameId: any;
    currentUser: CurrentUser = JSON.parse(localStorage.getItem('currentUser'));
    sortingDefaultState: any;
    sortactive: any;
    sortDirection: any;
    private _unsubscribeAll$: Subject<void> = new Subject();
    loader: boolean;
    isShownCheckBox: boolean=true;
    oldIsTrackId: any;
    className: string;
    WorkitemGuid: any;
    //isShownsavecancel: boolean=false;
    UpdatedText: any;
    initialRow: any;
    RequestGuid: string;
    currentDraggedAddText: string;
    currentDraggedAddClient: string;
    highlightedRows: any;
    appPermissions = JSON.parse(localStorage.getItem("app_permissions"));
    selectedColore: string = 'rgb(217, 217, 217)';
    toggleValue: boolean=false;
    // editingRowIndex: number | null = null;
    // editingField: string | null = null;
    // calculateData: any = {
    //     MatterGuid: '', QuantityType: '', Quantity: '', FeeEarner: '', FeeType: '', rateid: ''
    // };
    constructor(private renderer: Renderer2,public globalFunctionService: GlobalFunctionsService,public toolbarServiceService: ToolbarServiceService,public _matDialog: MatDialog,
        private _mainAPiServiceService: MainAPiServiceService, private behaviorService: BehaviorService, private SortingbehaviorService: SortingBehaviourService, public router: Router,private dialog: MatDialog,private toastr:ToastrService,private Timersservice: TimersService,) {
        // this.behaviorService.MatterData$.subscribe(result => {
        //   if (result) {
        //     this.currentMatter = result;
        //   }
        // });
        this.SortingbehaviorService.CIScreenSorting$.pipe(takeUntil(this._unsubscribeAll$)).subscribe((result) => {
            if (result) {
                this.sortingDefaultState = result;
                localStorage.setItem('Create_Invoice_screen_sorting', JSON.stringify(result));
            } else {
                this.sortingDefaultState = JSON.parse(localStorage.getItem('Create_Invoice_screen_sorting'))
            }
        });

        this.behaviorService.checktogglestatus$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(data=>{
            if(data !=undefined){
                this.toggleValue=data;
                if(data == true){
                        this.displayedColumnsTime.unshift('select');
                }else{
                    if(this.displayedColumnsTime?.length >0 && this.displayedColumnsTime[0] == 'select'){
                        this.displayedColumnsTime.splice(0,1);
                        
                    }
                }
            }
        })

        // this.behaviorService.MatterData$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(result => {
        //     const materIDofTab = window.name.split("_");
        //     this.windowNameId = (materIDofTab && materIDofTab[1]) ? materIDofTab[1] : undefined;
        //     this.currentMatter = JSON.parse(localStorage.getItem(this.windowNameId ? this.windowNameId : 'set_active_matters'));
        //     if (this.currentMatter) {
        //     } else {
        //         if (result) {
        //             this.currentMatter = result;
        //             localStorage.setItem(this.windowNameId, JSON.stringify(this.currentMatter))
        //         }
        //     }
        // });
        /**
                 *  Subscribes to the clickMaterFromTimeLine$ observable provided by behaviorService.
                 * @remarks
                 * It is particularly relevant for scenarios where the behaviorService controls the "open in new tab"
                 * feature(on or off), and the selection of matterGuid is influenced by whether multiple tabs are open.
                 */
        this.behaviorService.clickMaterFromTimeLine$
            .pipe(
                tap(result => {
                    if (result) {
                        this.currentMatter = result;
                    } else {
                        this.currentMatter = JSON.parse(localStorage.getItem(this.windowNameId || 'set_active_matters')) || result;
                        localStorage.setItem(this.windowNameId || 'set_active_matters', JSON.stringify(this.currentMatter));
                    }
                })
            )
            .subscribe();

    }

     /**
     * It runs once after the component's view has been fully initialized.
    */
    ngOnInit() {
        // if (this.sortingDefaultState) {
        //   this.sortactive = this.sortingDefaultState.active;
        //   this.sortDirection = this.sortingDefaultState.direction;
        // } else {
        //   this.sortactive = "ITEMDATE";
        //   this.sortDirection = "asc";
        //   // this.sortDirection = "desc";
        // }
        this.sortactive = "ITEMDATE";
        this.sortDirection = "asc";

        if (this.currentUser.PRODUCTTYPE == "Solicitor") {
            if(this.router.url == "/invoice/bulk-invoices"){
                this.displayedColumnsTime = ['select', 'ITEMDATE', 'FEEEARNER', 'ADDITIONALTEXT','RATE','QTY', 'PRICE', 'GST', 'PRICEINCGST'];
            }
            else{
                this.displayedColumnsTime = ['select', 'ITEMDATE', 'FEEEARNER', 'ADDITIONALTEXT', 'PRICE', 'GST', 'PRICEINCGST'];
            }
        } else {
            if(this.router.url == "/invoice/bulk-invoices"){
                this.displayedColumnsTime = ['select', 'ITEMDATE', 'ADDITIONALTEXT', 'RATE', 'QTY', 'PRICE', 'GST', 'PRICEINCGST'];
            }
            else{
                this.displayedColumnsTime = ['select', 'ITEMDATE', 'ADDITIONALTEXT', 'RATE', 'PRICE', 'GST', 'PRICEINCGST'];
            }
        }
        this.invoiceData = [];
        /** old starts here
         *
         * this._mainAPiServiceService.getSetData({ MATTERGUID: this.currentMatter.MATTERGUID, Invoiced: 'No', INVOICING: true },
         *
         *  old ends here
         */

        if(this.MattersData == undefined){
            this.getWorkItemsData();
            this.className="invoicetable-block"
        }
        
        this.bulkInvoiceWorkItems$.subscribe(data=>{
            if(data && data !=null && data !=undefined && data.invoiceBulkWorkItems !=null && data.invoiceBulkWorkItems !=undefined && Object.keys(data.invoiceBulkWorkItems).length > 0){
                this.MattersData=data.invoiceBulkWorkItems;
                this.getWorkItemsData();
                this.className="invoicetable-block2"
                setTimeout(() => {
                let data=localStorage.getItem('Spellcheck');
                console.log(data);
                if(data == 'true'){
                    this.triggerSpellCheck();
                }
                },2000);
            }else{
               // this.getWorkItemsData();
            }
        })
    }

    // startEdit(rowIndex: number, field: string): void {
    //     this.editingRowIndex = rowIndex;
    //     this.editingField = field;
    // }
    
    // isEditing(rowIndex: number, field: string): boolean {
    //     return this.editingRowIndex === rowIndex && this.editingField === field;
    // }
    
    // saveEdit(rowIndex: number, row: any): void {
    //     this.loader = true;
    //     this.cancelEdit();
    //     this.utilityAPI(row);
    // }
    
    // cancelEdit(): void {
    //     this.editingRowIndex = null;
    //     this.editingField = null;
    // }

    // utilityAPI(row){
    //     this.calculateData.MatterGuid = row.MATTERGUID;
    //     switch (row.QUANTITYTYPE) {
    //         case 'hh:mm': {
    //             this.calculateData.QuantityType = 'X';
    //             break;
    //         }
    //         case 'Hours': {
    //             this.calculateData.QuantityType = 'H';
    //             break;
    //         }
    //         case 'Minutes': {
    //             this.calculateData.QuantityType = 'M';
    //             break;
    //         }
    //         case 'Days': {
    //             this.calculateData.QuantityType = 'D';
    //             break;
    //         }
    //         case 'Units': {
    //             this.calculateData.QuantityType = 'U';
    //             break;
    //         }
    //         case 'Fixed': {
    //             this.calculateData.QuantityType = 'F';
    //             break;
    //         }
    //         default: {
    //             this.calculateData.QuantityType = 'F';
    //             break;
    //         }
    //     }
    //     this.calculateData.Quantity = row.QUANTITY;
    //     this.calculateData.FeeEarner = row.FEEEARNER;
    //     this.calculateData.FeeType = row.FEETYPE;
    //     this.calculateData.rateid = row.RATEID;
    //     this.Timersservice.calculateWorkItems(this.calculateData).subscribe(response => {
    //         if (response.CODE == 200 && response.STATUS == "success") {
    //             let CalcWorkItemCharge = response.DATA;
    //             row.PRICE = CalcWorkItemCharge.PRICE;
    //             row.PRICEINCGST = Number(CalcWorkItemCharge.PRICEINCGST);
    //             row.GST = Number(CalcWorkItemCharge.GST);
    //             this.SaveClickTimeEntry(row);
    //         } else if (response.MESSAGE == 'Not logged in') {
    //             this.loader = false;
    //         }
    //     }, err => {
    //         this.toastr.error(err);
    //         this.loader = false;
    //     });
    // }

    // numberOnly(event: KeyboardEvent) {
    //     const allowedCharacters = /[0-9.]/;
    //     const inputChar = String.fromCharCode(event.charCode);
    //     if (!allowedCharacters.test(inputChar)) {
    //       event.preventDefault();
    //     }
    // }

    /**
     * This function is used to get the work items data value
     */
getWorkItemsData(){
    this.loader=true;
    let filterData = JSON.parse(localStorage.getItem("wip_config_secondary"));
        if (filterData && (this.router.url == "/time-billing/work-in-progress" ||  this.router.url == "/matter-details/work-in-progress")) {
            filterData["INVOICING"] = true;
        }else if(this.MattersData && this.MattersData !=undefined && this.MattersData !=null && this.router.url != "/time-billing/work-in-progress" && this.router.url != "/invoice/sub-invoices" && this.router.url != "/matters"){
            if(this.displayedColumnsTime[0] == 'select'){
                this.displayedColumnsTime.splice(0,1);
                let index=this.displayedColumnsTime.indexOf('link');
                if(index == -1){
                    this.displayedColumnsTime.push('link');
                }
                this.isShownCheckBox=false;
            }
            filterData = { MATTERGUID: this.MattersData.MATTERGUID, Invoiced: 'No', INVOICING: true }
           // this.behaviorService.bulkInvoiceWorkItemslist$.next(null);
        }
         else {
            filterData = { MATTERGUID: this.currentMatter.MATTERGUID, Invoiced: 'No', INVOICING: true }
        }
        // filterData["MATTERGUID"] = this.currentMatter.MATTERGUID;

        // set current selected matter GUID to active matter
        // const activeMatterStr = window.localStorage.getItem("set_active_matters")
        const activeMatterStr = JSON.parse(localStorage.getItem(this.windowNameId ? this.windowNameId : 'set_active_matters'));
        if (activeMatterStr) {
            try {
                const activeMatter = JSON.parse(activeMatterStr);
                if (activeMatter) {
                    filterData["MATTERGUID"] = activeMatter.MATTERGUID;
                }
            } catch (err) { }
        }
        // ends here ~ set current selected matter GUID to active matter


        if (filterData.MatterGuid) {
            filterData["MATTERGUID"] = filterData.MatterGuid;
            delete filterData.MatterGuid;
        };
        if(this.router.url == "/invoice/bulk-invoices") {
            filterData["FormatAsTreeGrid"] = true;
        }
        this._mainAPiServiceService.getSetData({ "Action": "GetData", "Filters": filterData }, 'workitem').pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
            if (response.CODE == 200 && response.STATUS == "success") {
                let tempData = [];
                this.uninvoicedData = [];
                if(this.router.url != "/invoice/bulk-invoices") {
                    this.behaviorService.refreshinvoiceWorkItem.next(response.DATA.WORKITEMS);
                }

                if(this.router.url == "/invoice/bulk-invoices") {
                    const workItems = response.DATA.ITEMTYPES;
                    workItems.forEach(group => {
                        this.tempTotalData[group.ITEMTYPEDESC] = group;
                        if (Array.isArray(group.DELIVERABLES)) {
                            group.DELIVERABLES.forEach(del => {
                                del.WORKITEMS.forEach(workItem => {
                                    if (!workItem.ISGROUPBY) {
                                        tempData.push(workItem);
                                        if (!workItem.INVOICEGUID) {
                                            this.uninvoicedData.push(workItem);
                                        }
                                    }
                                });
                            });
                        } else {
                            group.WORKITEMS.forEach(workItem => {
                                if (!workItem.ISGROUPBY) {
                                    tempData.push(workItem);
                                    if (!workItem.INVOICEGUID) {
                                        this.uninvoicedData.push(workItem);
                                    }
                                }
                            });
                        }
                    });
                }
    
                if(this.router.url != "/invoice/bulk-invoices") {
                    response.DATA.WORKITEMS.forEach(group => {
                        if (!group.ISGROUPBY) {
                            tempData.push(group);
                            if (!group.INVOICEGUID) {
                                this.uninvoicedData.push(group);
                            }
                        } else {
                            this.tempTotalData[group.ITEMTYPEDESC] = group;
                        }
                    });
                }

                console.log('this.tempTotalData',this.tempTotalData);
                if(this.router.url == "/invoice/bulk-invoices") {
                    this.behaviorService.refreshinvoiceWorkItem.next(tempData);
                }
                this.allData = tempData;
                tempData.forEach(element => {
                    element.isShownsavecancel=false
                });
                this.initialRow = JSON.parse(JSON.stringify(tempData));
                if(this.router.url == "/invoice/bulk-invoices") {
                    this.behaviorService.refreshinvoiceWorkItem.next(this.allData);
                }
                if(this.router.url != "/invoice/bulk-invoices") {
                    if (response.DATA.WORKITEMS[0]) {
                        this.highlightedRows = response.DATA.WORKITEMS[0].WORKITEMGUID;
                    }
                    if(response.DATA.WORKITEMS[1]){
                        localStorage.setItem('FEEEARNERDATA',response.DATA.WORKITEMS[1].FEEEARNER);
                    }
                }

                if(this.router.url == "/invoice/bulk-invoices") {
                    if (this.allData[0]) {
                        this.highlightedRows = this.allData[0].WORKITEMGUID;
                        localStorage.setItem('FEEEARNERDATA',this.allData[0].FEEEARNER);
                    }
                }

                this.invoiceData = new MatTableDataSource([]);
                this.invoiceData.data = this.getGroups(this.allData, this.groupByColumns);
                if (this.invoiceData.data[0]) {
                    this.groupHeaderClick(this.invoiceData.data[0]);
                    let shortingData: any = { active: this.sortactive, direction: this.sortDirection };
                    this.sortingCLM(shortingData);
                    let selectedWIPCheckbox = JSON.parse(localStorage.getItem("SelectedWIPCheckbox"));
                    this.selection.clear();
                    if (selectedWIPCheckbox) {
                        selectedWIPCheckbox.forEach((row) => {
                            this.allData.find(o => {
                                if (o.WORKITEMGUID === row.WORKITEMGUID && !o.INVOICEGUID) {
                                    o.TAGGED = 1;
                                    this.selection.select(o);
                                }
                            });
                        });
                    }
                }
                // this.masterToggle();
                this.totalDataOut.emit(this.selection.selected);
                this.behaviorService.refreshTaggedList.next(this.selection.selected);
                this.loader=false;
            }
        }, error => {
            this.loader=false;
        });
}

    ngOnDestroy(): void {
        this._unsubscribeAll$.next();
        this._unsubscribeAll$.complete();
        this.behaviorService.moveDataEntry$.next(null);
    }

    /**
     * This function is used to onDrag Start
     */
    

    // onDrop(event: CdkDragDrop<string[]>): void {
    //     const targetTypename = this.currentDraggedAddText;
    //     console.log('event',event);
    //     console.log('this.invoiceData.data',this.invoiceData.data);
    //     const targetIndex = event.currentIndex;
    //     if (!this.isDropAllowed(targetTypename, targetIndex)) {
    //         this.toastr.warning('Please rearrange the rows based on their Contact Type.');
    //         return;
    //     }
    //     moveItemInArray(this.invoiceData.data, event.previousIndex, event.currentIndex);
    //     let newPosition;
    //     const filteredArray = this.invoiceData.data.filter((item) => item.CLIENTNAME === targetTypename);
    //     console.log('filteredArray',filteredArray);
    //     filteredArray.map((cors,index) => {
    //         if(cors.WORKITEMGUID == this.highlightedRows) {
    //             console.log('heree',cors.WORKITEMGUID)
    //             console.log('here this.highlightedRows',this.highlightedRows)
    //             console.log('index',index);
    //             newPosition = index + 1;
    //         }
    //     })
    //     this._mainAPiServiceService.getSetData({ Action: 'Reorder', DATA: { WORKITEMGUID: this.highlightedRows, INVOICEORDER: newPosition } }, 'workitem').subscribe(response => {
    //         if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
    //             // this.isspiner = false;
    //             // if (this._data.action == 'duplicate') {
    //             //     this.loadData('', true);
    //             // } else {
    //                 // this.loadData('', '');
    //                 this.getWorkItemsData();
    //             // }
    //         } else if (response.MESSAGE == 'Not logged in') {
    //             // this.isspiner = false;
    //             // this.dialogRef.close(false);
    //         } else {
    //             // this.isspiner = false;
    //         }
    //     }, (error: any) => {
    //         // this.isspiner = false;
    //     });
    // }

    /**
     * This function is used to ondrop 
     */
    // onDrop(event: CdkDragDrop<string[]>): void {
    //     const clientName  = this.currentDraggedAddText;
    //     const clientNameText  = this.currentDraggedAddClient;
    //     const targetIndex = event.currentIndex;
    //     const isDropAllowed = this.isDropAllowed(clientName, targetIndex);
    //     if (!isDropAllowed) {
    //         this.toastr.warning('Please rearrange the rows within the same Item Type');
    //         return;
    //     }
    //     const defaultArray = [...this.invoiceData.data];
    //     moveItemInArray(this.invoiceData.data, event.previousIndex + 1, event.currentIndex + 1);
    //     let newPosition;
    //     const filteredArray = this.invoiceData.data.filter((item) => item.CLIENTNAME === clientNameText);
    //     filteredArray.map((cors, index) => {
    //         if (cors.WORKITEMGUID === this.highlightedRows) {
    //             newPosition = index + 1;
    //         }
    //     });
    //     this._mainAPiServiceService.getSetData(
    //         { Action: 'Reorder', DATA: { WORKITEMGUID: this.highlightedRows, NEWINVOICEORDER: newPosition } },
    //         'workitem'
    //     ).subscribe(
    //         response => {
    //             if (response.CODE === 200 && (response.STATUS === "OK" || response.STATUS === "success")) {
    //                 this.invoiceData.data = [...this.invoiceData.data];
    //             } else if (response.MESSAGE === 'Not logged in') {
    //             } else if (response.STATUS === 'error') {
    //                 this.invoiceData.data = [...defaultArray];
    //                 this.toastr.error(response.MESSAGE);
    //             } else {
    //             }
    //         },
    //         error => {
    //         }
    //     );
    // }


    onDragStart(ele): void {
        this.currentDraggedAddText = ele.ITEMTYPEDESC;
        this.currentDraggedAddClient = ele.CLIENTNAME;
        this.highlightedRows = ele.WORKITEMGUID;
    }

    onDrop(event: CdkDragDrop<string[]>): void {
        const clientName = this.currentDraggedAddText;
        const clientNameText = this.currentDraggedAddClient;
        const targetIndex = event.currentIndex;
        const validRows = this.invoiceData.data.filter(item => !item.level); //
        const isDropAllowed = this.isDropAllowed(clientName, targetIndex, validRows);
        // Check if drop is allowed within the same item type
        if (!isDropAllowed) {
            this.toastr.warning('Please rearrange the rows within the same Item Type');
            return;
        }
    
        const defaultArray = [...this.invoiceData.data];
        // Move the item in the filtered array (without group headers)
        moveItemInArray(validRows, event.previousIndex, event.currentIndex);
        // Re-map the valid rows back into the full array, keeping the group headers intact
        let updatedData = [];
        let currentIndex = 0;
        this.invoiceData.data.forEach(item => {
            if (item.level) {
                updatedData.push(item); // Push group header
            } else {
                updatedData.push(validRows[currentIndex]); // Push the reordered valid row
                currentIndex++;
            }
        });
        let newPosition;
        // const filteredArray = this.invoiceData.data.filter((item) => item.CLIENTNAME === clientNameText);
        const filteredArray = validRows; 
        filteredArray.map((cors, index) => {
            if (cors.WORKITEMGUID === this.highlightedRows) {
                newPosition = index + 1;
            }
        });
        // Call API to save new positions
        this._mainAPiServiceService.getSetData(
            { Action: 'Reorder', DATA: { WORKITEMGUID: this.highlightedRows, NEWINVOICEORDER: newPosition } },
            'workitem'
        ).subscribe(
            response => {
                if (response.CODE === 200 && (response.STATUS === "OK" || response.STATUS === "success")) {
                    this.invoiceData.data = [...updatedData]; // Update the data array after reordering
                } else if (response.MESSAGE === 'Not logged in') {
                    // Handle not logged-in state
                } else if (response.STATUS === 'error') {
                    this.invoiceData.data = [...defaultArray];
                    this.toastr.error(response.MESSAGE);
                }
            },
            error => {
                // Handle error
            }
        );
    }

    isDropAllowed(clientName: string, targetIndex: number, validRows: any[]): boolean {
        const targetItem = validRows[targetIndex];
        // const draggedItem = validRows.find(item => item.CLIENTNAME === clientName); // Find dragged item
        return targetItem.ITEMTYPEDESC === clientName; // Ensure the items belong to the same group
    }    
    
    /**
     * This function is used to isDropAllowed
     */
    // isDropAllowed(targetTypename: string, targetIndex: number): boolean {
    //     const targetItem = this.invoiceData.data[targetIndex + 1];
    //     console.log('targetItem',targetItem);
    //     return targetItem.ITEMTYPEDESC === targetTypename;
    // }

    /**
     * This function is used to on resize the end data value
     */
    onResizeEnd(event: ResizeEvent, columnName): void {
        if (event.edges.right) {
            const cssValue = event.rectangle.width + 'px';
            const columnElts = document.getElementsByClassName('mat-column-' + columnName);
            for (let i = 0; i < columnElts.length; i++) {
                const currentEl = columnElts[i] as HTMLDivElement;
                currentEl.style.width = cssValue;
            }
        }
    }
    /**
     * start grouping code
     */
    sortingCLM(sort: MatSort) {
        let data = this.allData;
        let sortSubData;
        const index = data.findIndex(x => x['level'] == 1);
        if (sort.active && sort.direction !== '') {
            if (index > -1) {
                data.splice(index, 1);
            }
            data = data.sort((a: any, b: any) => {
                let propertyA: number | Date | string = '';
                let propertyB: number | Date | string = '';
                let FildeValue = ['ITEMDATE'];
                if (sort.active) {
                    const isAsc = sort.direction === 'asc';
                    if (FildeValue.includes(sort.active)) {
                        let tempDate = a[sort.active].split("/");
                        let tempDateb = b[sort.active].split("/");
                        [propertyA, propertyB] = [new Date(tempDate[1] + '/' + tempDate[0] + '/' + tempDate[2]), new Date(tempDateb[1] + '/' + tempDateb[0] + '/' + tempDateb[2])];
                        const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
                        const valueB = isNaN(+propertyB) ? propertyB : +propertyB;
                        return (valueA < valueB ? -1 : 1) * (isAsc ? 1 : -1);
                    } else {
                        [propertyA, propertyB] = [a[sort.active], b[sort.active]];
                        const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
                        const valueB = isNaN(+propertyB) ? propertyB : +propertyB;
                        return (valueA < valueB ? -1 : 1) * (isAsc ? 1 : -1);
                    }
                } else {
                    return 0;
                }
            });

            sortSubData = [...data];
            sortSubData = sortSubData.slice().sort((a: any, b: any) => {
                const property = sort.active;
                const isAsc = sort.direction === 'asc';
                // let propertyA: number | Date | string = "";
                // let propertyB: number | Date | string = "";
                // // let FildeValue = this.dateColFilter;
                // [propertyA, propertyB] = [a['ITEMDATESORT'], b['ITEMDATESORT']];
                // const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
                // const valueB = isNaN(+propertyB) ? propertyB : +propertyB;
                // return (valueA < valueB ? -1 : 1) * (this.sort.direction === 'asc' ? 1 : -1);
                if (a[property] === b[property] && sort.active === property) {
                    return (a['ITEMDATESORT'] < b['ITEMDATESORT'] ? -1 : 1) * (isAsc ? 1 : -1) // ascending order for sub-sorting
                }
                return 0;
            });
        }
        this.invoiceData.data = this.addGroupsNew(this._allGroup, sortSubData, this.groupByColumns, this.expandedCar);
        this.invoiceData.paginator = this.paginator;
    }

    private compare(a, b, isAsc) {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }

    /**
     * This function is used to get the group
     */
    isGroup(index, item): boolean {
        return item.level;
    }

    /**
     * This function is used to get the groups data value
     */
    getGroups(data: any[], groupByColumns: string[]): any[] {
        return this.getGroupList(data, 0, groupByColumns);
    }

    /**
     * This function is used to get the group list
     */
    getGroupList(data: any[], level: number = 0, groupByColumns: string[]): any[] {
        if (level >= groupByColumns.length) {
            return data;
        }
        let groups = this.uniqueBy(data.map(row => {
            const result = new Group();
            result.level = level + 1;
            for (let i = 0; i <= level; i++) {
                result[groupByColumns[i]] = row[groupByColumns[i]];
            }
            return result;
        }), JSON.stringify);
        const currentColumn = groupByColumns[level];
        let subGroups = [];
        groups.forEach(group => {
            const rowsInGroup = data.filter(row => group[currentColumn] === row[currentColumn]);
            group.totalCounts = rowsInGroup.length;
            this.expandedSubCar = [];
        });
        this._allGroup = groups;
        return groups;
    }

    /**
     * This function is used to Add the new groups
     */
    addGroupsNew(allGroup: any[], data: any[], groupByColumns: string[], dataRow: any): any[] {
        const rootGroup = new Group();
        rootGroup.expanded = true;
        return this.getSublevelNew(allGroup, data, 0, groupByColumns, rootGroup, dataRow);
    }

    /**
     * This function is used to get the sublevel new data
     */
    getSublevelNew(allGroup: any[], data: any[], level: number, groupByColumns: string[], parent: Group, dataRow: any): any[] {
        if (level >= groupByColumns.length) {
            return data;
        }
        const currentColumn = groupByColumns[level];
        let subGroups = [];
        allGroup.forEach(group => {
            const rowsInGroup = data.filter(row => group[currentColumn] === row[currentColumn]);
            group.totalCounts = rowsInGroup.length;
            // if (group.ITEMTYPEDESC == dataRow.ITEMTYPEDESC.toString()) {
            //   group.expanded = dataRow.expanded;
            const subGroup = this.getSublevelNew(allGroup, rowsInGroup, level + 1, groupByColumns, group, group.ITEMTYPEDESC.toString());
            this.expandedSubCar = subGroup;
            subGroup.unshift(group);
            subGroups = subGroups.concat(subGroup);
            // } else {
            //   subGroups = subGroups.concat(group);
            //
            // }
        });
        const sortedSubGroups = subGroups.sort((a, b) => a.INVOICEORDER - b.INVOICEORDER);
        // return subGroups;
        return sortedSubGroups;
    }

    /**
     * This function is used to unique By
     * @param a -data value
     * @param key -key data
     * @returns -data value
     */
    uniqueBy(a, key) {
        const seen = {};
        return a.filter((item) => {
            const k = key(item);
            return seen.hasOwnProperty(k) ? false : (seen[k] = true);
        });
    }

    
    /**
     * This function is used to group header click
     * @param row -row data value
     */
    groupHeaderClick(row):void {
        if (row.expanded) {
            row.expanded = false;
            this.invoiceData.data = this.getGroups(this.allData, this.groupByColumns);
        } else {
            row.expanded = true;
            this.expandedCar = row;
            this.invoiceData.data = this.addGroupsNew(this._allGroup, this.allData, this.groupByColumns, row);
        }
    }

    // groupInvoiceData(data: any[]): any[] {
    //     const groups = {};
    //     data.forEach((item) => {
    //         const groupName = item.ITEMTYPEDESC;
    //         if (!groups[groupName]) {
    //             groups[groupName] = {
    //                 ITEMTYPEDESC: groupName,
    //                 items: [],
    //             };
    //         }
    //         groups[groupName].items.push(item);
    //     });
    //     return Object.values(groups);
    // }

    // getConnectedLists(currentGroup: string): string[] {
    //     // Connect only to lists with the same ITEMTYPEDESC
    //     return this.groupedInvoiceData
    //         .filter((group) => group.ITEMTYPEDESC === currentGroup)
    //         .map((group) => group.items);
    // }

    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected() {
        if (this.invoiceData.data) {
            const numSelected = this.selection.selected.length;
            const numRows = this.invoiceData.data.length;
            return numSelected === numRows;
        }
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
        if (this.invoiceData.data)
            this.isAllSelected() ? this.selection.clear() : this.invoiceData.data.forEach(row => this.selection.select(row));

        // if(this.selection.selected[0]) {
        //   if(this.selection.selected[0]["level"]) {
        //     this.selection.selected.splice(0,1)
        //   }
        // }
        this.behaviorService.refreshTaggedList.next(this.selection.selected);
    }

    /**
     * This function is used to get the return data value.
     */
    retuenData():void {
        this.totalDataOut.emit(this.selection.selected);
        this.behaviorService.refreshTaggedList.next(this.selection.selected);
    }
    /** The label for the checkbox on the passed row */
    checkboxLabel(row?: any): string {
        if (!row) {
            return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
        }
        return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
    }

    /**
     * This function is used to sort the data
     * @param val -val data
     */
    sortData(val):void {
        this.SortingbehaviorService.CIScreenSorting(val);
        //this.setDefaultWidth(this.displayedColumns, 0);
    }

    /**
     * This function is used to get the row data value
     * @param row -row data value
     */
    getrowdata(row):void{
        this.behaviorService.setworkInProgressData(row);
        this.behaviorService.SpendMoneyData(row);
       this.doubleClick(row);
    }


    /**
     * This function is used to click the double
     * @param data -data value
     */
    doubleClick(data):void {
        if (data && data?.ITEMTYPEDESC == 'Disbursement') {
            this.spendmoneypopup('edit');

        } else {
            this.globalFunctionService.editTimeEntries(data?.ITEMTYPEDESC);
        }
    }

    /**
     * This function is used to open the spendmoney popup
     * @param actionType -action type data value
     * @param val -data value
     * @param flag -flag value
     */
    spendmoneypopup(actionType: any, val: any = '', flag?: any) {
        this.SetOldistrackid('open');
        const dialogRef = this.dialog.open(SpendMoneyAddComponent, {
            disableClose: true,
            panelClass: 'SpendMoney-dialog',
            data: { action: actionType, FromWhere: val, flag: flag }
        });
        dialogRef.afterClosed().subscribe(result => {
            this.SetOldistrackid('close');
            if (result) {
                $("#refreshSpendMoneyTab").click();
                $('#refreshRecouncilItem').click();
                $('#refreshWorkInprogress').click();
                $('#refreshGeneral').click();
                $("#refreshreciptsDAta").click();
              //$("#refreshtabledetails").click();
            }
        });
    }

    /**
     * This function is used to set the old district id
     * @param type -district type
     */
    SetOldistrackid(type):void {
        if (type === 'open') {
            this.oldIsTrackId = localStorage.getItem('istrackid');
        } else {
            localStorage.setItem('istrackid', this.oldIsTrackId);
        }
    }

    /**
     * This function is used to get the updated value
     * @param event -event data values
     * @param datavalue -data values
     */
    getdatavalue(event,datavalue:any){ 
    this.WorkitemGuid=datavalue.WORKITEMGUID;
    // This is for shows the two bottons(save/cancel) 
    //this.isShownsavecancel=true;
    // This is for getting the Updated value
    datavalue.isShownsavecancel=true;

    this.UpdatedText=event;
    let row = JSON.parse(JSON.stringify(this.initialRow));
    row.forEach(element => {
        if(element.WORKITEMGUID == datavalue.WORKITEMGUID){
            if(datavalue.ADDITIONALTEXT == element.ADDITIONALTEXT){
               // this.isShownsavecancel=false;
                datavalue.isShownsavecancel=false;
            }            
        }
    });
    }

    /**
     * This function is used to Remove the new Changes
     * @param valu-Updated value 
     */
    Removenewchanges(valu):void{
    // This is for shows the two bottons(save/cancel)
    valu.isShownsavecancel=false;
    //this.isShownsavecancel=false;
    valu.ADDITIONALTEXT=valu.ADDITIONALTEXT;
    let row = JSON.parse(JSON.stringify(this.initialRow));
    row.forEach(element => {
        if(element.WORKITEMGUID == valu.WORKITEMGUID){
            valu.ADDITIONALTEXT= element.ADDITIONALTEXT
        }
    }); 
}



/**
 * This function is used to Save New Changes.
 */
SaveNewChangesData(row:any):void{
    this.loader = true;
   this.SaveClickTimeEntry(row);
}

/**
     * This function is used to Save the Click Time Entry
     */
SaveClickTimeEntry(row) {
    let PostData: any = {
        "ADDITIONALTEXT": row.ADDITIONALTEXT,
        "COMMENT": row.COMMENT,
        "FEEEARNER": row.FEEEARNER,
        "ITEMTYPE": row.ITEMTYPE,
        "ITEMDATE": row.ITEMDATE,
        "ITEMTIME": row.ITEMTIME,
        "MATTERGUID": row.MATTERGUID,
        "PRICE": Number(row.PRICE),
        "PRICEINCGST": row.PRICEINCGST,
        "QUANTITY": row.QUANTITY,
        "RATEID": (row.RATEID),
        "MATTERDELIVERABLEGUID": row.MATTERDELIVERABLEGUID,
        "WorkItemGuid":row.WORKITEMGUID
    }
    if (row.ITEMTYPE == "activity" || row.ITEMTYPE == "sundry") {
        PostData.FEETYPE = row.QUANTITYTYPE;
        PostData.QUANTITYTYPE = '';
    } else {
        PostData.QUANTITYTYPE = row.QUANTITYTYPE;
    }

    let FormAction = 'update'
    if (this.currentUser.PRODUCTTYPE == 'Barrister') {
        delete PostData.FEEEARNER
    }

    this.RequestGuid = this._mainAPiServiceService.generateUniqSerial();
    let PostTimeEntryData: any = { FormAction: FormAction, VALIDATEONLY: true, RequestGuid: this.RequestGuid, Data: PostData };
    this.Timersservice.SetWorkItems(PostTimeEntryData).subscribe(res => {
        if (res.CODE == 200 && res.STATUS == "success") {
            this.checkValidation(res.DATA.VALIDATIONS, PostTimeEntryData,row);
        } else if (res.CODE == 451 && res.STATUS == 'warning') {
            this.checkValidation(res.DATA.VALIDATIONS, PostTimeEntryData,row);
            this.loader = false;
        } else if (res.CODE == 450 && res.STATUS == 'error') {
            this.checkValidation(res.DATA.VALIDATIONS, PostTimeEntryData,row);
            this.loader = false;
        }else if(res.CODE == 406){
            this.loader = false;
        }
         else if (res.MESSAGE == 'Not logged in') {
           // this.dialogRef.close(false);
        }
    }, err => {
        this.loader = false;
        this.toastr.error(err);
    });
}


  /**
     * This function is used to check the validation 
     */
  async checkValidation(bodyData: any, PostTimeEntryData: any,row) {
    await this.globalFunctionService.checkValidation(bodyData, PostTimeEntryData)
        .subscribe(data => {
            if (data) {
               // this.errorWarningData = data.errorWarningData;
                //this.errorWarningDataArray = data.errorWarningData;
                if (data.callback) {
                    this.loader = true
                    this.saveTimeEntry(PostTimeEntryData,row);
                } else {
                   // this.loader = false;
                }
            };
            //this.loader = false;
        });
}

 /**
     * This function is used to Save the Time entry
     */
 saveTimeEntry(PostTimeEntryData: any,row) {
    PostTimeEntryData.VALIDATEONLY = false;
    this.Timersservice.SetWorkItems(PostTimeEntryData).subscribe(res => {
        if (res.CODE == 200 && res.STATUS == "success") {
           // $("#refreshtabledetails").click();
            //this.isShownsavecancel=false;
            this.loader = false;
            row.isShownsavecancel=false;
            this.toastr.success('Invoice Text updated Successfully');
            // setTimeout(() => {
            //     $("#refreshreciptsDAta").click();
            // }, 200);
        } else if (res.CODE == 451 && res.STATUS == 'warning') {
            this.toastr.warning(res.MESSAGE);
            this.loader = false;
        } else if (res.CODE == 450 && res.STATUS == 'error') {
            this.toastr.warning(res.MESSAGE);
            this.loader = false;
        } else if (res.MESSAGE == 'Not logged in') {
           // this.dialogRef.close(false);
            this.loader = false;
        }
    }, err => {
        //this.loader = false;
        this.toastr.error(err);
    });
}

 /**
     * This function is used to open the Write off Dialog data
     * @param type -type of the data
     */
 OpenWriteOffDialog(type,row) {
        this.behaviorService.setworkInProgressData(row);
        //this.behaviorService.SpendMoneyData(row);
    this.toolbarServiceService.SetOldistrackid("open");
    const dialogRef = this._matDialog.open(WriteOffTimeEntryComponent, {
        width: "100%",
        disableClose: true,
        data: { type: type },
    });
    dialogRef.afterClosed().subscribe((result) => {
        this.toolbarServiceService.SetOldistrackid("close");
        if (result) {
            localStorage.setItem('Spellcheck','false');
            setTimeout(() => {
            $("#refreshTimeEntryTab").click();
            $("#refresheWorkEtimateTab").click();
          //$("#refreshtabledetails").click();
            $("#refreshreciptsDAta").click();
            },200);
        }
    });
}

/**
 * This function is used to add the Duplicate Time Entry.
 */
addDuplicateTimeEntry(row):void{
    this.behaviorService.setworkInProgressData(row);
    this.toolbarServiceService.addNewTimeEntry('Duplicate','','','')
}

/**
 * This function is used to move the Entry
 */
moveEntry(row){
    this.behaviorService.moveDataEntry$.next(row);
    const dialogRef = this._matDialog.open(MoveEntryComponent, {
        width: "100%",
        disableClose: true,
        data: "",
    });
    dialogRef.afterClosed().subscribe((result) => {
        this.toolbarServiceService.SetOldistrackid("close");
        if (result) {
            localStorage.setItem('Spellcheck','false');
        // $("#refreshtabledetails").click();
           setTimeout(() => {
           $('#refreshWorkInprogress').click();
           $('#refreshTimeEntryTab').click();
           $("#refreshreciptsDAta").click();
           }, 200);
        }
    });
}

/**
     * This function is used to split the time entry data value
     * @param type -type of the data value
     */
splittimeentry(type,row) {
    this.behaviorService.moveDataEntry$.next(row);
    let popupData: any = {
        action: type,
        WORKITEMGUID: row,
    };
    //  this.toolbarServiceService.SetOldistrackid('open');
    const dialogRef = this.dialog.open(SplitTimeEntryComponent, {
        width: "100%",
        disableClose: true,
        data: popupData,
    });
    dialogRef.afterClosed().subscribe((result) => {
        this.toolbarServiceService.SetOldistrackid("close");
        if (result) {
            localStorage.setItem('Spellcheck','false');
            $("#refreshTimeEntryTab").click();
            $("#refreshWorkInprogress").click();
          //$("#refreshtabledetails").click();
        }
    });
}

/**
 * This function is used to delete the Time Entry.
 */
deleteTimeEntry(row):void{
    localStorage.setItem('Spellcheck','false');
    this.behaviorService.setworkInProgressData(row);
    setTimeout(() => {
        $("#deleteTimeEntry").click();
    },2000);
}

 // Method to focus on each textarea sequentially
 triggerSpellCheck() {
    const textAreasArray = this.textAreas.toArray();

    // Sequentially focus and blur each textarea to trigger spellcheck
    textAreasArray.forEach((textArea, index) => {
      setTimeout(() => {
        if(textArea.nativeElement != undefined){
            this.renderer.selectRootElement(textArea.nativeElement).focus();  // Use Renderer2 to focus
            this.renderer.selectRootElement(textArea.nativeElement).blur();   // Use Renderer2 to blur    
        }
            }, index * 150); // Delay each action slightly
    });

    // Return focus to the first textarea at the end
    setTimeout(() => {
        if(textAreasArray[0]?.nativeElement != undefined){
      this.renderer.selectRootElement(textAreasArray[0].nativeElement).focus();
        }
    }, textAreasArray.length * 150);
  }

}
