<div class="example-loading-shade" *ngIf="isLoadingResults">
    <mat-spinner [style.zoom]="0.5" *ngIf="isLoadingResults"></mat-spinner>
</div>
<div cdkDrag class="popup_main_div add_report_main add_top_clients">
    <h2 cdkDragHandle mat-dialog-title class="popup_title_div"> {{title}}
        <button class="btn" mat-icon-button (click)="ondialogcloseClick()" aria-label="Close dialog">
            <mat-icon>close</mat-icon>
        </button>
    </h2>
    <div mat-dialog-content class="mat-typography" style="overflow: unset;">
        <div class="one" fxLayout="column" fxLayoutAlign="start" fxLayout.gt-md="row" style="width: 100%;">
            <div class="popup_body document_generated_main_div" *ngIf="!reportSubmitted && isPDFShow" style="width: 100%;">
                <div style="position: relative;z-index: 99999;width: 100%;">
                    <h3>Your report request has been submitted. Keep a track of it in the Generated reports panel on the right. </h3><br>
                    <!-- <mat-progress-bar style="height: 2em;" mode="determinate" [value]="progress"></mat-progress-bar> -->
                    <!-- <span style="position: absolute; top: 2.2em;" [ngStyle]="{'left': progress + '%'}"></span> -->
                </div>
            </div>
            <!-- <div class="popup_body document_generated_main_div" *ngIf="isLoadingResultsU" style="width: 100%;">
                <div fxLayout="row" class="document_generated_main_first" fxLayoutAlign="start center"
                    fxFlex="1 0 auto">
                    <h3>{{btnText}}</h3>
                </div>
            </div> -->
            <!-- Report for single -->
            <!-- <div *ngIf="this.data.REPORTID !== 'TrustAllTrustAccountStatements'  && this.data.REPORTID !== 'TrustMatterLedgers'" >
                <div *ngFor="let item1 of singledataarray; let i = index" >
                <div class="popup_body document_generated_main_div" *ngIf="isPDFGenerated" style="width: 100%;">
                    <div fxLayout="row" class="document_generated_main_first" fxLayoutAlign="start center"
                        fxFlex="1 0 auto">
                        <div class="middle" *ngIf="item1.SHORTNAME">The {{item1.SHORTNAME}} report has been produced.</div>
                        <div class="middle" *ngIf="!item1.SHORTNAME">The {{title}} report has been produced.</div>
                    </div>
                    <div fxLayout="row" class="document_generated_main_last" fxLayoutAlign="start center"
                        fxFlex="1 0 auto" class="button">
                        <a href="{{this.base_url}}{{PDF_Generation}}"  target="_blank">
                            <button  mat-raised-button  color="accent" class="mr-4">Click here to view PDF file</button>
                        </a>
                        <button mat-raised-button  color="accent" (click)="ToEmailthedocument()"
                            *ngIf="reportgenerateData?.DATA?.DOCUMENTGUID && reportgenerateData?.DATA?.MATTERGUID && accountClass == 'Trust Matter Ledger'">
                            Click here to email it</button>

                            <a *ngIf="this.CSVFILENAME !=''" href="{{this.base_url}}{{this.CSVFILENAME}}" target="_blank">
                                <button   mat-raised-button color="accent"  > Click here to view CSV file</button>
                            </a>
                        
                            <a href="{{this.base_url}}{{PDF_Generation}}" target="_blank">
                                <button  *ngIf="singledataarray==''" mat-raised-button  color="accent" class="mr-4">Click here to view
                                    it</button>
                            </a>
                            <button  mat-raised-button  color="accent" (click)="ToEmailthedocument()"
                                *ngIf=" singledataarray=='' &&  reportgenerateData?.DATA?.DOCUMENTGUID && reportgenerateData?.DATA?.MATTERGUID && accountClass == 'Trust Matter Ledger'">
                                Click here to email it</button>
                    </div>
                </div>
                <ng-container *ngIf="showwarningdiv=='true'" style="margin-top: 4px">
                    <div style=" margin-top: 20px;font-size: 15px;font-weight: 900;">
                        {{item1.MESSAGE}}
                    </div>
                    <div *ngIf ="matteridarray.length != 0">
                    <div  style="font-weight:bold;margin-top: 2px" *ngFor="let data of matteridarray; let i = index">
                        Matter Number is- {{data}}
                    </div>
                </div>
                </ng-container>
            </div>
           
        </div> -->

            <!-- <div *ngIf="this.data.REPORTID == 'TrustAllTrustAccountStatements'  || this.data.REPORTID == 'TrustMatterLedgers'" [ngStyle]="isPDFGenerated ? { 'max-height':'210px',overflow: 'scroll'} : {}">
                <div *ngFor="let item of storeAllMultipleSelectedData; let i = index"  >
              
                    <div class="popup_body document_generated_main_div" *ngIf="isPDFGenerated &&  !item.TEMPERROR" style="width: 100%;" >
                        <div fxLayout="row" class="document_generated_main_first mt-4" fxLayoutAlign="start center" 
                            fxFlex="1 0 auto">
                            <div fxFlex="35">The <b>{{item.SHORTNAME}}</b> report has been produced.</div>
                            <button class ="buttonview" fxFlex="23" mat-raised-button color="accent" (click)="helpWindow(i,item)"> Click here to view PDF file</button>&nbsp;
    
                            <button fxFlex="19" mat-raised-button color="accent" (click)="ToEmailthedocument(i,item)"> Click
                                here to email it</button>&nbsp;
                           <ng-container fxFlex="23" *ngFor="let data of CSVFILENAMEARRAY">
                           <a *ngIf="data.MATTERGUID==item.MATTERGUID"   href="{{this.base_url}}{{data.CSVFILENAME}}" target="_blank">
                           <button   mat-raised-button color="accent"> Click here to view CSV file</button>
                           </a>
                           </ng-container>
                        </div>
                    </div>
                
                <ng-container *ngIf="showwarningdiv=='true' && item.TEMPERROR && this.data.REPORTID == 'TrustAllTrustAccountStatements'  || this.data.REPORTID == 'TrustMatterLedgers'">
                    <div style=" margin-top: 20px;font-size: 15px;font-weight: 900;">
                      {{item.MESSAGE}}
                    </div>
                    </ng-container>
        
                    <div [ngStyle]="isPDFGenerated ? { 'max-height': '75px',overflow: 'scroll'} : {}" *ngIf="showwarningdiv=='true' &&  item.TEMPERROR && this.data.REPORTID == 'TrustAllTrustAccountStatements' || this.data.REPORTID == 'TrustMatterLedgers'">
                        <ng-container *ngIf="showwarningdiv=='true'" style="margin-top: 4px"  >
                                Matter Number is 2- {{item.SHORTNAME}}
                        </ng-container>
                    </div>
                </div>
            </div> -->
            <!-- <ng-container *ngIf="showwarningdiv=='true' && this.data.REPORTID == 'TrustAllTrustAccountStatements'">
            <div style=" margin-top: 20px;font-size: 15px;font-weight: 900;">
             The below matters dont have an address filled in for the client. Please correct this and then
              come back and run the statements for these matters.
            </div>
            </ng-container>

            <div [ngStyle]="isPDFGenerated ? { 'max-height': '75px',overflow: 'scroll'} : {}" *ngIf="showwarningdiv=='true' && this.data.REPORTID == 'TrustAllTrustAccountStatements'">
                <ng-container *ngIf="showwarningdiv=='true'" style="margin-top: 4px"  >
                   
                   
                    <div style="font-weight:bold;margin-top: 2px" *ngFor="let data of matteridarray; let i = index"  >
                        Matter Number is- {{data}}
                    </div>
                 
                </ng-container>
            </div> -->


            <form fxLayout="column" *ngIf="!isPDFGenerated && !isLoadingResultsU && showwarningdiv=='false'" fxFlex="1 0 auto" name="form"
                [formGroup]="ReportForm" id="ReportForm">
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" *ngIf="responseData.DATERANGE"  >
                <mat-form-field class="example custom-select pr-4" fxFlex="100" appearance="outline"  *ngIf="this.MatterFeildShow" [ngClass]="{'has-error':mattername.length == 0}">
                    <mat-label>Matters</mat-label>
                 <mat-chip-list #chipList aria-label="Matter selection">
                         <mat-chip *ngFor="let matterdata of mattername" (removed)="remove(matterdata)" class="mat-chips-selected">
                             <span *ngIf="mattername.lenght != 0"><span>{{matterdata| slice:0:40}}<ng-container *ngIf="matterdata.length>50">...</ng-container></span></span>
                         <button  matChipRemove class="canclebutton">
                             <mat-icon style="font-size: 18px !important;">cancel</mat-icon>
                         </button>
                     </mat-chip>
                     <input matInput
                         (keydown.Tab)="datashow($event)" 
                         formControlName="SHORTNAME" #MatterInput1 [matAutocomplete]="autoMatter"
                         [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                         (matChipInputTokenEnd)="add($event)" (keyup)="onKeyUp($event)" required [ngClass]="(!isMulti || isMulti== 'no' ||isMulti== 'No' )  && mattername.length > 0?'hideInput':'showInput'" >
                 </mat-chip-list>
                 <mat-autocomplete #autoMatter="matAutocomplete" (optionSelected)="selectedValue($event)" >
                     <mat-option class="mat-option_1" *ngFor="let data of matterlist" (click) ="selectedmatterguid(data)" value="{{data.MATTERGUID}}">
                         <!-- {{data.SHORTNAME}}--{{data.MATTER}} -->
                         {{data.SELECTEDDESCRIPTION}}
                     </mat-option>
                 </mat-autocomplete> 
                 <mat-icon class="matternews" matSuffix (click)="SelectMatter()" fxFlex="5">
                    <img class="icon_set" src="assets/icons/web_app/icon_matter_d.ico">
                </mat-icon>
                </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" *ngIf="responseData.DATERANGE">
                    <mat-form-field class="example custom-select pr-4" fxFlex="100" appearance="outline">
                        <mat-label>Date Range List</mat-label>
                        <mat-select formControlName="DATERANGE" #values name="DATERANGE"
                            (selectionChange)="hideshowdatepicker($event.value)" panelClass="height_custom_scroll">
                            <mat-option *ngFor="let Dropdowndatename of responseData.DATERANGE"
                                [value]="Dropdowndatename.TEXT">{{Dropdowndatename.TEXT}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" *ngIf="responseData.DATERANGE">
                    <div class="one" fxLayout="column">
                        <div *ngIf="title != 'Aged Debtors'">
                            <!-- <mat-form-field appearance="outline" fxFlex="50" class="pr-4">
                                <mat-label>From Date</mat-label>
                                <input matInput placeholder="Choose a From date" formControlName="ReportDateStart"
                                    name="ReportDateStart" [satDatepicker]="fromDate">
                                <sat-datepicker-toggle matSuffix [for]="fromDate"></sat-datepicker-toggle>
                                <sat-datepicker #fromDate [closeAfterSelection]="true"></sat-datepicker>
                            </mat-form-field>
                            <mat-form-field appearance="outline" fxFlex="50" class="pr-4">
                                <mat-label>To Date </mat-label>
                                <input matInput placeholder="Choose a To date" formControlName="DateEnd" name="DateEnd"
                                    [satDatepicker]="toDate">
                                <sat-datepicker-toggle matSuffix [for]="toDate"></sat-datepicker-toggle>
                                <sat-datepicker #toDate [closeAfterSelection]="true"></sat-datepicker>
                            </mat-form-field> -->
                            <mat-form-field appearance="outline" fxFlex="50" class="pr-4">
                                <mat-label>From Date</mat-label>
                                <input matInput placeholder="Choose a From date" formControlName="ReportDateStart"
                                    name="ReportDateStart" [matDatepicker]="fromDate">
                                <mat-datepicker-toggle matSuffix [for]="fromDate"></mat-datepicker-toggle>
                                <mat-datepicker #fromDate></mat-datepicker>
                            </mat-form-field>
                            <mat-form-field appearance="outline" fxFlex="50" class="pr-4">
                                <mat-label>To Date </mat-label>
                                <input matInput placeholder="Choose a To date" formControlName="DateEnd" name="DateEnd"
                                    [matDatepicker]="toDate">
                                <mat-datepicker-toggle matSuffix [for]="toDate"></mat-datepicker-toggle>
                                <mat-datepicker #toDate></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div *ngIf="title == 'Aged Debtors'">
                            <mat-form-field appearance="outline" class="example pr-4" fxFlex="100">
                                <mat-label>Date To</mat-label>
                                <input matInput name="DateEnd" formControlName="DateEnd" [matDatepicker]="dateto"
                                    placeholder="Date To">
                                <mat-datepicker-toggle matSuffix [for]="dateto"></mat-datepicker-toggle>
                                <mat-datepicker #dateto></mat-datepicker>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto"
                    *ngIf="responseData.FEEEARNERS && responseData.SHOWFEEEARNERS">
                    <mat-form-field class="example pr-4" fxFlex="100" appearance="outline">
                        <mat-label>{{responseData.FEEEARNERPROMPT}}</mat-label>
                        <mat-select formControlName="FEEEARNERNAME" name="FEEEARNERNAME"
                            [(value)]="responseData.FEEEARNERS[0].FEEEARNERGUID"
                            [(ngModel)]="responseData.FEEEARNERS[0].FEEEARNERGUID">
                            <mat-option *ngFor="let SelectOwnername of responseData.FEEEARNERS"
                                [value]="SelectOwnername.FEEEARNERGUID">{{SelectOwnername.FEEEARNERNAME}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto"
                    *ngIf="responseData.SHOWMULTIFEEEARNERS">
                    <mat-form-field class="example pr-4" fxFlex="100" appearance="outline">
                        <mat-label>Multi {{responseData.FEEEARNERPROMPT}}</mat-label>
                        <mat-select multiple formControlName="MULTIFEEEARNERLIST" [(ngModel)]="selected"
                            name="MULTIFEEEARNERLIST" (selectionChange)="multiFeeEarnerChange($event.value)">
                            <mat-option *ngFor="let SelectOwnername of responseData.FEEEARNERS"
                                [value]="SelectOwnername.FEEEARNERGUID">{{SelectOwnername.FEEEARNERNAME}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" *ngIf="responseData.SHOWFEEEARNERS2">
                    <mat-form-field class="example pr-4" fxFlex="100" appearance="outline">
                        <mat-label>{{responseData.FEEEARNERPROMPT2}}</mat-label>
                        <mat-select (selectionChange)="FE2Click($event.value,selectedvalue)" #selectedvalue formControlName="FEEEARNERNAME2" 
                            name="FEEEARNERNAME2" [(value)]="responseData.FEEEARNERS2[0].FEEEARNERGUID"
                            [(ngModel)]="responseData.FEEEARNERS2[0].FEEEARNERGUID">
                            <mat-option *ngFor="let SelectOwnername of responseData.FEEEARNERS2"
                                [value]="SelectOwnername.FEEEARNERGUID">{{SelectOwnername.FEEEARNERNAME}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" *ngIf="responseData.LIST1">
                    <mat-form-field class="example pr-4" fxFlex="100" appearance="outline">
                        <mat-label>{{responseData.LISTPROMPT1}}</mat-label>
                        <mat-select formControlName="LIST1TEXT" name="LIST1TEXT" [(value)]="responseData.LIST1[0].VALUE"
                            [(ngModel)]="responseData.LIST1[0].VALUE">
                            <mat-option *ngFor="let Selectsortordername of responseData.LIST1"
                                [value]="Selectsortordername.VALUE">{{Selectsortordername.TEXT}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" *ngIf="responseData.LIST2">
                    <mat-form-field class="example pr-4" fxFlex="100" appearance="outline">
                        <mat-label>{{responseData.LISTPROMPT2}}</mat-label>
                        <mat-select formControlName="LIST2TEXT" name="LIST2TEXT"
                            [(value)]="responseData.LIST2[indexLIST2].VALUE"
                            [(ngModel)]="responseData.LIST2[indexLIST2].VALUE">
                            <mat-option *ngFor="let List2name of responseData.LIST2" [value]="List2name.VALUE">
                                {{List2name.TEXT}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div
                    *ngIf="responseData.OPTIONTEXT1 || responseData.OPTIONTEXT2 || responseData.OPTIONTEXT3 || responseData.OPTIONTEXT4 || responseData.OPTIONTEXT5 || responseData.OPTIONTEXT6 || responseData.OPTIONTEXT7 || responseData.OPTIONTEXT8 || responseData.OPTIONTEXT9 || responseData.OPTIONTEXT10">

                    <div fxLayout="row wrap" fxLayoutAlign="start center" fxFlex="1 0 auto" class="label">
                        <div fxFlex="50" *ngIf="responseData.OPTIONTEXT1">
                            <mat-checkbox class="example-margin" formControlName="OPTIONTEXT1">
                                {{responseData.OPTIONTEXT1}}</mat-checkbox>
                        </div>
                        <div fxFlex="50" *ngIf="responseData.OPTIONTEXT2">
                            <mat-checkbox class="example-margin" formControlName="OPTIONTEXT2">
                                {{responseData.OPTIONTEXT2}}</mat-checkbox>
                        </div>
                        <div fxFlex="50" *ngIf="responseData.OPTIONTEXT3">
                            <mat-checkbox class="example-margin" formControlName="OPTIONTEXT3">
                                {{responseData.OPTIONTEXT3}} </mat-checkbox>
                        </div>
                        <div fxFlex="50" *ngIf="responseData.OPTIONTEXT4">
                            <mat-checkbox class="example-margin" formControlName="OPTIONTEXT4">
                                {{responseData.OPTIONTEXT4}} </mat-checkbox>
                        </div>
                        <div fxFlex="50" *ngIf="responseData.OPTIONTEXT5">
                            <mat-checkbox class="example-margin" formControlName="OPTIONTEXT5">
                                {{responseData.OPTIONTEXT5}} </mat-checkbox>
                        </div>
                        <div fxFlex="50" *ngIf="responseData.OPTIONTEXT6">
                            <mat-checkbox class="example-margin" formControlName="OPTIONTEXT6">
                                {{responseData.OPTIONTEXT6}} </mat-checkbox>
                        </div>
                        <div fxFlex="50" *ngIf="responseData.OPTIONTEXT7">
                            <mat-checkbox class="example-margin" formControlName="OPTIONTEXT7">
                                {{responseData.OPTIONTEXT7}} </mat-checkbox>
                        </div>
                        <div fxFlex="50" *ngIf="responseData.OPTIONTEXT8">
                            <mat-checkbox class="example-margin" formControlName="OPTIONTEXT8">
                                {{responseData.OPTIONTEXT8}} </mat-checkbox>
                        </div>
                        <div *ngIf="responseData.OPTIONTEXT9">
                            <mat-checkbox class="example-margin" formControlName="OPTIONTEXT9">
                                {{responseData.OPTIONTEXT9}} </mat-checkbox>
                        </div>
                        <div fxFlex="50" *ngIf="responseData.OPTIONTEXT10">
                            <mat-checkbox class="example-margin" formControlName="OPTIONTEXT10">
                                {{responseData.OPTIONTEXT10}} </mat-checkbox>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <mat-dialog-actions align="end" class="popup_ftr">
        <button mat-raised-button color="accent" class="mat-accent" (click)="ReportSubmit()"
            *ngIf="responseData && !isPDFGenerated && showwarningdiv=='false'" [disabled]="this.MatterFeildShow == 1 && mattername.length == 0">OK</button>
        <button mat-raised-button color="accent" class="mat-accent" (click)="CompleteSubmit()"
            *ngIf="!reportSubmitted && isPDFGenerated">OK</button>
        <button mat-raised-button color="primary" [mat-dialog-close]="true" cdkFocusInitial *ngIf="responseData && !isPDFGenerated && showwarningdiv=='false'">Cancel</button>
    </mat-dialog-actions>
</div>