import { Component, OnInit, Inject, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import { fuseAnimations } from 'src/@fuse/animations';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatterDialogComponent } from '../../time-entries/matter-dialog/matter-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { FuseConfirmDialogComponent } from 'src/@fuse/components/confirm-dialog/confirm-dialog.component';
import { DatePipe } from '@angular/common';
import { UserSelectPopupComponent } from '../../matters/user-select-popup/user-select-popup.component';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Router } from '@angular/router';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { GlobalFunctionsService } from '@_services/global-functions.service';
import { BehaviorService } from '@_services/Behavior.service';
import { debounceTime, distinctUntilChanged, Subject, Subscription, switchMap, takeUntil, tap } from 'rxjs';
import { CurrentUser } from 'src/app/_models/CurrentUser';
@Component({
    selector: 'app-task-dialoge',
    templateUrl: './task-dialoge.component.html',
    styleUrls: ['./task-dialoge.component.scss'],
    animations: fuseAnimations
})
export class TaskDialogeComponent implements OnInit, OnDestroy {
    errorWarningData: any = { "Error": [], 'Warning': [] };
    addData: any = [];
    TemplateName: any;
    isLoadingResults: boolean = false;
    matterData: any = [];
    TaskData: any = [];
    isspiner: boolean = false;
    action: any;
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
    title: string;
    date = new Date();
    FormAction: string;
    MatterGuid: string;
    TaskGuid: any;
    reminderCheck: any;
    time: string;
    TaskForm: FormGroup;
    RimindereDate: any;
    RimindereTime: any;
    UserDropDownData: any;
    currentuser: CurrentUser = JSON.parse(localStorage.getItem('currentUser'));
    userGuid: string;
    ImgDisAb: string;
    windowNameId: any;
    mattername: any = [];
    @ViewChild('MatterInput1', { static: false }) MatterInput: ElementRef<HTMLInputElement>;
    selectedData: any = [];
    isDisabled: boolean = true;
    exportdatavalue: any;
    searchData: any;
    separatorKeysCodes: number[] = [ENTER, COMMA];
    searchMatter: any;
    matterlist: any;
    private _unsubscribeAll$: Subject<void> = new Subject();
    private searchTerms = new Subject<string>();
    private subscription: Subscription;

    constructor(private _mainAPiServiceService: MainAPiServiceService,
        public globalFunService: GlobalFunctionsService,
        public dialogRef: MatDialogRef<TaskDialogeComponent>, private toastr: ToastrService,
        private behaviorService: BehaviorService,
        private _formBuilder: FormBuilder,
        public _matDialog: MatDialog,
        public datepipe: DatePipe,
        @Inject(MAT_DIALOG_DATA) public _data: any,
        private dialog: MatDialog,
        private router: Router,) {
        localStorage.setItem('istrackid', 'MainTaskDialogComponent');

        this.action = _data.action;
        if (this.action == 'edit' || this.action == 'edit legal') {
            this.title = "Update Task";
        } else if (this.action == 'copy' || this.action == 'copy legal') {
            this.title = "Duplicate Task";
        } else {
            this.title = "New Task";
        }
        // this.behaviorService.MatterData$.subscribe(result => {
        //   if (result) {
        //     this.matterData = result;
        //   }
        // });
        this.behaviorService.MatterData$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(result => {
            const materIDofTab = window.name.split("_");
            this.windowNameId = (materIDofTab && materIDofTab[1]) ? materIDofTab[1] : undefined;
            this.matterData = JSON.parse(localStorage.getItem(this.windowNameId ? this.windowNameId : 'set_active_matters'));
            if (this.matterData) {
            } else {
                if (result) {
                    this.matterData = result;
                    localStorage.setItem(this.windowNameId || 'set_active_matters', JSON.stringify(this.matterData))
                }
            }
        });
        /**
         *  Subscribes to the clickMaterFromTimeLine$ observable provided by behaviorService.
         * @remarks
         * It is particularly relevant for scenarios where the behaviorService controls the "open in new tab"
         * feature(on or off), and the selection of matterGuid is influenced by whether multiple tabs are open.
         */
        this.behaviorService.clickMaterFromTimeLine$
            .pipe(
                tap(result => {
                    if (result) {
                        this.matterData = result;
                    } else {
                        this.matterData = JSON.parse(localStorage.getItem(this.windowNameId || 'set_active_matters')) || result;
                        localStorage.setItem(this.windowNameId || 'set_active_matters', JSON.stringify(this.matterData));
                    }
                })
            )
            .subscribe();
        this.behaviorService.TaskData$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(result => {
            if (result) {
                this.TaskData = result;
            }
        });
        this.behaviorService.UserDropDownData$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(result => {
            if (result) {
                this.UserDropDownData = result;
            }
        });

        this.behaviorService.dialogClose$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(result => {
            if (result != null) {
                if (result.MESSAGE == 'Not logged in') {
                    this.dialogRef.close(false);
                }
            }
        });
        this.behaviorService.APIretryCall$.pipe(takeUntil(this._unsubscribeAll$)).subscribe((result) => {
            if (localStorage.getItem('istrackid') == 'MainTaskDialogComponent' && result['click'] && result['data']['task']) {
                this.ngOnInit();
            }
            if (localStorage.getItem('istrackid') == 'MainTaskDialogComponent' && result['click'] && result['data']['task']) {
                this.TaskSave();
            }
            // result['click']=false;

        });
    }

     /**
     * It runs once after the component's view has been fully initialized.
    */
    ngOnInit() {
        this.TaskForm = this._formBuilder.group({
            REMINDERDATE: [''],
            USERGUID: [''],
            UserName: [''],
            REMINDER: [''],
            TASKGUID: [''],
            MATTERGUID: [''],
            STARTDATE: [''],
            DUEDATE: [''],
            PERCENTCOMPLETE: [''],
            SendREMINDERDATE: [''],
            SendSTARTDATE: [''],
            SendREMINDERTIME: [''],
            MatterName: [''],
            STATUS: [''],
            PRIORITY: [''],
            REMINDERTIME: [''],
            DESCRIPTION: [''],
            SendDUEDATE: ['']
        });
        this.TaskForm.controls['UserName'].disable();
        if (this.action == 'edit' || this.action == 'edit legal' || this.action == 'copy' || this.action == 'copy legal') {
            this.EditPopUpOPen();
        } else if (this.action == 'new general') {
            this.ForCommonNewData();
            this.ImgDisAb = "disabled-click-cursor";
            if (this.currentuser) {
                this.TaskForm.controls['UserName'].setValue(this.currentuser.UserName);
                this.TaskForm.controls['USERGUID'].setValue(this.currentuser.UserGuid);
            }
            this.TaskForm.controls['MatterName'].disable();
            this.TaskForm.controls['MatterName'].setValue('');
            this.TaskForm.controls['MATTERGUID'].setValue('');
        }
        else {
            if (this.currentuser) {
                this.TaskForm.controls['UserName'].setValue(this.currentuser.UserName);
                this.TaskForm.controls['USERGUID'].setValue(this.currentuser.UserGuid);
            }
            this.ForCommonNewData();
            this.TaskForm.controls['MatterName'].setValue(this.matterData.SELECTEDDESCRIPTION);
            this.TaskForm.controls['MATTERGUID'].setValue(this.matterData.MATTERGUID);
            this.mattername = [this.matterData.SELECTEDDESCRIPTION]
            let matterStatus: any = localStorage.getItem('MatterWindow_Open_Status');
            if (this.router.url.split("/")[1] == 'matter-details' || matterStatus == true) {
                if (this._data?.matter) {
                    this.matterData = this._data.matter;
                }
                this.mattername = [this.matterData.SELECTEDDESCRIPTION]
                this.TaskForm.controls['MATTERGUID'].setValue(this.matterData.MATTERGUID);
            }
        }


        if (this.action == "new legal") {
            this.getTaskDefaultValues();
        }

        this.subscription = this.searchTerms.pipe(
            debounceTime(300), // Wait for 300ms pause in events
            distinctUntilChanged(), // Ignore if the next search term is the same as the previous one
            switchMap((term: string) => this.selectMatterFormSearch(term)) // Switch to new observable on new term
          ).subscribe(response => {
            if (response.CODE == 200 && response.STATUS == "success") {
                this.exportdatavalue = response.DATA
                this.searchData = response.DATA.RECORDS;
                this.matterlist = this.searchData;
            }
        });
    }

    ngOnDestroy(): void {
        this._unsubscribeAll$.next();
        this._unsubscribeAll$.complete();
        this.subscription?.unsubscribe();
    }

    ForCommonNewData() {
        // let username = JSON.parse(localStorage.getItem("task_filter"));
        // if (username != null) {
        //   this.TaskForm.controls['UserName'].setValue(username.user);
        // }
        var event = new Date().toLocaleString("en-US", { timeZone: "Australia/sydney" });
        let time = event.toLocaleString();
        this.time = new Date(time).getHours() + 1 + ":" + new Date(time).getMinutes(); //+ ":" + new Date(time).getSeconds();
        this.TaskForm.controls['REMINDERTIME'].setValue(this.time);
        let begin = this.datepipe.transform(new Date(), 'dd/MM/yyyy');
        this.TaskForm.controls['SendREMINDERDATE'].setValue(begin);
        this.TaskForm.controls['REMINDERTIME'].disable();
        this.TaskForm.controls['REMINDERDATE'].disable();
        this.TaskForm.controls['REMINDERDATE'].setValue(new Date());
        this.TaskForm.controls['STARTDATE'].setValue(new Date());
        this.TaskForm.controls['STATUS'].setValue('Not Started');
        this.TaskForm.controls['PRIORITY'].setValue('0');

        this.TaskForm.controls['DUEDATE'].setValue(new Date());
        this.TaskForm.controls['SendDUEDATE'].setValue(begin);
        this.TaskForm.controls['SendSTARTDATE'].setValue(begin);
        this.TaskForm.controls['SendREMINDERTIME'].setValue(this.time);
    }
    EditPopUpOPen() {

        // if (this.action != 'edit legal' && this.action != 'copy legal') {
        //   let username = JSON.parse(localStorage.getItem("task_filter"));
        //   if (username && username.user) {
        //     this.TaskForm.controls['UserName'].setValue(username.user);
        //   }
        // }

        //new Payload changes see this ToDo -> New API for Tasks.
        let refectorPayload = {
            "Filters": {
                TASKGUID: (this._data?.TABLEGUID) ? this._data?.TABLEGUID : this.TaskData.TASKGUID
            },
            Action: "GetData"
        };

        //old payload is : { TASKGUID: this.TaskData.TASKGUID } new is -> refectorPayload

        this.isLoadingResults = true;
        this._mainAPiServiceService.getSetData(refectorPayload, 'task').pipe(takeUntil(this._unsubscribeAll$)).subscribe(res => {
            if (res.CODE == 200 && res.STATUS == "success") {
                let DueDate = res.DATA.TASKS[0].DUEDATE.split("/");
                let DUE = new Date(DueDate[1] + '/' + DueDate[0] + '/' + DueDate[2]);
                this.TaskForm.controls['DUEDATE'].setValue(DUE);
                this.TaskForm.controls['SendDUEDATE'].setValue(res.DATA.TASKS[0].DUEDATE);
                let StartDate = res.DATA.TASKS[0].STARTDATE.split("/");
                let Start = new Date(StartDate[1] + '/' + StartDate[0] + '/' + StartDate[2]);
                this.TaskForm.controls['STARTDATE'].setValue(Start);
                this.TaskForm.controls['SendSTARTDATE'].setValue(res.DATA.TASKS[0].STARTDATE);
                let ReminderDate = res.DATA.TASKS[0].REMINDERGROUP.REMINDERDATE.split("/");
                let Reminder = new Date(ReminderDate[1] + '/' + ReminderDate[0] + '/' + ReminderDate[2]);
                let begin = this.datepipe.transform(new Date(), 'dd/MM/yyyy');
                this.TaskForm.controls['REMINDERDATE'].setValue(!ReminderDate[0] ? new Date() : Reminder);
                this.TaskForm.controls['SendREMINDERDATE'].setValue(!res.DATA.TASKS[0].REMINDERGROUP.REMINDERDATE ? begin : res.DATA.TASKS[0].REMINDERGROUP.REMINDERDATE);
                this.TaskForm.controls['USERGUID'].setValue(res.DATA.TASKS[0].USERGUID);
                this.TaskForm.controls['UserName'].setValue(res.DATA.TASKS[0].USERNAME);
                this.TaskForm.controls['MatterName'].setValue(res.DATA.TASKS[0].SELECTEDDESCRIPTION);
                this.TaskForm.controls['MATTERGUID'].setValue(res.DATA.TASKS[0].MATTERGUID);
                this.TaskForm.controls['STATUS'].setValue(res.DATA.TASKS[0].STATUS);
                this.TaskForm.controls['PRIORITY'].setValue(res.DATA.TASKS[0].PRIORITY.toString());
                let time = res.DATA.TASKS[0].REMINDERGROUP.REMINDERTIME;
                this.TaskForm.controls['REMINDERTIME'].setValue(time ? this.convert12to24Fomat(time) : this.DefaultReminderTime());
                this.TaskForm.controls['SendREMINDERTIME'].setValue(res.DATA.TASKS[0].REMINDERGROUP.REMINDERTIME);
                this.TaskForm.controls['REMINDER'].setValue(res.DATA.TASKS[0].REMINDERGROUP.REMINDER);
                this.ChekBoxClick(res.DATA.TASKS[0].REMINDERGROUP.REMINDER)
                this.TaskForm.controls['STATUS'].setValue(res.DATA.TASKS[0].STATUS);
                this.TaskForm.controls['TASKGUID'].setValue(res.DATA.TASKS[0].TASKGUID);
                this.TaskForm.controls['PERCENTCOMPLETE'].setValue(res.DATA.TASKS[0].PERCENTCOMPLETE);
                this.TaskForm.controls['DESCRIPTION'].setValue(res.DATA.TASKS[0].DESCRIPTION);
                this.mattername = [res.DATA.TASKS[0].SHORTNAME + '-' + res.DATA.TASKS[0].MATTER];

                if (!res.DATA.TASKS[0].MATTER) {
                    this.ImgDisAb = "disabled-click-cursor";
                    this.TaskForm.controls['MatterName'].disable();
                }
            } else if (res.MESSAGE == 'Not logged in') {
                this.dialogRef.close(false);
            }
            this.isLoadingResults = false;
        }, err => {
            this.toastr.error(err);
            this.isLoadingResults = false;
        });
    }
    // closepopup() {
    //     this.dialogRef.close(false);
    // }
    choosedReminderDate(type: string, event: MatDatepickerInputEvent<Date>) {
        let begin = this.datepipe.transform(event.value, 'dd/MM/yyyy');
        this.TaskForm.controls['SendREMINDERDATE'].setValue(begin);
    }
    choosedStartDate(type: string, event: MatDatepickerInputEvent<Date>) {
        let begin = this.datepipe.transform(event.value, 'dd/MM/yyyy');
        this.TaskForm.controls['SendSTARTDATE'].setValue(begin);
    }
    choosedDueDate(type: string, event: MatDatepickerInputEvent<Date>) {
        let begin = this.datepipe.transform(event.value, 'dd/MM/yyyy');
        this.TaskForm.controls['SendDUEDATE'].setValue(begin);
    }
    ChekBoxClick(val) {
        if (val.checked == true || val == 1) {
            this.TaskForm.controls['REMINDERTIME'].enable();
            this.TaskForm.controls['REMINDERDATE'].enable();
        } else {
            this.TaskForm.controls['REMINDERTIME'].disable();
            this.TaskForm.controls['REMINDERDATE'].disable();
        }
    }
    SelectMatter() {
        const dialogRef = this.dialog.open(MatterDialogComponent, { width: '100%', disableClose: true });
        dialogRef.afterClosed().subscribe(result => {
            localStorage.setItem('istrackid', 'MainTaskDialogComponent');
            if (result) {
                this.TaskForm.controls['MatterName'].setValue(result.SELECTEDDESCRIPTION);
                this.TaskForm.controls['MATTERGUID'].setValue(result.MATTERGUID);
                this.mattername = [result.SELECTEDDESCRIPTION]
            }
        });
    }
    SelectContact() {
        const dialogRef = this.dialog.open(UserSelectPopupComponent, { width: '100%', disableClose: true, data: null });
        dialogRef.afterClosed().subscribe(result => {
            localStorage.setItem('istrackid', 'MainTaskDialogComponent');
            if (result) {
                this.TaskForm.controls['UserName'].setValue(result.USERNAME);
                this.TaskForm.controls['USERGUID'].setValue(result.USERGUID);
                this.userGuid=result.USERGUID;
                console.log(this.userGuid);
                // this.TaskDialogeData.MatterName=result.MATTER
            }
        });
    }

    remove(fruit: any) {
        const index = this.mattername.indexOf(fruit);
        if (index >= 0) {
            this.mattername.splice(index, 1);
            this.TaskForm.controls['MATTERGUID'].setValue('');
        }
        let sortname = fruit.split('-')[0]
        this.rm(sortname)
    };
    rm(sortname) {
        this.selectedData.forEach((element: any, index: any) => {
            if (element.SHORTNAME.toString() == sortname.replace(/^\s+|\s+$/gm, '')) {
                this.selectedData.splice(index, 1);
            }
        })
    }

    onKeyUp(event: any): void {
        const searchValue = event.target.value;
        this.searchTerms.next(searchValue);
    }


    public selectMatterFormSearch(event) {
        // let searchobj = { FastSearch: 'True', SearchString: event }
        let searchobj = {
            "Action": "GetData",
            "Filters": { FastSearch: 'True', SearchString: event }
        };

        return this._mainAPiServiceService.getSetData(searchobj, 'matter')
    }
    
    selectedValue(event: MatAutocompleteSelectedEvent): void {
        if (this.mattername.indexOf(event.option.viewValue.replace(/^\s+|\s+$/gm, '')) == -1) {
            this.mattername = [event.option.viewValue]
            this.MatterInput.nativeElement.value = '';
            this.TaskForm.controls['MatterName'].setValue(this.matterData.SELECTEDDESCRIPTION);
        }
    };
    add(event: MatChipInputEvent): void {
        const value = (event.value || '').trim();
        this.matterlist.forEach((element: any) => {
            if (element.SHORTNAME == event.value) {
                // Add our fruit
                const array3 = this.mattername;
                if (value) {
                    if (this.mattername.indexOf(value.replace(/^\s+|\s+$/gm, '')) == -1) {
                        this.mattername = [value]
                    }
                }
            }
        });

        // Clear the input value
        if (event.input) {
            event.input.value = '';
        }
        this.TaskForm.controls['MatterName'].setValue(this.matterData.SELECTEDDESCRIPTION);
    };
    datashow(event) {
        this.exportdatavalue.RECORDS.forEach((element, i) => {
            if (i == 0) {
                let data = element
                this.mattername = [data.SELECTEDDESCRIPTION]
                this.TaskForm.controls['MatterName'].setValue(null);

                if (event.input) {
                    event.input.value = '';
                } else {
                    this.MatterInput.nativeElement.value = '';
                }

                this.TaskForm.controls['MATTERGUID'].setValue(data.MATTERGUID);
                // this.matterChange('MatterGuid', data.MATTERGUID);
                if (this.selectedData.map((ele, index) => ele.MATTERGUID == data.MATTERGUID ? index : -1)[0] == -1 || this.selectedData.length == 0) {
                    this.selectedData = [data];
                }
            }
        });
    };

    selectedmatterguid(data: any) {

        this.TaskForm.controls['MATTERGUID'].setValue(data.MATTERGUID);
        // this.matterChange('MatterGuid', data.MATTERGUID);
        // this.getDeliverabledata(data.MATTERGUID)
        // const array3 = this.selectedData;
        // if (array3.length < 1) {
        if (this.selectedData.map((ele, index) => ele.MATTERGUID == data.MATTERGUID ? index : -1)[0] == -1 || this.selectedData.length == 0) {
            this.selectedData = [data];
        }
        // }
    };
    get f() {
        return this.TaskForm.controls;
    }
    forRimindCheck() {
        if (this.f.REMINDER.value == true || this.f.REMINDER.value == 1) {
            this.RimindereDate = this.f.SendREMINDERDATE.value
            this.RimindereTime = this.f.REMINDERTIME.value
        } else {
            this.RimindereDate = "";
            this.RimindereTime = "";
        }
    }
    TaskSave() {
        if (this.f.PERCENTCOMPLETE.value < 0 || this.f.PERCENTCOMPLETE.value > 100) {
            return this.toastr.error('Compalete percentage filed not between 0 to  100');
        }
        if (this.action == "edit" || this.action == "edit legal") {
            this.forRimindCheck();
            this.FormAction = 'update';
            this.TaskGuid = this.f.TASKGUID.value
            this.MatterGuid = this.f.MATTERGUID.value;
            this.userGuid = this.f.USERGUID.value;
        } else if (this.action == 'new legal') {
            this.forRimindCheck();
            this.FormAction = 'insert';
            this.TaskGuid = "";
            this.MatterGuid = this.matterData.MATTERGUID;
            this.userGuid = this.f.USERGUID.value;
        } else if (this.action == 'new matter') {
            this.forRimindCheck();
            this.FormAction = 'insert';
            this.TaskGuid = "";
            this.MatterGuid = this.f.MATTERGUID.value;
            this.userGuid = this.f.USERGUID.value;
        } else if (this.action == 'new general') {
            this.forRimindCheck();
            this.MatterGuid = '';
            this.FormAction = 'insert';
            this.TaskGuid = "";
            this.userGuid = this.f.USERGUID.value;
        } else if (this.action == 'copy' || this.action == 'copy legal') {
            this.forRimindCheck();
            this.FormAction = 'insert';
            this.TaskGuid = "";
            this.MatterGuid = this.f.MATTERGUID.value;
            this.userGuid = this.f.USERGUID.value;
        } else {
            this.forRimindCheck();
            this.FormAction = 'insert';
            this.TaskGuid = "";
            this.MatterGuid = this.f.MATTERGUID.value;
            this.userGuid = this.f.USERGUID.value;
        }
        let data = {
            TASKGUID: this.TaskGuid,
            MATTERGUID: this.MatterGuid,
            USERGUID: this.userGuid,
            DUEDATE: this.f.SendDUEDATE.value,
            STATUS: this.f.STATUS.value,
            STARTDATE: this.f.SendSTARTDATE.value,
            PRIORITY: this.f.PRIORITY.value,
            PERCENTCOMPLETE: this.f.PERCENTCOMPLETE.value,
            DESCRIPTION: this.f.DESCRIPTION.value,
            REMINDERGROUP: {
                REMINDER: this.f.REMINDER.value,
                REMINDERDATE: this.RimindereDate,
                REMINDERTIME: this.RimindereTime,
            }
        };
        this.isspiner = true;
        let finalData = { DATA: data, FormAction: this.FormAction, VALIDATEONLY: true }
        this._mainAPiServiceService.getSetData(finalData, 'task').pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
            if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
                this.checkValidation(response.DATA.VALIDATIONS, finalData);
            } else if (response.CODE == 451 && response.STATUS == 'warning') {
                this.checkValidation(response.DATA.VALIDATIONS, finalData);
            } else if (response.CODE == 450 && response.STATUS == 'error') {
                this.checkValidation(response.DATA.VALIDATIONS, finalData);
            } else if (response.MESSAGE == 'Not logged in') {
                this.dialogRef.close(false);
            }
        }, err => {
            this.isspiner = false;
            this.toastr.error(err);
        });

    }
    async checkValidation(bodyData: any, details: any) {
        // let errorData: any = [];
        // let warningData: any = [];
        // let tempError: any = [];
        // let tempWarning: any = [];
        // bodyData.forEach(function (value) {
        //   if (value.VALUEVALID == 'No') {
        //     errorData.push(value.ERRORDESCRIPTION);
        //     tempError[value.FIELDNAME] = value;
        //   }
        //   else if (value.VALUEVALID == 'Warning') {
        //     tempWarning[value.FIELDNAME] = value;
        //     warningData.push(value.ERRORDESCRIPTION);
        //   }
        // });
        // this.errorWarningData = { "Error": tempError, 'Warning': tempWarning };
        // if (Object.keys(errorData).length != 0) {
        //   this.toastr.error(errorData);
        //   this.isspiner = false;
        // } else if (Object.keys(warningData).length != 0) {
        //   this.isspiner = false;
        //   this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
        //     disableClose: true,
        //     width: '100%',
        //     data: warningData
        //   });
        //   this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to Save?';
        //   this.confirmDialogRef.afterClosed().subscribe(result => {
        //     if (result) {
        //       this.isspiner = true;
        //       this.taskSaveData(details);
        //     }
        //     this.confirmDialogRef = null;
        //   });
        // } else if (Object.keys(warningData).length == 0 && Object.keys(errorData).length == 0) {
        //   this.taskSaveData(details);

        // }
        this.isspiner = false;
        await this.globalFunService.checkValidation(bodyData, details).pipe(takeUntil(this._unsubscribeAll$))
            .subscribe(data => {
                if (data) {
                    this.errorWarningData = data.errorWarningData;
                    // this.errorWarningDataArray = data.errorWarningData;
                    if (data.callback) {
                        this.taskSaveData(details);
                    }
                }
            });
    }
    taskSaveData(data: any) {
        data.VALIDATEONLY = false;
        this._mainAPiServiceService.getSetData(data, 'task').pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
            if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
                if (this.action !== 'edit') {
                    this.toastr.success('save successfully');
                } else {
                    this.toastr.success('update successfully');
                }
                this.dialogRef.close(true);
            } else if (response.CODE == 451 && response.STATUS == 'warning') {
                this.toastr.warning(response.MESSAGE);
                this.isspiner = false;
            } else if (response.CODE == 450 && response.STATUS == 'error') {
                this.toastr.error(response.MESSAGE);
                this.isspiner = false;
            } else if (response.MESSAGE == 'Not logged in') {
                this.dialogRef.close(false);
            }
        }, error => {
            this.isspiner = false;
            this.toastr.error(error);
        });
    }
    //Convert 12 hour format to 24 hour format
    convert12to24Fomat(time: string): string {
        var [shours, sminutes] = time.match(/([0-9]{1,2}):([0-9]{2})/).slice(1);
        var hours = Number(shours);
        var minutes = Number(sminutes);
        var AMPM = time.slice(-2);
        if (AMPM == "PM" && hours < 12) hours = hours + 12;
        if (AMPM == "AM" && hours == 12) hours = hours - 12;
        var sHours = hours.toString();
        var sMinutes = minutes.toString();
        if (hours < 10) sHours = "0" + sHours;
        if (minutes < 10) sMinutes = "0" + sMinutes;
        return sHours + ":" + sMinutes;
    }
    //set default reminder time on edit
    DefaultReminderTime(): string {
        var event = new Date().toLocaleString("en-US", { timeZone: "Australia/sydney" });
        let time = event.toLocaleString();
        return new Date(time).getHours() + 1 + ":" + new Date(time).getMinutes();
    }

    // get all fields on load
    getTaskDefaultValues() {
        const payload = {
            "FILTERS": {
            },
            "DATA": {
            },
            Action: "Default"
        };

        this._mainAPiServiceService.getSetData(payload, 'task').pipe(takeUntil(this._unsubscribeAll$)).subscribe(res => {
            if (res.CODE == 200 && res.STATUS == "success") {
                if (res.DATA.DEFAULTVALUES) {
                    this.TaskForm.patchValue(res.DATA.DEFAULTVALUES);
                    this.TaskForm.controls['PRIORITY'].setValue(res.DATA.DEFAULTVALUES.PRIORITY.toString());
                    this.TaskForm.controls["REMINDER"].setValue(res.DATA.DEFAULTVALUES.REMINDERGROUP.REMINDER)
                }
            }
        })
    }
    // ends here ~ get all fields on load

}
