import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ContactSelectDialogComponent } from '../../../contact/contact-select-dialog/contact-select-dialog.component';
import { FormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { BrowseFolderPopupComponent } from '../../browse-folder-popup/browse-folder-popup.component';
import { ConfirmPopupComponent } from 'src/app/main/confirm-popup/confirm-popup.component';
import { ToastrService } from 'ngx-toastr';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { BehaviorService } from '@_services/Behavior.service';
import { Subject } from 'rxjs';
import { TooltipService } from '@_services/tooltip.service';

@Component({
    selector: 'app-others',
    templateUrl: './others.component.html',
    styleUrls: ['./others.component.scss']
})
export class OthersComponent implements OnInit, OnDestroy {
    MatterType: any[];
    ClientSource: any[];
    Industry: any[];
    FiledOfLaw: any[];
    loadArchiveno: boolean = false;
    @Input() errorWarningData: any;
    @Input() matterdetailForm: FormGroup;
    @Input() isEditMatter: any;
    @Input() testBool;
    private _unsubscribeAll$: Subject<void> = new Subject();
    @Input() toolTipList: any;
    toolTipPostion = "above";
    confirmDialogRef1: MatDialogRef<ConfirmPopupComponent, any>;
    appPermissions = JSON.parse(localStorage.getItem('app_permissions'));
    currentUSer: any = JSON.parse(localStorage.getItem('currentUser'));
    constructor(
        public MatDialog: MatDialog,
        private _mainAPiServiceService: MainAPiServiceService,
        public datepipe: DatePipe,
        private toastr: ToastrService,
        private behaviorService: BehaviorService,
        public tooltipService: TooltipService
    ) {

        this.behaviorService.additionalFieldsValues$.subscribe(result => {
            if (result != null) {
            }
        })

    }

      /**
     * It runs once after the component's view has been fully initialized.
    */
    ngOnInit() {
        let ClientSourceLookups = JSON.parse(localStorage.getItem('client_source_lookups'));
        if (ClientSourceLookups && ClientSourceLookups != null) {
            this.ClientSource = ClientSourceLookups;
        } else {
            this._mainAPiServiceService.getSetData({ Action: 'getdata', Filters: { 'LookupType': 'Client Source' } }, 'lookup').subscribe(responses => {
                if (responses.CODE === 200 && responses.STATUS === 'success') {
                    this.ClientSource = responses.DATA.LOOKUPS;
                    localStorage.setItem('client_source_lookups', JSON.stringify(responses.DATA.LOOKUPS));
                }
            });
        }
        let MatterTypeLookups = JSON.parse(localStorage.getItem('matter_type_lookups'));
        if (MatterTypeLookups && MatterTypeLookups != null) {
            this.MatterType = MatterTypeLookups;
        } else {
            this._mainAPiServiceService.getSetData({ Action: 'getdata', Filters: { 'LookupType': 'Matter Type' } }, 'lookup').subscribe(responses => {
                if (responses.CODE === 200 && responses.STATUS === 'success') {
                    this.MatterType = responses.DATA.LOOKUPS;
                    localStorage.setItem('matter_type_lookups', JSON.stringify(responses.DATA.LOOKUPS));
                }
            });
        }
        let IndustryLookups = JSON.parse(localStorage.getItem('industry_lookups'));
        if (IndustryLookups && IndustryLookups != null) {
            this.Industry = IndustryLookups;
        } else {
            this._mainAPiServiceService.getSetData({ Action: 'getdata', Filters: { 'LookupType': 'Industry' } }, 'lookup').subscribe(responses => {
                if (responses.CODE === 200 && responses.STATUS === 'success') {
                    this.Industry = responses.DATA.LOOKUPS;
                    localStorage.setItem('industry_lookups', JSON.stringify(responses.DATA.LOOKUPS));
                }
            });
        }
        let fieldOfLawLookups = JSON.parse(localStorage.getItem('field_of_law_lookups'));
        if (fieldOfLawLookups && fieldOfLawLookups != null) {
            this.FiledOfLaw = fieldOfLawLookups;
        } else {
            this._mainAPiServiceService.getSetData({ Action: 'getdata', Filters: { 'LookupType': 'Field Of Law' } }, 'lookup').subscribe(responses => {
                if (responses.CODE === 200 && responses.STATUS === 'success') {
                    this.FiledOfLaw = responses.DATA.LOOKUPS;
                    localStorage.setItem('field_of_law_lookups', JSON.stringify(responses.DATA.LOOKUPS));
                }
            });
        }
    }

    /**
     * This function is used to open the contact matter popup.
     */
    ContactMatter() {
        const dialogRef = this.MatDialog.open(ContactSelectDialogComponent, {
            width: '100%', disableClose: true, data: {
                type: ""
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                localStorage.setItem('istrackid', 'MatterPopupComponent');
                this.matterdetailForm.controls['REFERRERGUID'].setValue(result.CONTACTGUID);
                this.matterdetailForm.controls['REFERRERNAME'].setValue(result.CONTACTNAME + ' - ' + result.SUBURB);
            }
        });
    }

    /**
     * This function is used to open the folder popup
     */
    OpenFolderListPopup() {
        const dialogRef = this.MatDialog.open(BrowseFolderPopupComponent, {
            disableClose: true, panelClass: 'contact-dialog', data: { action: 'new', isReturn: true }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.matterdetailForm.controls['DOCUMENTFOLDER'].setValue(result.FULLPATH);
            }
        });
    }

    /**
     * Used to reset the folder path
     */
    RestFolderPath() {
        this.matterdetailForm.controls['DOCUMENTFOLDER'].setValue('');
    }

    /**
     * This function is used to set the archive date
     * @param type -
     * @param event -value of the date
     */
    ArchiveDate(type: string, event: MatDatepickerInputEvent<Date>) {
        this.matterdetailForm.controls['ARCHIVEDATE'].setValue(this.datepipe.transform(event.value, 'dd/MM/yyyy'));
    }

    /**
     * used to set the Archive Destroy Due date
     * @param type -
     * @param event -value of the date
     */
    ArchiveDestroyDueDate(type: string, event: MatDatepickerInputEvent<Date>) {
        this.matterdetailForm.controls['ARCHIVEDESTROYEDDUEDATE'].setValue(this.datepipe.transform(event.value, 'dd/MM/yyyy'));
    }

    /**
     *  used to set the Archive Destroy date
     * @param type 
     * @param event-value of the date. 
     */
    ArchiveDestroyedDate(type: string, event: MatDatepickerInputEvent<Date>) {
        this.matterdetailForm.controls['ARCHIVEDESTROYEDDATE'].setValue(this.datepipe.transform(event.value, 'dd/MM/yyyy'));
    }
    /**
     * Function is used to archive
     */
    ArchiveNow() {
        this.confirmDialogRef1 = this.MatDialog.open(ConfirmPopupComponent, { disableClose: true, width: "100%", data: {} });
        this.confirmDialogRef1.componentInstance.confirmMessage = "Do you want To make the matter  inactive?";
        this.confirmDialogRef1.componentInstance.confirmTitle = 'Archive?';
        this.confirmDialogRef1.componentInstance.confirm1 = 'Yes';
        this.confirmDialogRef1.componentInstance.confirm2 = 'No';
        this.confirmDialogRef1.afterClosed().subscribe((result) => {
            if (result) {
                this.loadArchiveno = true;
                let Payload = {
                    "Action": "GetNextArchiveNumber",
                    "Data": {},
                }

                this._mainAPiServiceService.getSetData(Payload, 'utility').subscribe(response => {
                    if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
                        this.loadArchiveno = false;
                        // this.matterdetailForm.controls['ARCHIVENO'].setValue(response.DATA.ARCHIVENUMBER);
                        // this.matterdetailForm.controls['ARCHIVEDATE'].setValue(this.datepipe.transform(new Date(),'dd/MM/yyyy'));
                        // this.matterdetailForm.controls['ARCHIVEDESTROYEDDUEDATE'].setValue(this.datepipe.transform(new Date(),'dd/MM/yyyy'));
                        // this.matterdetailForm.controls['ARCHIVEDESTROYEDDATE'].setValue(this.datepipe.transform(new Date(),'dd/MM/yyyy'));
                        // this.matterdetailForm.controls['ARCHIVEDATETEXT'].setValue(new Date());
                        // this.matterdetailForm.controls['ARCHIVEDESTROYEDDUEDATETEXT'].setValue(new Date());
                        // this.matterdetailForm.controls['ARCHIVEDESTROYEDDATETEXT'].setValue(new Date());
                        this.matterdetailForm.controls['ACTIVE'].setValue(false);
                        if (this.matterdetailForm.controls['ARCHIVENO'].value == '') {
                            this.matterdetailForm.controls['ARCHIVENO'].setValue(response.DATA.ARCHIVENUMBER);
                        }

                        if (this.matterdetailForm.controls['ARCHIVEDATE'].value == '' || this.matterdetailForm.controls['ARCHIVEDATE'].value == null) {
                            let ARCHIVEDATE1 = response.DATA.ARCHIVEDATE.split("/");
                            this.matterdetailForm.controls['ARCHIVEDATETEXT'].setValue(new Date(ARCHIVEDATE1[1] + '/' + ARCHIVEDATE1[0] + '/' + ARCHIVEDATE1[2]));
                            this.matterdetailForm.controls['ARCHIVEDATE'].setValue(response.DATA.ARCHIVEDATE);

                        }
                        if (this.matterdetailForm.controls['ARCHIVEDESTROYEDDUEDATE'].value == '' || this.matterdetailForm.controls['ARCHIVEDESTROYEDDUEDATE'].value == null) {
                            let ARCHIVEDESTROYEDDUEDATE1 = response.DATA.ARCHIVEDESTROYEDDUEDATE.split("/");
                            this.matterdetailForm.controls['ARCHIVEDESTROYEDDUEDATETEXT'].setValue(new Date(ARCHIVEDESTROYEDDUEDATE1[1] + '/' + ARCHIVEDESTROYEDDUEDATE1[0] + '/' + ARCHIVEDESTROYEDDUEDATE1[2]));
                            this.matterdetailForm.controls['ARCHIVEDESTROYEDDUEDATE'].setValue(response.DATA.ARCHIVEDESTROYEDDUEDATE);

                        }
                    }
                }, error => {
                    this.toastr.error(error);
                });
            }
        });
    }

    /**
     * Used to copy the text.
     * @param event -value of the text
     */
    myFunction(event: any) {
        // var copyText: any = document.getElementById("myInput");
        // copyText.select();
        // copyText.setSelectionRange(0,9999);
        // navigator.clipboard.writeText(copyText.value)
        // var copyText: any = document.getElementById("myInput");
        // event.select();
        // event.setSelectionRange(0,9999);
        navigator.clipboard.writeText(event)
    }


    ngOnDestroy(): void {
        this._unsubscribeAll$.next();
        this._unsubscribeAll$.complete();
    }

}
