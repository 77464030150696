import { Injectable } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";
import * as _ from "lodash";
import * as $ from "jquery";
import { FuseConfirmDialogComponent } from "src/@fuse/components/confirm-dialog/confirm-dialog.component";
import { Observable } from "rxjs";
import { TimeEntryDialogComponent } from "@pages/time-entries/time-entry-dialog/time-entry-dialog.component";
@Injectable({
    providedIn: "root",
})
export class GlobalFunctionsService {
    currentUser: any;
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
    public _matDialog: MatDialog;

    constructor(private router: Router, private dialog: MatDialog) {
        this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    }

    /**
     * This function is used to go the specific tab
     * @param URL -url data value
     * @param modul -module name
     */
    goTotab(URL: any, modul) {
        const url = this.router.serializeUrl(this.router.createUrlTree([URL]));
        if (this.currentUser.OPENINNEWTAB) {
            let target = URL;
            if (environment.ISPRO) {
                this.commonFocusWindow("/#" + url, target);
                target = "";
            } else {
                // window.open('/silqWebApp/#' + url, '_blank');
                // this.commonFocusWindow(
                //     "/" + environment.URLPATH + "/#" + url,
                //     target
                // );
                this.commonFocusWindow("/#" + url,
                    target
                );
                target = "";
            }
        } else {
            this.router.navigate([url]);
        }
    }

    /**
     * This function is used to gotoId Tab
     * @param URL -URL data value
     * @param modul -modul data value
     */
    goToIdTab(URL: any, modul):void {
        const url = this.router.serializeUrl(this.router.createUrlTree([URL]));
        const currentUser = JSON.parse(localStorage.getItem("currentUser"));
        if (currentUser && currentUser.OPENINNEWTAB) {
            let target = modul;
            if (environment.ISPRO) {
                this.commonFocusWindow("/#" + url, target);
                target = "";
            } else {
                // window.open('/silqWebApp/#' + url, '_blank');
                // this.commonFocusWindow(
                //     "/" + environment.URLPATH + "/#" + url,
                //     target
                // );
                this.commonFocusWindow(
                    "/#" + url,
                    target
                );
                target = "";
            }
        } else {
            this.router.navigate([url]);
        }
    }

    /**
     * This function is used to Common focus window
     * @param url -url data value
     * @param target -target data
     */
    commonFocusWindow(url, target):void {
        let random = target;
        random = window.open(url, target);
        random.focus();
    }

    /**
     * This function is used to edit the time entry
     * @param data -data value
     */
    editTimeEntries(data?):void {
        let matterData = JSON.parse(localStorage.getItem("set_active_matters"));

        const dialogRef = this.dialog.open(TimeEntryDialogComponent, {
            width: "100%",
            disableClose: true,
            data: {
                edit: "Edit",
                matterData: matterData,
                isReadOnly: "",
                previewTimer: "",
                TABLEGUID: ""
            },
        });
        dialogRef.afterClosed().subscribe((result) => {
            // this.SetOldistrackid('close');
            if (result) {
                localStorage.setItem('Spellcheck','false');
              setTimeout(() => {
                  $("#refreshTimeEntryTab").click();
                  $("#refreshWorkInprogress").click();
                  $("#refreshreciptsDAta").click();
              },200);
               // $("#refreshtabledetails").click();
            }
        });
    }

    /**
     * This function is used to check the validation data value
     * @param bodyData -body data value
     * @param details -details data value
     * @returns data 
     */
    checkValidation(bodyData: any, details: any): Observable<any> {
        return new Observable<any>((observer) => {
            let errorData: any = [];
            let warningData: any = [];
            let tempError: any = [];
            let tempWarning: any = [];
            bodyData.forEach(async function (value: {
                VALUEVALID: string;
                ERRORDESCRIPTION: any;
                FIELDNAME: any;
            }) {
                if (value.VALUEVALID.toLowerCase() == "no") {
                    errorData.push(value.ERRORDESCRIPTION);
                    tempError[value.FIELDNAME] = value;
                } else if (value.VALUEVALID.toLowerCase() == "warning") {
                    tempWarning[value.FIELDNAME] = value;
                    warningData.push(value.ERRORDESCRIPTION);
                }
            });
            // this.errorWarningData = { "Error": tempError, 'Warning': tempWarning };
            // this.errorWarningDataArray = { "Error": tempError, 'Warning': tempWarning };
            const errWarning = {
                callback: true,
                data: details,
                errorWarningData: { Error: tempError, Warning: tempWarning },
            };
            if (Object.keys(errorData).length != 0) {
                this.confirmDialogRef = this.dialog.open(
                    FuseConfirmDialogComponent,
                    {
                        disableClose: true,
                        width: "100%",
                        data: { errorValidation: errorData, test: true },
                    }
                );
                this.confirmDialogRef.componentInstance.confirmMessage = "";
                this.confirmDialogRef.afterClosed().subscribe((result) => {
                    this.confirmDialogRef = null;
                    if (result) {
                        // this.isspiner = true;
                        // this.saveMatterData(details);

                        observer.next(errWarning);
                    } else {
                        if (
                            errWarning.data.FormAction == "previewall" &&
                            errWarning.callback
                        ) {
                            observer.next(errWarning);
                        }

                        if (
                            errWarning.data.FormAction == "invoiceall" &&
                            errWarning.callback
                        ) {
                            observer.next(errWarning);
                        }else{
                            errWarning.callback=false;
                            observer.next(errWarning);                            
                        }
                    }
                });
                // this.toastr.error(errorData);
                // this.isspiner = false;
            } else if (Object.keys(warningData).length != 0) {
                // this.isspiner = false;
                this.confirmDialogRef = this.dialog.open(
                    FuseConfirmDialogComponent,
                    {
                        disableClose: true,
                        width: "100%",
                        data: { warningValidation: warningData },
                    }
                );
                this.confirmDialogRef.componentInstance.confirmMessage =
                    "Are you sure you want to Save?";
                this.confirmDialogRef.afterClosed().subscribe((result) => {
                    this.confirmDialogRef = null;
                    if (result) {
                        errWarning.callback = true;
                        // this.isspiner = true;
                        // this.saveMatterData(details);
                        observer.next(errWarning);
                    } else {
                        errWarning.callback = false;
                        observer.next(errWarning);
                    }
                });
            } else if (
                Object.keys(warningData).length == 0 &&
                Object.keys(errorData).length == 0
            ) {
                // this.saveMatterData(details);
                observer.next(errWarning);
            }
        });
    }
}
