import { Component, Inject, OnInit, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmPopupComponent } from 'src/app/main/confirm-popup/confirm-popup.component';
import * as $ from 'jquery';
import { GenerateDocumentPopupComponent } from '../generate-document-popup/generate-document-popup.component';
import { MatterDialogComponentForTemplate } from '../matter-dialog/matter-dialog.component';
import { GeneratePacksPopupComponent } from '../packs/generate-packs-popup/generate-packs-popup.component';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { FuseConfirmDialogComponent } from 'src/@fuse/components/confirm-dialog/confirm-dialog.component';
import { FolderCreateComponent } from '../template-list/folder-create/folder-create.component';
import { EmailDailogComponent } from '../email-templete/email-dailog/email-dailog.component';
import { PacksDailogComponent } from '../packs/packs-dailog/packs-dailog.component';
import { ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { SignaturesPopupComponent } from '../../inbox/signatures-popup/signatures-popup.component';
import { BehaviorService } from '@_services/Behavior.service';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { Subject, Subscription, debounceTime, distinctUntilChanged, takeUntil } from 'rxjs';
import { CurrentUser } from 'src/app/_models/CurrentUser';
import { TierPermissionPipe } from '@_pipes/tier-permission.pipe';
import { TemplateViewerComponent } from '@pages/web-template-viewer/template-viewer/template-viewer.component';
import { DropDownTreeComponent } from '@syncfusion/ej2-angular-dropdowns';
import { TemplateHeaderFooterComponent } from '@pages/template-header-footer/template-header-footer/template-header-footer.component';
import { LexonFormAgreementComponent } from '../lexon-form-agreement/lexon-form-agreement.component';

@Component({
  selector: 'app-matter-documanr-popup',
  templateUrl: './matter-documanr-popup.component.html',
  styleUrls: ['./matter-documanr-popup.component.scss'],
  providers: [TierPermissionPipe],
  encapsulation: ViewEncapsulation.None
})
export class MatterDocumanrPopupComponent implements OnInit, AfterViewInit, OnDestroy {
  NewFIlENAME:any
  stateSelect: any;
  highlightedRows: any;
  whichFormSelect: any;
  selectedTabIndex: number = 0;
  TemplateGenerateData: any = [];
  storeFolder: any;
  windowNameId: any;
  EmailtemplateData: any = [];
  KitName: any;
  KitGUid: any;
  packsToobar: string = '';
  courtFormData: any;
  type = 1;
  oldIsTrackId: any;
  searchData = ''
  SafeCustodyData: any
  DocRegData: any = [];
  refreshbutton: boolean = false;
  menuList = [
    {
      id: 1,
      name: 'Generation',
      active: true
    },
    {
      id: 2,
      name: 'Management',
      active: false
    }
  ];

  tabs: any = [
    {
      tabNum: 0,
      name: "Template",
      active: true
    },
    {
      tabNum: 1,
      name: "Emails",
      active: false
    }, {
      tabNum: 2,
      name: "Packs",
      active: false
    }
    , {
      tabNum: 3,
      name: "Precedents",
      active: false
    }
    , {
      tabNum: 4,
      name: "Court Forms",
      active: false
    }, {
      tabNum: 5,
      name: "Lexon Forms",
      active: false
    }
  ]
  // Declare a Subject to emit new values
  inputValueSubject = new Subject<any>();
  newFileNameGuid:any
  // Subscription to handle value changes
  subscription: Subscription;
  isDocTempletType: boolean;
  isDocTempletFolder: boolean;
  isGenrateReport: boolean = false;
  confirmDialogRef2: MatDialogRef<ConfirmPopupComponent>;
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
  DeletePostData: any;
  ApiMethod: any;
  refreshId: any;
  title: any;
  pathOfGenerateDocument: any;
  private _unsubscribeAll$: Subject<void> = new Subject();
  currentUser: CurrentUser = JSON.parse(localStorage.getItem("currentUser"));
  permissions = JSON.parse(localStorage.getItem("set_tier_permission"));
   getMatterGuId = JSON.parse(
    localStorage.getItem("set_active_matters")
  );
  sub1: Subscription;
  isFound: boolean = false;
  isShowLexonForms:boolean = false;
  FolderListArray: any = [];
  Attachments: any = [];
  isLoadingResults:any
  matterData: any = {};
  expandexNodelist: any = [];

  @ViewChild("renamepopupdropdowntree", { static: false }) dropdownTree: DropDownTreeComponent;
  @ViewChild('folderlistfilename', { static: false }) public ddlelem: DropDownTreeComponent;
  public treeSettings: any = { loadOnDemand: true };
  attachdata: any;
  mail: any;
  FolderData: any = [];
  fields: any = {};
  EmailId: any;
  mattername: any = [];
  Filename: any;
  DocumentFolder: any
  // Define a boolean flag to track if it's the first time subscription
  firstTimeSubscription: boolean = true;
  firstTimeSub: boolean = true;
  mainDataArray: any = [];
  isEmpty: boolean = true;
  keyValue: any;
  currentRow: any;
  precedentsDataArray: any = [];
  Showlexonforms: boolean=false;
  isHideDiv: boolean=false;
  Title='Generate Documents';
  constructor(public dialogRef: MatDialogRef<MatterDocumanrPopupComponent>, private router: Router,
    @Inject(MAT_DIALOG_DATA) public _data: any, public behaviorService: BehaviorService, public _matDialog: MatDialog, public MatDialog: MatDialog, private _mainAPiServiceService: MainAPiServiceService, private toastr: ToastrService,public tierPermission:TierPermissionPipe) {
    this.behaviorService.TemplateGenerateData$.subscribe(result => {
      if (result) {

        this.TemplateGenerateData = result;
        // this.NewFIlENAME = this.NewFIlENAME ? this.NewFIlENAME:this.TemplateGenerateData.TEMPLATENAME
        this.NewFIlENAME = this.TemplateGenerateData.TEMPLATENAME;
        this.isDocTempletType = result.TEMPLATETYPE !== 'Folder';
        if (result.TEMPLATETYPE === 'Folder' || result.TEMPLATETYPE === 'Go_Back') {
          this.isDocTempletFolder = true;
        } else {
          this.isDocTempletFolder = false;
        }
        // this.UtilityAPI();
        // Call UtilityAPI only the first time subscription
        if (this.firstTimeSubscription) {
          setTimeout(() => {
            this.UtilityAPI();
          }, 1000);
          // Set the flag to false after the first subscription
          this.firstTimeSubscription = false;
        }
      }
    });
    this.behaviorService.MatterData$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(result => {
      if (result) {
        this.matterData = result;
        this.mattername = [this.matterData.SELECTEDDESCRIPTION]
      }
    });

    this.behaviorService.generateDocumentPath$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(data=>{
      this.pathOfGenerateDocument=data
    })

    this.subscription = this.inputValueSubject
      .pipe(
        debounceTime(500), // Debounce input changes
        distinctUntilChanged(), // Ensure distinct values
      ).subscribe((result) => {
        this.NewFIlENAME = result; // Assign input value
          // this.UtilityAPI();
        // Further processing with the result if needed
      });

    this.behaviorService.packs$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(result => {
      if (result != null) {
        this.KitName = result.KITNAME;
        this.KitGUid = result.KITGUID;
        if (result.TEMPLATETYPEDESC == 'Email') {
          this.packsToobar = 'Email';
        } else if (result.TEMPLATETYPEDESC == 'Template') {
          this.packsToobar = 'Template';
        } else {
          this.packsToobar = 'Packs';
        }
      }
    });

    this.behaviorService.courtFormData$.pipe(takeUntil(this._unsubscribeAll$)).subscribe((result) => {
      if (result)
        this.courtFormData = result;
      if(this.courtFormData) {
        if (this.firstTimeSub) {
          setTimeout(() => {
            this.UtilityAPI();
          }, 1000);
          // Set the flag to false after the first subscription
          this.firstTimeSub = false;
        }
      }
    });

    this.getTierPermission();
  }

  /**
   * THis function is used to chenge the State
   */
  changeState(){
    this.behaviorService.precedentsStateData(this.stateSelect);
  }

  /**
   * Used to check the Email template permission
   */
  getTierPermission():boolean{
    const pipe = this.tierPermission.transform("Email Templates");
    if(pipe && pipe?.ALLOWED){
      if(pipe?.ALLOWED==1){
      return true;
      }else{
      return false;
      }
    }
  }

  /**
     * It runs only once after the component's view has been rendered.
  */
  ngAfterViewInit(): void {
    this.isFound = this.permissions.some(element => {
      if (element.FEATURENAME == 'Court Forms' && element.ALLOWED == 0) {
        return true;
      }
      return false;
    });

    this.isShowLexonForms = this.permissions.some(element => {
      if (element.FEATURENAME == 'Lexon Forms' && element.ALLOWED == 0) {
        return true;
      }
      return false;
    });

    setTimeout(() => {
      $('#matter-doc-template').hide();
    }, 50);

    this.created();
  }

  /**
   * This function is used to
   */
  ngOnDestroy(): void {
      this._unsubscribeAll$.next();
    this._unsubscribeAll$.complete();

    this.sub1?.unsubscribe();
  }

   /**
     * It runs once after the component's view has been fully initialized.
    */
  ngOnInit() {
      this.title = this.menuList[0].name;
      this.behaviorService.showMails$.subscribe(data=>{
        if(data == true){
          this.selectTab(1);
        }else{
          this.selectTab(0);
        }
      })
    const matterId = window.name.split("_");
    this.windowNameId = (matterId && matterId[1]) ? matterId[1] : undefined

    this.getFolder({ DONTRECURSESUBFOLDERS: true, DONTADDROOTENTRY: true, FOLDERNAME: '' }, "rootLevel");
    // this.getAttchmentData();
    if (this._data.isGetAttechment) {
      this.mail.FILENAME = (this.mail.FILENAME).replace(/(.*)\.(.*?)$/, "$1");
    }

    if (this._data?.selectedTabIndex && this._data?.selectedTabIndex !=undefined && this._data?.selectedTabIndex !=null) {
      this.selectedTabIndex=this._data?.selectedTabIndex;
      this.selectTab(this._data?.selectedTabIndex);
      this.isHideDiv=true;
      this.Title=this._data.action;
    }
    
  }

  /**
   * this function is used to load the data
   */
  LoadData(passdata) {
    this.isLoadingResults = true;
    this.sub1 = this._mainAPiServiceService.getSetData(passdata, 'TemplateList').subscribe(response => {
      if (response.CODE == 200 && response.STATUS == "success") {
        if (response.DATA.AREASOFLAW) {
          this.mainDataArray = response.DATA.AREASOFLAW;
            const keys = Object.keys(response.DATA.AREASOFLAW);
            const val=Object.values(response.DATA.AREASOFLAW);
            const AREAOFLAWID=val[0][0].AREAOFLAWID;
            this.stateSelect = keys[0];
            this.isLoadingResults = false;
            if(this.selectedTabIndex == 3) {
              this.whichFormSelect = 1;
              this.LoadData({ "TemplateType": "PRECEDENT FORM", "AREAOFLAWID": 1 });
            }
            if(this.selectedTabIndex == 4) {
              this.whichFormSelect = '90';
              this.LoadData({ "TemplateType": "Court Form", "AREAOFLAWID": '90' });
            }

            if(this.selectedTabIndex == 5) {
              //this.whichFormSelect = '90';
              this.LoadData({ "TemplateType": "LEXON FORM", "AREAOFLAWID": AREAOFLAWID });
            }
        } else {
          if (response?.DATA && response?.DATA?.FORMS && response?.DATA?.FORMS?.length === 0) {
            this.isEmpty = true;
          } else {
            this.isEmpty = false;
          }
          this.precedentsDataArray = response?.DATA?.FORMS;
          if (response?.DATA && response?.DATA?.FORMS[0]) {
            this.rowClick(response.DATA.FORMS[0]);
          }
          // this.precedentsdata = new MatTableDataSource(this.precedentsDataArray);
          this.highlightedRows = 0;
          // this.precedentsdata.paginator = this.paginator;
          // this.precedentsdata.sort = this.sort;
          this.isLoadingResults = false;
        }
        // this.pageSize = JSON.parse(localStorage.getItem('lastPageSize'));
      }
    }, err => {

      this.toastr.error(err);
      this.isLoadingResults = false;
    });
    // this.pageSize = JSON.parse(localStorage.getItem('lastPageSize'));
  }

  /**
   * This function is used to row click
   */
  rowClick(row) {
    this.currentRow = row;
    this.behaviorService.courtFormData(row);
  }

  /**
   * This function is used select the tab
   */
  selectTab(selectTab: any) {
    this.selectedTabIndex = selectTab;
    this.packsToobar = '';
    if (this.selectedTabIndex == 0 || this.selectedTabIndex == 1 || this.selectedTabIndex == 2) {
      if(this._data.flag == "system-setting-template") {
        this.menuList = [
          {
            id: 2,
            name: 'Management',
            active: true
          }
        ];
        this.changeMode('Management',2)
        this.title = this.menuList[0].name;
      } else {
        this.menuList = [
          {
            id: 1,
            name: 'Generation',
            active: true
          },
          {
            id: 2,
            name: 'Management',
            active: false
          }
        ];
      }
    } else {
      if(this.selectedTabIndex == 3) {
        this.LoadData({ "TEMPLATETYPE": "PRECEDENT AREA OF LAW" });
      }
      if(this.selectedTabIndex == 4) {
        this.LoadData({ "TEMPLATETYPE": "Court Area of Law" });
      }
      if(this.selectedTabIndex == 5){
        this.Showlexonforms=false;
        const is_lexon = localStorage.getItem('lexon_forms');
        if(!is_lexon) {
          this.OpenLexonFormAgreement();
          return;
        } else {
          this.setLexonForms();
          return;
        }
       // this.LoadData({ "TEMPLATETYPE": "LEXON AREA OF LAW" });
      }
      this.menuList = [
        {
          id: 1,
          name: 'Generation',
          active: true
        }
      ];
    }

    this.tabs.forEach(tab => {
      if (tab.tabNum == selectTab) {
        tab.active = true;
      } else {
        tab.active = false;
      }
    });
    switch (this.selectedTabIndex) {
      case 0:
        this._data.url = this.dblClickUrl('Document');
        break;
      case 1:
        this._data.url = this.dblClickUrl('Email');
        break;
      default:
        this._data.url = this.dblClickUrl('Document');
    }


    $(".header_menu").click(function () {
      $("popup_ftr").fadeIn();
    });
    setTimeout(() => {
      $('#matter-doc-template').hide();
      $('#filters').hide();
      $('#filters-matter-popup').hide();
    }, 100);
  }

  /**
   * This function is used to open the signature dialog
   */
  signaturDialog() {

    const dialogRef = this._matDialog.open(SignaturesPopupComponent, { disableClose: true, panelClass: '', data: { data: '' } });
    dialogRef.afterClosed().subscribe(result => {
        if (result) {
            dialogRef.close();
        }
    });

}


/**
 * This function is used to dbl click on the url
 */
  dblClickUrl(docType) {
    let sendurl: any = '';
    if (docType == 'Document') {
      if (this.router.url == "/matters"
        || this.router.url == "/legal-details/settlement"
        || this.router.url == "/legal-details/chronology"
        || this.router.url == "/legal-details/authority"
        || this.router.url == "/time-billing/receipts-credits") {
        return sendurl = '/create-document/matter-template'
      } else if (this.router.url == "/time-billing/matter-invoices" || this.router.url == "/invoice/sub-invoices") {
        return sendurl = "/create-document/invoice-template"
      } else if (this.router.url == "/receive-money") {
        return sendurl = "/create-document/receive-money-template"
      } else if (this.router.url == "/contact") {
        return sendurl = "/create-document/contact-template"
      } else if (this.router.url == "/full-Safe-Custody/Safe-Custody") {
        return sendurl = "/full-Safe-Custody/Safe-Custody"
      }
    } else {
      if (this.router.url == "/matters"
        || this.router.url == "/legal-details/settlement"
        || this.router.url == "/legal-details/chronology"
        || this.router.url == "/legal-details/authority"
        || this.router.url == "/time-billing/receipts-credits") {
        return sendurl = "/create-document/email-matter-template"
      } else if (this.router.url == "/time-billing/matter-invoices" || this.router.url == "/invoice/sub-invoices") {
        return sendurl = "/create-document/email-invoice-template"
      } else if (this.router.url == "/receive-money") {
        return sendurl = "/create-document/email-receive-money-template"
      } else if (this.router.url == "/contact") {
        return sendurl = "/create-document/email-contact-template"
      } else if (this.router.url == "/full-Safe-Custody/Safe-Custody") {
        return sendurl = "/full-Safe-Custody/Safe-Custody"
      }
    }

  }

  /**
   * This function is used to change the mode
   */
  changeMode(event, index) {
    this.menuList.forEach((data) => {
      if (data.id == index) {
        this.title = data.name
        data.active = true;
      } else {
        data.active = false;
      }
    });

    if (event == 'Generation') {
      this.type = 0;
    } else {
      this.type = 1;
    }
  }

  // GenarateDocument() {
  //   this.behaviorService.TemplateGenerateData$.subscribe(result => {
  //     if (result) {
  //       this.TemplateGenerateData = result;
  //     }
  //   });
  //   this.behaviorService.templateSubFolder$.subscribe((result) => {
  //     if (result) {
  //       this.storeFolder = result;
  //     } else if (result == '') {
  //       this.storeFolder = result;
  //     }
  //   });

  //   let matterData = JSON.parse(localStorage.getItem(this.windowNameId ? this.windowNameId : 'set_active_matters'));
  //   let passdata = { 'Context': "Matter", 'ContextGuid': matterData.MATTERGUID, "knownby": "Template", "Type": "Template", "Folder": this.storeFolder, "Template": this.TemplateGenerateData.TEMPLATENAME }
  //   this.ForDocDialogOpen(passdata);

  // }

  // let passdata = { 'Context': "Invoice", 'ContextGuid': '', "knownby": "Template", "Type": "Template", "Folder": this.storeFolder, "Template": this.TemplateGenerateData.TEMPLATENAME, isMultipleDocument: true }
  //let passdata = { 'Context': "Invoice", 'ContextGuid': invoiceGUid, "knownby": "Template", "Type": "Template", "Folder": this.storeFolder, "Template": this.TemplateGenerateData.TEMPLATENAME }
  // this.ForDocDialogOpen(passdata);

  /**
   * This function is used to Edit the document data
   */
  EditDocument(){
    let fileName;
    let folder;
    this.behaviorService.TemplateGenerateData$.subscribe(result => {
      if (result) {
        fileName = result.TEMPLATENAME;
      }
    });
    this.behaviorService.templateSubFolder$.subscribe((result) => {
      if (result) {
        folder = result;
      } else if (result == '') {
        folder = result;
      }
    })
    if(fileName) {
      this.DownloadTemplatesEdit(fileName, folder)
    }
  }

  setHeaderFooter(){
    const dialogRef = this._matDialog.open(TemplateHeaderFooterComponent, {
      width: "100%",
      disableClose: true,
      data: { },
    });
    dialogRef.afterClosed().subscribe((result) => {
        // if (result) {
        // }
    });
  }

  /**
   * This function is used to change the input data value.
   */
  changeInput(event: any) {
    // Emit the new value to the Subject
    // this.inputValueSubject.next(newValue);
    this.newFileNameGuid = event.target.value;
    this.inputValueSubject.next(event.target.value);
    // this.TemplateGenerateData.TEMPLATENAME = newValue
  }


  /**
   * This function is used to call the utility api.
   */
  UtilityAPI() {
    // this.behaviorService.TemplateGenerateData$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(result => {
    //   if (result) {
    //     this.TemplateGenerateData = result;
    //     this.isDocTempletType = result.TEMPLATETYPE !== 'Folder';
    //     if (result.TEMPLATETYPE === 'Folder' || result.TEMPLATETYPE === 'Go_Back') {
    //       this.isDocTempletFolder = true;
    //     } else {
    //       this.isDocTempletFolder = false;
    //     }
    //   }
    // });
    // this.behaviorService.TemplateGenerateData$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(result => {
    //   if (result) {
    //     this.TemplateGenerateData = result;
    //     this.NewFIlENAME = this.NewFIlENAME ? this.NewFIlENAME:this.TemplateGenerateData.TEMPLATENAME
    //   }
    // });
    let collectSelectedInvoice = JSON.parse(localStorage.getItem('collectSelectedInvoice'));
    let passdata

    // if (this.router.url == "/time-billing/matter-invoices") {
    if (this.router.url == "/time-billing/matter-invoices" || this.router.url == '/matter-details/matter-invoices') {
      let invoiceGUid = localStorage.getItem('edit_invoice_id');
      let collectSelectedInvoice = JSON.parse(localStorage.getItem('collectSelectedInvoice'));
      if (collectSelectedInvoice && collectSelectedInvoice.length > 1 && this._data.flag == 'MultipleDocument') {
        // passdata = { 'Context': "Invoice", 'ContextGuid': '', "knownby": "Template", "Type": "Template", "Folder": this.storeFolder, "Template": this.TemplateGenerateData.TEMPLATENAME, isMultipleDocument: true }
        passdata = { 'Context': "Invoice", 'ContextGuid': invoiceGUid, "knownby": "Template", "Type": "Template", "Folder": this.storeFolder, "Template": this.TemplateGenerateData.TEMPLATENAME, isMultipleDocument: true }
      } else {
        let invoiceGUid = localStorage.getItem('edit_invoice_id');
        passdata = { 'Context': "Invoice", 'ContextGuid': invoiceGUid, "knownby": "Template", "Type": "Template", "Folder": this.storeFolder, "Template": this.TemplateGenerateData.TEMPLATENAME }
      }

    } else if (this.router.url == "/legal-details/settlement" || this.router.url == "/legal-details/chronology" || this.router.url == "/legal-details/authority") {
      let matterData = JSON.parse(localStorage.getItem(this.windowNameId ? this.windowNameId : 'set_active_matters'));
      passdata = { 'Context': "Matter", 'ContextGuid': matterData.MATTERGUID, "knownby": "Template", "Type": "Template", "Folder": this.storeFolder, "Template": this.TemplateGenerateData.TEMPLATENAME }

    } else if (this.router.url == "/time-billing/receipts-credits") {
      let ReceiptData = JSON.parse(localStorage.getItem('receiptData'));
      passdata = { 'Context': "Receipt", 'ContextGuid': ReceiptData.INCOMEGUID, "knownby": "Template", "Type": "Template", "Folder": this.storeFolder, "Template": this.TemplateGenerateData.TEMPLATENAME }


    } else if (this.router.url == "/receive-money") {
      let ReceiptData = JSON.parse(localStorage.getItem('receiptData'));
      passdata = { 'Context': "Receipt", 'ContextGuid': ReceiptData.INCOMEGUID, "knownby": "Template", "Type": "Template", "Folder": this.storeFolder, "Template": this.TemplateGenerateData.TEMPLATENAME }


    } else if (this.router.url == "/contact") {
      let ContactGuID = localStorage.getItem('contactGuid');
      passdata = { 'Context': "Contact", 'ContextGuid': ContactGuID, "knownby": "Template", "Type": "Template", "Folder": this.storeFolder, "Template": this.TemplateGenerateData.TEMPLATENAME }


    } else if (this.router.url == "/full-Safe-Custody/Safe-Custody") {
      passdata = { 'Context': "Safe Custody", 'ContextGuid': this.SafeCustodyData.SAFECUSTODYGUID, "knownby": "Template", "Type": "Template", "Folder": this.storeFolder, "Template": this.TemplateGenerateData.TEMPLATENAME }

    } else if (this.router.url == "/invoice/sub-invoices") {
      try {

        let collectSelectedInvoice = JSON.parse(localStorage.getItem('collectSelectedInvoice'));

        if (collectSelectedInvoice && collectSelectedInvoice.length > 1 && (this._data.flag == 'MultipleDocument' || this._data.flag == 'GenerateDocument')) {
          // passdata = { 'Context': "Invoice", 'ContextGuid': '', "knownby": "Template", "Type": "Template", "Folder": this.storeFolder, "Template": this.TemplateGenerateData.TEMPLATENAME, isMultipleDocument: true }
          let invoiceGUid = localStorage.getItem('edit_invoice_id');
          passdata = { 'Context': "Invoice", 'ContextGuid': invoiceGUid, "knownby": "Template", "Type": "Template", "Folder": this.storeFolder, "Template": this.TemplateGenerateData.TEMPLATENAME, isMultipleDocument: true }
        } else {
          let invoiceGUid = localStorage.getItem('edit_invoice_id');
          passdata = { 'Context': "Invoice", 'ContextGuid': invoiceGUid, "knownby": "Template", "Type": "Template", "Folder": this.storeFolder, "Template": this.TemplateGenerateData.TEMPLATENAME }
        }

      } catch (err) {
        console.log(err)
      }
    } else if (this.router.url == "/matters") {
      let matterData = JSON.parse(localStorage.getItem(this.windowNameId ? this.windowNameId : 'set_active_matters'));
      // passdata = { 'Context': "Matter", 'ContextGuid': '', "knownby": "Template", "Type": "Template", "Folder": this.storeFolder, "Template": this.NewFIlENAME, isMultipleMatter: true }
      passdata = { 'Context': "Matter", 'ContextGuid': matterData.MATTERGUID, "knownby": "Template", "Type": "Template", "Folder": this.storeFolder, "Template": this.NewFIlENAME, isMultipleMatter: true }
    } else {
      let matterData = JSON.parse(localStorage.getItem(this.windowNameId ? this.windowNameId : 'set_active_matters'));
      passdata = { 'Context': "Matter", 'ContextGuid': matterData.MATTERGUID, "knownby": "Template", "Type": "Template", "Folder": this.storeFolder, "Template": this.NewFIlENAME }

    }
    let Payload;
    this.NewFIlENAME = '';
    this.newFileNameGuid = '';
    if((this.selectedTabIndex == 3 || this.selectedTabIndex == 4 || this.selectedTabIndex == 5) && this.courtFormData) {
      Payload = { Action: 'GetAutoDocName', Data: { CONTEXT: passdata.Context, CONTEXTGUID: passdata.ContextGuid, TEMPLATEFILE: passdata.Template, 'FORMID': this.courtFormData.FORMID, "Type": this.selectedTabIndex == 3 ? "PRECEDENT FORM"  : (this.selectedTabIndex == 5 ) ? "LEXON FORM" :  "Court Form" } }
    }  else {
      Payload = { Action: 'GetAutoDocName', Data: { CONTEXT: passdata.Context, CONTEXTGUID: passdata.ContextGuid, TEMPLATEFILE: passdata.Template } }
    }
    this._mainAPiServiceService.getSetData(Payload, 'utility').pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
      if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
        this.NewFIlENAME = response.DATA.DOCUMENTNAME
        // this.newFileNameGuid = this.getMatterGuId.SHORTNAME + response.DATA.DOCUMENTNAME
        this.newFileNameGuid = response.DATA.DOCUMENTNAME

        // this.TemplateGenerateData.TEMPLATENAME = response.DATA.DOCUMENTNAME
        // const cf = response.DATA.CLERKFEE ? response.DATA.CLERKFEE : this.f.CLERKFEE.value;
        // this.PrepareReceiptForm.controls['CLERKFEE'].setValue(Number(cf).toFixed(2));
        // const acf = response.DATA.AMOUNTLESSCLERKFEE ? response.DATA.AMOUNTLESSCLERKFEE : this.f.AMOUNTLESSCLERKFEE.value;
        // this.PrepareReceiptForm.controls['AMOUNTLESSCLERKFEE'].setValue(Number(acf).toFixed(2));
        // const amt = response.DATA.AMOUNTRECEIVED ? response.DATA.AMOUNTRECEIVED : this.f.AMOUNT.value;
        // this.PrepareReceiptForm.controls['AMOUNT'].setValue(Number(amt).toFixed(2));
      }
      // this.isspiner = false;
    }, error => {
      // this.isspiner = false;
      this.toastr.error(error);
    });
  }

  /**
   * This function is used to Generate the document data value.
   */
  GenarateDocument() {
    this.behaviorService.TemplateGenerateData$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(result => {
      if (result) {
        this.TemplateGenerateData = result;
      }
    });
    this.behaviorService.SafeCustody$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(result => {
      if (result) {
        this.SafeCustodyData = result;
      }
    });
    this.behaviorService.templateSubFolder$.pipe(takeUntil(this._unsubscribeAll$)).subscribe((result) => {
      if (result) {
        this.storeFolder = result;
      } else if (result == '') {
        this.storeFolder = result;
      }
    })

    let collectSelectedInvoice = JSON.parse(localStorage.getItem('collectSelectedInvoice'));


    // if (this.router.url == "/time-billing/matter-invoices") {
      if (this.router.url == "/time-billing/matter-invoices" || this.router.url =='/matter-details/matter-invoices') {
      let passdata
      let collectSelectedInvoice = JSON.parse(localStorage.getItem('collectSelectedInvoice'));
      if (collectSelectedInvoice && collectSelectedInvoice.length > 1 && this._data.flag == 'MultipleDocument') {
        passdata = { 'Context': "Invoice", 'ContextGuid': '', "knownby": "Template", "Type": "Template", "Folder": this.storeFolder, "Template": this.TemplateGenerateData.TEMPLATENAME, isMultipleDocument: true, DOCUMENTNAME: this.NewFIlENAME, DOCUMENTFOLDER: this.DocumentFolder  }
      } else {
        let invoiceGUid = localStorage.getItem('edit_invoice_id');
        passdata = { 'Context': "Invoice", 'ContextGuid': invoiceGUid, "knownby": "Template", "Type": "Template", "Folder": this.storeFolder, "Template": this.TemplateGenerateData.TEMPLATENAME, DOCUMENTNAME: this.NewFIlENAME, DOCUMENTFOLDER: this.DocumentFolder }
      }
      //let passdata = { 'Context': "Invoice", 'ContextGuid': invoiceGUid, "knownby": "Template", "Type": "Template", "Folder": this.storeFolder, "Template": this.TemplateGenerateData.TEMPLATENAME }
        this.ForDocDialogOpen(passdata);

    } else if (this.router.url == "/legal-details/settlement" || this.router.url == "/legal-details/chronology" || this.router.url == "/legal-details/authority") {
      let matterData = JSON.parse(localStorage.getItem(this.windowNameId ? this.windowNameId : 'set_active_matters'));
        let passdata = { 'Context': "Matter", 'ContextGuid': matterData.MATTERGUID, "knownby": "Template", "Type": "Template", "Folder": this.storeFolder, "Template": this.TemplateGenerateData.TEMPLATENAME, DOCUMENTNAME: this.NewFIlENAME, DOCUMENTFOLDER: this.DocumentFolder }
        this.ForDocDialogOpen(passdata);
      }else if( this.router.url == "/time-billing/receipts-credits"){
        let ReceiptData = JSON.parse(localStorage.getItem('receiptData'));
        let passdata = { 'Context': "Receipt", 'ContextGuid': ReceiptData.INCOMEGUID, "knownby": "Template", "Type": "Template", "Folder": this.storeFolder, "Template": this.TemplateGenerateData.TEMPLATENAME, DOCUMENTNAME: this.NewFIlENAME, DOCUMENTFOLDER: this.DocumentFolder }
        this.ForDocDialogOpen(passdata);

    } else if (this.router.url == "/receive-money" || this.router.url == "/matter-details/receipts-credits") {
      let ReceiptData = JSON.parse(localStorage.getItem('receiptData'));
        let passdata = { 'Context': "Receipt", 'ContextGuid': ReceiptData.INCOMEGUID, "knownby": "Template", "Type": "Template", "Folder": this.storeFolder, "Template": this.TemplateGenerateData.TEMPLATENAME, DOCUMENTNAME: this.NewFIlENAME, DOCUMENTFOLDER: this.DocumentFolder }
        this.ForDocDialogOpen(passdata);

    } else if (this.router.url == "/contact") {
      let ContactGuID = localStorage.getItem('contactGuid');
        let passdata = { 'Context': "Contact", 'ContextGuid': ContactGuID, "knownby": "Template", "Type": "Template", "Folder": this.storeFolder, "Template": this.TemplateGenerateData.TEMPLATENAME, DOCUMENTNAME: this.NewFIlENAME, DOCUMENTFOLDER: this.DocumentFolder }
        this.ForDocDialogOpen(passdata);

    } else if (this.router.url == "/full-Safe-Custody/Safe-Custody") {
        let passdata = { 'Context': "Safe Custody", 'ContextGuid': this.SafeCustodyData.SAFECUSTODYGUID, "knownby": "Template", "Type": "Template", "Folder": this.storeFolder, "Template": this.TemplateGenerateData.TEMPLATENAME, DOCUMENTNAME: this.NewFIlENAME, DOCUMENTFOLDER: this.DocumentFolder }
        this.ForDocDialogOpen(passdata);
    } else if (this.router.url == "/invoice/sub-invoices") {
        try {
          let passdata
          let collectSelectedInvoice = JSON.parse(localStorage.getItem('collectSelectedInvoice'));

          if (collectSelectedInvoice && collectSelectedInvoice.length > 1 && (this._data.flag == 'MultipleDocument' || this._data.flag == 'GenerateDocument')) {
            passdata = { 'Context': "Invoice", 'ContextGuid': '', "knownby": "Template", "Type": "Template", "Folder": this.storeFolder, "Template": this.TemplateGenerateData.TEMPLATENAME, isMultipleDocument: true, DOCUMENTNAME: this.NewFIlENAME, DOCUMENTFOLDER: this.DocumentFolder }
          } else {
            let invoiceGUid = localStorage.getItem('edit_invoice_id');
            passdata = { 'Context': "Invoice", 'ContextGuid': invoiceGUid, "knownby": "Template", "Type": "Template", "Folder": this.storeFolder, "Template": this.TemplateGenerateData.TEMPLATENAME, DOCUMENTNAME: this.NewFIlENAME, DOCUMENTFOLDER: this.DocumentFolder }
          }
          //let passdata = { 'Context': "Invoice", 'ContextGuid': invoiceGUid, "knownby": "Template", "Type": "Template", "Folder": this.storeFolder, "Template": this.TemplateGenerateData.TEMPLATENAME }

          this.ForDocDialogOpen(passdata);
        } catch(err) {
          console.log(err)
        }

    } else if(this.router.url == "/matters") {
      let matterData = JSON.parse(localStorage.getItem(this.windowNameId ? this.windowNameId : 'set_active_matters'));
        let passdata = { 'Context': "Matter", 'ContextGuid': '', "knownby": "Template", "Type": "Template", "Folder": this.storeFolder, "Template": this.TemplateGenerateData.TEMPLATENAME, isMultipleMatter: true, DOCUMENTNAME: this.NewFIlENAME, DOCUMENTFOLDER:this.DocumentFolder }
        this.ForDocDialogOpen(passdata);
    } else {
    let matterData = JSON.parse(localStorage.getItem(this.windowNameId ? this.windowNameId : 'set_active_matters'));
        let passdata = { 'Context': "Matter", 'ContextGuid': matterData.MATTERGUID, "knownby": "Template", "Type": "Template", "Folder": this.storeFolder, "Template": this.TemplateGenerateData.TEMPLATENAME, DOCUMENTNAME: this.NewFIlENAME, DOCUMENTFOLDER: this.DocumentFolder }
        this.ForDocDialogOpen(passdata);
    }

  }

  /**
   * This function is used to Generate the Email
   */
  GenarateEmail(val: any = '') {
    this.behaviorService.EmailGenerateData$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(result => {
      if (result) {
        this.EmailtemplateData = result;
      }
    });

    // let matterData = JSON.parse(localStorage.getItem(this.windowNameId ? this.windowNameId : 'set_active_matters'));
    // let passdata = { 'Context': "Matter", 'ContextGuid': matterData.MATTERGUID, "knownby": "Email", "Type": "Email", "Folder": '', "Template": this.EmailtemplateData.NAME, "ExtraData": "inboxWithTemplate" }
    // this.ForEmailDialogOpen(passdata);
    //let passdata = { 'Context': "Invoice", 'ContextGuid': '', "knownby": "Email", "Type": "Email", "Folder": '', "Template": this.EmailtemplateData.NAME, isMultipleDocument: true }
    if (this.router.url == "/time-billing/matter-invoices" || this.router.url == "/invoice/sub-invoices" || this.router.url =='/matter-details/matter-invoices') {
      let passdata
      let invoiceGUid = localStorage.getItem('edit_invoice_id');
      let collectSelectedInvoice = JSON.parse(localStorage.getItem('collectSelectedInvoice'));

      if (collectSelectedInvoice && collectSelectedInvoice.length > 1 && this._data.flag == 'MultipleDocument') {

        passdata = { 'Context': "Invoice", 'ContextGuid': '', "knownby": "Email", "Type": "Email", "Folder": '', "Template": this.EmailtemplateData.NAME, isMultipleDocument: true }
      } else {

        passdata = { 'Context': "Invoice", 'ContextGuid': invoiceGUid, "knownby": "Email", "Type": "Email", "Folder": '', "Template": this.EmailtemplateData.NAME }
      }


      //this.ForDocDialogOpen(passdata);
      this.ForEmailDialogOpen(passdata);

    } else if (this.router.url == "/matters"
      || this.router.url == "/legal-details/settlement" || this.router.url == "/legal-details/chronology" || this.router.url == "/legal-details/authority" || this.router.url == "/time-billing/receipts-credits" || this.router.url == '/matter-details/matter-detail-dashboard') {
      let matterData = JSON.parse(localStorage.getItem(this.windowNameId ? this.windowNameId : 'set_active_matters'));
      let ReceiptData = JSON.parse(localStorage.getItem('receiptData'));
      let passdata = { 'Context': "Matter", 'ContextGuid': matterData.MATTERGUID, "knownby": "Email", "Type": "Email", "Folder": this.storeFolder, "Template": this.EmailtemplateData.NAME }
      // this.ForDocDialogOpen(passdata);
      this.ForEmailDialogOpen(passdata);
    }else if( this.router.url == "/time-billing/receipts-credits"){
      let ReceiptData = JSON.parse(localStorage.getItem('receiptData'));
      let passdata = { 'Context': "Receipt", 'ContextGuid':ReceiptData.INCOMEGUID , "knownby": "Template", "Type": "Template", "Folder": this.storeFolder, "Template": this.TemplateGenerateData.TEMPLATENAME }
      this.ForEmailDialogOpen(passdata);


    } else if (this.router.url == "/receive-money") {
      let ReceiptData = JSON.parse(localStorage.getItem('receiptData'));
      let passdata = { 'Context': "Income", 'ContextGuid': ReceiptData.INCOMEGUID, "knownby": "Email", "Type": "Email", "Folder": this.storeFolder, "Template": this.EmailtemplateData.NAME }
      //this.ForDocDialogOpen(passdata);
      this.ForEmailDialogOpen(passdata);

    } else if (this.router.url == "/contact") {
      let ContactGuID = localStorage.getItem('contactGuid');
      let passdata = { 'Context': "Contact", 'ContextGuid': ContactGuID, "knownby": "Email", "Type": "Email", "Folder": this.storeFolder, "Template": this.EmailtemplateData.NAME }
      //this.ForDocDialogOpen(passdata);
      this.ForEmailDialogOpen(passdata);

    } else if (this.router.url == "/full-Safe-Custody/Safe-Custody") {
      let passdata = { 'Context': "Safe Custody", 'ContextGuid': this.SafeCustodyData.SAFECUSTODYGUID, "knownby": "Email", "Type": "Email", "Folder": this.storeFolder, "Template": this.EmailtemplateData.NAME }
      //this.ForDocDialogOpen(passdata);
      this.ForEmailDialogOpen(passdata);
    }
    else {
      let matterData = JSON.parse(localStorage.getItem(this.windowNameId ? this.windowNameId : 'set_active_matters'));
      let passdata = { 'Context': "Matter", 'ContextGuid':matterData.MATTERGUID , "knownby": "Template", "Type": "Template", "Folder": this.storeFolder, "Template": this.TemplateGenerateData.TEMPLATENAME }
      this.ForEmailDialogOpen(passdata);
      }
  }

  /**
   * This function is used to Generate the Packs
   */
  GenaratePacks() {
    // let matterData = JSON.parse(localStorage.getItem(this.windowNameId ? this.windowNameId : 'set_active_matters'));
    // let passdata = { 'Context': "Matter", 'ContextGuid': matterData.MATTERGUID, "knownby": "Pack", "Type": "Pack", "Folder": '', "Template": this.KitName }
    // this.ForEmailDialogOpen(passdata);
    if (this.router.url == "/time-billing/matter-invoices" || this.router.url == "/invoice/sub-invoices" || this.router.url == "/matter-details/matter-invoices") {
      let invoiceGUid = localStorage.getItem('edit_invoice_id');
      let passdata = { 'Context': "Invoice", 'ContextGuid': invoiceGUid, "knownby": "Pack", "Type": "Pack", "Folder": '', "Template": this.KitName }
      //this.ForDocDialogOpen(passdata);
      this.ForEmailDialogOpen(passdata);

    } else if (this.router.url == "/matters"
      || this.router.url == "/matter-details/settlement" || this.router.url == "/matter-details/chronology" || this.router.url == "/matter-details/authority" || this.router.url == "/matter-details/matter-detail-dashboard") {
      let matterData = JSON.parse(localStorage.getItem(this.windowNameId ? this.windowNameId : 'set_active_matters'));
      let passdata = { 'Context': "Matter", 'ContextGuid': matterData.MATTERGUID, "knownby": "Pack", "Type": "Pack", "Folder": '', "Template": this.KitName }
      // this.ForDocDialogOpen(passdata);
      this.ForEmailDialogOpen(passdata);

    } else if (this.router.url == "/receive-money" || this.router.url == "/time-billing/receipts-credits" || this.router.url == "/matter-details/receipts-credits") {
      let ReceiptData = JSON.parse(localStorage.getItem('receiptData'));
      let passdata = { 'Context': "Receipt", 'ContextGuid': ReceiptData.INCOMEGUID, "knownby": "Pack", "Type": "Pack", "Folder": '', "Template": this.KitName }
      //this.ForDocDialogOpen(passdata);
      this.ForEmailDialogOpen(passdata);

    } else if (this.router.url == "/contact") {
      let ContactGuID = localStorage.getItem('contactGuid');
      let passdata = { 'Context': "Contact", 'ContextGuid': ContactGuID, "knownby": "Pack", "Type": "Pack", "Folder": '', "Template": this.KitName }
      //this.ForDocDialogOpen(passdata);
      this.ForEmailDialogOpen(passdata);

    } else if (this.router.url == "/full-Safe-Custody/Safe-Custody") {
      let passdata = { 'Context': "Safe Custody", 'ContextGuid': this.SafeCustodyData.SAFECUSTODYGUID, "knownby": "Pack", "Type": "Pack", "Folder": '', "Template": this.KitName }
      // this.ForDocDialogOpen(passdata);
      this.ForEmailDialogOpen(passdata);
    }else {
      let matterData = JSON.parse(localStorage.getItem(this.windowNameId ? this.windowNameId : 'set_active_matters'));
      let passdata = { 'Context': "Matter", 'ContextGuid':matterData.MATTERGUID , "knownby": "Template", "Type": "Template", "Folder": this.storeFolder, "Template": this.TemplateGenerateData.TEMPLATENAME }
      this.ForEmailDialogOpen(passdata);
      }
  }

  /**
   * This function is used to Generate the Precedents
   */
  GenaratePrecedents() {
    let matterData = JSON.parse(localStorage.getItem(this.windowNameId ? this.windowNameId : 'set_active_matters'));
    let passdata = { 'FORMID': this.courtFormData.FORMID, 'nameShow': this.courtFormData.DESCRIPTION, 'Context': "Matter", 'ContextGuid': matterData.MATTERGUID, "knownby": "Template", "Type": "PRECEDENT FORM" }
    this.ForDocDialogOpen(passdata);
  }

  /**
   * This fdunction is used to generate the Court forms
   */
  GenarateCourtForm()  {
    let matterData = JSON.parse(localStorage.getItem(this.windowNameId ? this.windowNameId : 'set_active_matters'));
    let passdata = { 'FORMID': this.courtFormData.FORMID, 'nameShow': this.courtFormData.DESCRIPTION, 'Context': "Matter", 'ContextGuid': matterData.MATTERGUID, "knownby": "Template", "Type":(this.selectedTabIndex == 4) ? "Court Form" : "LEXON FORM", DOCUMENTNAME: this.NewFIlENAME, DOCUMENTFOLDER: this.DocumentFolder || '' }
    this.ForDocDialogOpen(passdata);
  }

  /**
   * This function is used to For DocDialog Open
   */
  ForDocDialogOpen(passdata) {
    const dialogRef = this._matDialog.open(GenerateDocumentPopupComponent, { width: '100%', disableClose: true, data: passdata });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {

      }
    });
  }

  /**
   * This function is used to open the Email dialog
   */
  ForEmailDialogOpen(passdata) {
    if (passdata.knownby == 'Pack') {
      const dialogRef = this._matDialog.open(GeneratePacksPopupComponent, {
        width: '100%',
        disableClose: true,
        data: passdata
      });
      dialogRef.afterClosed().subscribe(result => { });
    } else {
      const dialogRef = this._matDialog.open(MatterDialogComponentForTemplate, {
        width: '100%',
        disableClose: true,
        data: passdata
      });
      dialogRef.afterClosed().subscribe(result => { });
    }
  }

/**
 * This function is used to Download the Templates
 */
  DownloadTemplates(obj) {
    this.isGenrateReport = true;
    let fileName = '';
    let folder = '';
    this.SetOldistrackid('open');
    if (obj === 'single') {
      this.confirmDialogRef2 = this.MatDialog.open(ConfirmPopupComponent, { disableClose: true, width: '100%', });
      this.confirmDialogRef2.componentInstance.confirmMessage = "Due to the default behaviour of Word, in order to edit this template, you will need to open Microsoft Word first and then open the template from inside Word.";
      this.confirmDialogRef2.componentInstance.confirmMessage1 = "To do this, go to File --> Open --> navigate to your downloads folder and find the template there";
      this.confirmDialogRef2.componentInstance.confirmTitle = "Download Templates";
      this.confirmDialogRef2.componentInstance.confirm1 = "Ok";
      this.confirmDialogRef2.componentInstance.confirm2 = "";

      this.confirmDialogRef2.afterClosed().subscribe(result => {
        this.behaviorService.TemplateGenerateData$.subscribe(result => {
          if (result) {
            fileName = result.TEMPLATENAME;
          }
        });
        this.behaviorService.templateSubFolder$.subscribe((result) => {
          if (result) {
            folder = result;
          } else if (result == '') {
            folder = result;
          }
        })
        this.DownloadTemplatesCall(fileName, folder);
      });

    } else {
      this.DownloadTemplatesCall(fileName, folder);
    }
  }

  /**
   * This function is used to Download the Template Edit data value.
   */
  DownloadTemplatesEdit(fileName: any, folder: any) {
    let session_token = localStorage.getItem('session_token');
    this.SetOldistrackid('open');
    this._mainAPiServiceService.getSetData({ FILENAME: fileName, FOLDER: folder }, 'TemplateDownload').subscribe(response => {
      // this.SetOldistrackid('close');
      if (response.FileUpload_response.CODE == 200 && response.FileUpload_response.STATUS === 'success') {
        // this.dialogRef.close(false);
        this.behaviorService.setDocumentPathFolder(folder);
        this.behaviorService.setDocumentPathFilaname(fileName);
        this.behaviorService.setDocumentPathData(response.FileUpload_response.DATA.DOWNLOADFILEBASE64);
        this.behaviorService.setDocumentEnvUrl(environment.APIEndpoint);
        this.behaviorService.setSessionToken(session_token);
        this.behaviorService.setApiKey(environment.APIKEY);
        this.behaviorService.setDocumentEnvUrlText(environment.APIEndpointDeliverable);
        // const url = this.router.serializeUrl(
        //   this.router.createUrlTree(["/template-viewer"])
        // );
        // if (this.currentUser.OPENINNEWTAB) {
        //   localStorage.setItem('folder', folder)
        //   localStorage.setItem('filename', fileName)
        //   localStorage.setItem('doc_path', environment.DocumentsUrl + response.FileUpload_response.DATA.DOWNLOADFILE)
        //   localStorage.setItem('env_url', environment.APIEndpoint)
        //   localStorage.setItem('env_url_text', environment.APIEndpointDeliverable)
        //   localStorage.setItem('api_key', environment.APIKEY)
        //   if (environment.ISPRO) {
        //       this.commonFocusWindow("/#" + url);
        //   } else {
        //       this.commonFocusWindow(
        //           "/" + environment.URLPATH + "/#" + url
        //       );
        //   }
        // } else {
        //     this.router.navigate(['/template-viewer'])
        // }
        const dialogRef = this._matDialog.open(TemplateViewerComponent, {
          width: "100%",
          disableClose: true,
          data: { },
        });
        dialogRef.afterClosed().subscribe((result) => {
            // if (result) {
            // }
            this.SetOldistrackid('close');
        });
      } else if (response.FileUpload_response.MESSAGE == 'Not logged in') {
        this.SetOldistrackid('close');
        this.dialogRef.close(false);
      }
      this.isGenrateReport = false;
    }, error => {
      this.SetOldistrackid('close');
      this.toastr.error(error);
      this.isGenrateReport = false;
    });
  }

  /**
   * This function is used to comman Focus window.
   */
  commonFocusWindow(url) {
    let random: any;
    random = window.open(url, "_blank");
    random.focus();
  }

  /**
   * This function is used to Download the Temlates Call
   */
  DownloadTemplatesCall(fileName: any, folder: any) {
    this._mainAPiServiceService.getSetData({ FILENAME: fileName, FOLDER: folder }, 'TemplateDownload').pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
      this.SetOldistrackid('close');
      if (response.FileUpload_response.CODE == 200 && response.FileUpload_response.STATUS === 'success') {
        window.open(environment.DocumentsUrl + response.FileUpload_response.DATA.DOWNLOADFILE);
      } else if (response.FileUpload_response.MESSAGE == 'Not logged in') {
      }
      this.isGenrateReport = false;
    }, error => {
      this.SetOldistrackid('close');
      this.toastr.error(error);
      this.isGenrateReport = false;
    });
  }

  /**
   * This function is used to Set the Old district id
   */
  SetOldistrackid(type) {
    if (type === 'open') {
      this.oldIsTrackId = localStorage.getItem('istrackid');
    } else {
      localStorage.setItem('istrackid', this.oldIsTrackId);
    }
  }


  /**
   * This function is used to create the new folder
   */
  createNewFolder(type,Title?) {
    if (type == 'delete') {
      this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
        disableClose: true,
        width: '100%',
      });
      this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to delete?';
      this.confirmDialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.isGenrateReport = true;
          let folder = localStorage.getItem("Folder");
          const folderPath = folder ? folder + '/' + this.TemplateGenerateData.TEMPLATENAME : '/' + this.TemplateGenerateData.TEMPLATENAME;
          let postData = { FormAction: "delete", DATA: { FOLDERNAME: folderPath } };
          let RefreshIdArray: any = ['#refreshTemplatelistTab'];
          this.SetVariableForRetry(postData, 'TemplateFolder', RefreshIdArray);
          this._mainAPiServiceService.getSetData(postData, 'TemplateFolder').subscribe(res => {
            if (res.STATUS == "success" && res.CODE == 200) {
              $('#refreshTemplatelistTab').click();
              this.toastr.success('Deleted successfully');
            }
            this.isGenrateReport = false;
          });
        }
        this.confirmDialogRef = null;
      });
    } else {
      let dialogRef: any = this._matDialog.open(FolderCreateComponent, { disableClose: true, panelClass: 'Document-dialog', data: {Title:Title, type: type, temlateData: this.TemplateGenerateData } });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          $('#refreshTemplatelistTab').click();
        }
      });
    }
  }

  /**
   * This function is used to set the Variable For Retry
   */
  SetVariableForRetry(PostData: any, Methode: any, RefreshArray: any) {
    this.oldIsTrackId = localStorage.getItem('istrackid');
    this.DeletePostData = PostData;
    this.ApiMethod = Methode;
    this.refreshId = RefreshArray;
    localStorage.setItem('istrackid', 'DeleteDataClick');
  }


  /**
   * This functionis used to open the Email template popup
   */
  EmailTempletePopUp(actionType) {
    this.SetOldistrackid('open');
    let EmailPopdata = {}
    if (actionType == 'new') {
      EmailPopdata = { action: actionType }
    } else if (actionType == 'edit' || actionType == 'copy') {
      EmailPopdata = { action: actionType }
    }
    const dialogRef = this._matDialog.open(EmailDailogComponent, {
      disableClose: true,
      panelClass: 'Email-dialog',
      data: EmailPopdata
    });
    dialogRef.afterClosed().subscribe(result => {
      this.SetOldistrackid('close');
      if (result) {
        $('#refreshEmailTab').click();
      }
    });
  }

  /**
   * This function is used to open the pack module.
   */
  PackModule(actionType) {
    this.SetOldistrackid('open');
    let PackPopdata = {}
    if (actionType == 'new') {
      PackPopdata = { action: actionType }
    } else {
      PackPopdata = { action: actionType }
    }
    const dialogRef = this._matDialog.open(PacksDailogComponent, {
      disableClose: true,
      panelClass: 'Pack-dialog',
      data: PackPopdata
    });
    dialogRef.afterClosed().subscribe(result => {
      this.SetOldistrackid('close');
      if (result) {
        $('#refreshKitTab').click();
      }
    });
  }

  /**
   * This function is used to delete the packs
   */
  DeletePack(): void {
    this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
      disableClose: true,
      width: '100%',
    });
    this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to delete?';
    this.confirmDialogRef.afterClosed().subscribe(result => {
      if (result) {
        let postData = { ACTION: "delete", data: { KITGUID: this.KitGUid } }
        let RefreshIdArray: any = ['#refreshKitTab'];
        this.SetVariableForRetry(postData, 'document-pack', RefreshIdArray);
        this._mainAPiServiceService.getSetData(postData, 'document-pack').subscribe(res => {
          if (res.STATUS == "success" && res.CODE == 200) {
            localStorage.setItem('istrackid', this.oldIsTrackId);
            $('#refreshKitTab').click();
            this.toastr.success('Deleted successfully');
          }
        });
      }
    });
  }
  
  /**
   * This function is used to Delete the Email Templates
   */
  DeleteEmailTemplete() {
    this.behaviorService.EmailGenerateData$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(result => {
        if (result) {
            this.EmailtemplateData = result;
        }
    });
    this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
        disableClose: true,
        width: '100%',
    });
    this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to delete?';
    this.confirmDialogRef.afterClosed().subscribe(result => {
        if (result) {
            let postData = { FormAction: "delete", data: { EMAILGUID: this.EmailtemplateData.EMAILGUID } }
            let RefreshIdArray: any = ['#refreshEmailTab'];
            this.SetVariableForRetry(postData, 'template-email', RefreshIdArray);
            this._mainAPiServiceService.getSetData(postData, 'template-email').subscribe(res => {
                if (res.STATUS == "success" && res.CODE == 200) {
                    localStorage.setItem('istrackid', this.oldIsTrackId);
                    $('#refreshEmailTab').click();
                    this.toastr.success('Deleted successfully');
                } else if (res.CODE == 451 && res.STATUS == 'warning') {
                    this.toastr.warning(res.MESSAGE);
                } else if (res.CODE == 450 && res.STATUS == 'error') {
                    this.toastr.error(res.MESSAGE);
                } else if (res.MESSAGE == 'Not logged in') {
                    this.toastr.error(res.MESSAGE);
                    this.dialogRef.close(false);
                }
            });
        }
        this.confirmDialogRef = null;
    });
}

/**
 * This function is used to on search the data value.
 */
  onSearch(search) {
    if (search['key'] === "Enter" || search == 'Enter') {
      this.behaviorService.MatterTemplatePopupSearch$.next({
        'search': this.searchData,
        'type': (this.selectedTabIndex == 0) ? 'Template' : (this.selectedTabIndex == 1) ? 'Emails' : (this.selectedTabIndex == 2) ? 'Packs' : (this.selectedTabIndex == 3) ? 'precedents' : (this.selectedTabIndex == 4) ? 'courtForms'  : (this.selectedTabIndex == 5) ? 'LEXON FORM' : ''
      });
    }
  }

  /**
   * This function is used to reload the data.
   */
  reloadData(){
    this.behaviorService.MatterTemplatePopupReload$.next({
      'type': (this.selectedTabIndex == 0) ? 'Template' : (this.selectedTabIndex == 1) ? 'Emails' : (this.selectedTabIndex == 2) ? 'Packs' : (this.selectedTabIndex == 3) ? 'precedents' : (this.selectedTabIndex == 4) ? 'courtForms' : (this.selectedTabIndex == 5) ? 'LEXON FORM':''
    });
  }

  /**
   * This functionis used to Open the url
   */
  OpenUrl(){
  var url = "https://api10.silq.com.au/Fields.html"
  window.open(url);
  }

  
/**
 * This function is used to get the Folder
 */
  getFolder(postData, level) {
    postData.MATTERGUID = this.matterData.MATTERGUID;
    let Data = postData;
    this.sub1 = this._mainAPiServiceService.getSetData({ "Action": "NetDriveGetMatterFolders", Data }, 'cloud-storage').subscribe(res => {
      if (res.CODE == 200 && res.STATUS == "success") {
        this.isLoadingResults = false;
        let fol: any = res.DATA.FOLDERS;
        if (level == 'rootLevel') {
          fol.forEach(e => {
            e.hasChild = true;
            e.expanded = false;
            // let tempData = e;
            this.FolderListArray.push(e);
            // tempData.FOLDERS.forEach(element => {
            //   element.hasChild = true;
            //   element.expanded = false;
            //   element.pid = tempData.FULLPATH;
            //   this.FolderListArray.push(element);
            // });
          });
          this.fields = { dataSource: this.FolderListArray, value: 'FULLPATH', text: 'FOLDERNAME', parentValue: "pid", hasChildren: 'hasChild' };
          // this.fields = { dataSource: this.FolderListArray, value: 'FULLPATH', text: 'FOLDERNAME', parentValue: "pid", hasChildren: 'hasChild' };
        } else {
          fol.forEach(element => {
            element.hasChild = true;
            element.expanded = false;
            element.pid = postData.FOLDERNAME;
            this.FolderListArray.push(element);
          });
          this.expandexNodelist = this.expandexNodelist.filter((element, i) => i === this.expandexNodelist.indexOf(element));
          this.FolderListArray.forEach(item => {
            if (this.expandexNodelist.includes(item.FULLPATH) || item.FULLPATH == "<MatterFolder>") {
              item.expanded = true;
              item.hasChild = true;
            } else {
              item.hasChild = true;
              item.expanded = false;
            }
          });
          this.dropdownTree.refresh();
          (this.dropdownTree as any).treeObj.nodeExpanding = this.TriggerFolderList.bind(this);
          this.dropdownTree.showPopup();
          this.fields = { dataSource: this.FolderListArray, value: 'FULLPATH', text: 'FOLDERNAME', parentValue: "pid", hasChildren: 'hasChild' };
        }
      }
    }, err => {
      this.isLoadingResults = false;
      this.toastr.error(err);
    });
  }

  /**
   * This function is used to create the tree 
   */
  created() {
    if (this.dropdownTree && (this.dropdownTree as any).treeObj) {
      (this.dropdownTree as any).treeObj.nodeExpanding = this.TriggerFolderList.bind(this);
      this.dropdownTree.hidePopup();
    }
    // (this.dropdownTree as any).treeObj.nodeExpanding = this.TriggerFolderList.bind(this);
  }

  /**
   * This function is used to Trigger the Folder list 
   */
  TriggerFolderList(arges) {
    if (!this.expandexNodelist.includes(arges.nodeData.id) && arges.nodeData.id != "<MatterFolder>") {
      this.expandexNodelist.push(arges.nodeData.id);
      this.getFolder({ DONTRECURSESUBFOLDERS: false, DONTADDROOTENTRY: true, FOLDERNAME: arges.nodeData.id }, 'subFolderLevel');
    }
  }
  
  /**
   * This function is used to change the folder
   */
  FloderChnage(event: any) {

    let data = event.itemData
    this.DocumentFolder = data.id

    // this.FolderData = $event.itemData;
  }
  // ENd EJS drop sown tree

  OpenLexonFormAgreement(){
    const dialogRef = this._matDialog.open(LexonFormAgreementComponent, {
      width: "100%",
      disableClose: true,
      data: { },
    });
    dialogRef.afterClosed().subscribe((result) => {
         if (result) {
          this.Showlexonforms=true;
          localStorage.setItem('lexon_forms','yes');
          this.setLexonForms();
         }else{
          this.selectTab(0);
         }
        this.SetOldistrackid('close');
    });
  }

  setLexonForms(){
    this.Showlexonforms=true;
    this.LoadData({ "TEMPLATETYPE": "LEXON AREA OF LAW" });
    this.menuList = [
      {
        id: 1,
        name: 'Generation',
        active: true
      }
    ];
    this.tabs.forEach(tab => {
      if (tab.tabNum == this.selectedTabIndex) {
        tab.active = true;
      } else {
        tab.active = false;
      }
    });
    switch (this.selectedTabIndex) {
      case 0:
        this._data.url = this.dblClickUrl('Document');
        break;
      case 1:
        this._data.url = this.dblClickUrl('Email');
        break;
      default:
        this._data.url = this.dblClickUrl('Document');
    }


    $(".header_menu").click(function () {
      $("popup_ftr").fadeIn();
    });
    setTimeout(() => {
      $('#matter-doc-template').hide();
      $('#filters').hide();
      $('#filters-matter-popup').hide();
    }, 100);
  }
}
