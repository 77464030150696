import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { fuseAnimations } from 'src/@fuse/animations';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import * as moment from 'moment';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { FuseConfirmDialogComponent } from 'src/@fuse/components/confirm-dialog/confirm-dialog.component';
import { MatterDialogComponent } from '../matter-dialog/matter-dialog.component';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { BehaviorService } from '@_services/Behavior.service';
import { TimersService } from '@_services/timers.service';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { GlobalFunctionsService } from '@_services/global-functions.service';
import { TooltipService } from '@_services/tooltip.service';
import { AppPermissionsPipe } from '@_pipes/app-permissions.pipe';
import { TierPermissionPipe } from '@_pipes/tier-permission.pipe';
import { CurrentUser } from 'src/app/_models/CurrentUser';

@Component({
    selector: 'app-resume-timer',
    templateUrl: './resume-timer.component.html',
    styleUrls: ['./resume-timer.component.scss'],
    providers: [AppPermissionsPipe, TierPermissionPipe],

    animations: fuseAnimations
})

export class ResumeTimerComponent implements OnInit, OnDestroy {
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
    rateid: any = ''
    rateidarray: any = [''];
    resumeTimerForm: FormGroup;
    timerTime: any;
    errorWarningData: any = {};
    errorWarningDataArray: any = {}
    successMsg = 'Time entry update successfully';
    timeStops: any = [];
    isLoadingResults: any = false;
    isspiner: any = false;
    userList: any;
    matterShortName: any;
    priviousQuntitychnage: any;
    storePreviousDate: any;
    testBool: boolean = false;
    toolTipList: any
    ActiveTimerData: any = { SHORTNAME: '', MATTERGUID: '', secound: '', WORKITEMGUID: '' };
    ActivityList: any = [
        { 'ACTIVITYID': 'hh:mm', 'DESCRIPTION': 'hh:mm' },
        { 'ACTIVITYID': 'Hours', 'DESCRIPTION': 'Hours' },
        { 'ACTIVITYID': 'Minutes', 'DESCRIPTION': 'Minutes' },
        { 'ACTIVITYID': 'Days', 'DESCRIPTION': 'Days' },
        { 'ACTIVITYID': 'Units', 'DESCRIPTION': 'Units' },
        { 'ACTIVITYID': 'Fixed', 'DESCRIPTION': 'Fixed' }
    ];
    LookupsList: any = [];
    filteredOptions: Observable<string[]>;
    calculateData: any = { MatterGuid: '', QuantityType: '', Quantity: '', FeeEarner: '', FeeType: '' };

    toolTipPostion = "above";
    RequestGuid: string;
    Deliverable: any;
    DeliverableField: any;
    DerivableDropShow: boolean = false;
    isDisabled = false;
    currentUser: CurrentUser = JSON.parse(localStorage.getItem('currentUser'));
    appPermissions: any = JSON.parse(localStorage.getItem('app_permissions'));
    sub: Subscription;

    constructor(public dialogRef: MatDialogRef<ResumeTimerComponent>,
        public MatDialog: MatDialog,
        private behaviorService: BehaviorService,
        private _formBuilder: FormBuilder,
        private toastr: ToastrService, public datepipe: DatePipe,
        private Timersservice: TimersService,
        private _mainAPiServiceService: MainAPiServiceService,
        public globalFunService: GlobalFunctionsService,
        @Inject(MAT_DIALOG_DATA) public resumeTimerData: any,
        private tierpermission: TierPermissionPipe,
        public tooltipService: TooltipService,
    ) {
        localStorage.setItem('istrackid', 'ResumeTimerComponent');
        this.timeStops = this.getTimeStops('01:00', '23:59');
        this.behaviorService.dialogClose$.subscribe(result => {
            if (result != null) {
                if (result.MESSAGE == 'Not logged in') {
                    this.dialogRef.close(false);
                }
            }
        });
        this.behaviorService.APIretryCall$.subscribe((result) => {
            if (localStorage.getItem('istrackid') == 'ResumeTimerComponent' && result['click'] && result['data']['workitem']) {
                this.ngOnInit();
            }

            if (localStorage.getItem('istrackid') == 'ResumeTimerComponent' && result['click'] && result['data']['workitem']) {
                this.SaveClickTimeEntry();
            }
        });
    }

     /**
     * It runs once after the component's view has been fully initialized.
    */
    ngOnInit() {

        this.behaviorService.DELIVERABLES$.subscribe(data => {
            this.DeliverableField = data
            if (this.DeliverableField == "No") {
                this.DerivableDropShow = true
            } else {
                this.DerivableDropShow = false
            }
        })




        this._mainAPiServiceService.getSetData({ Action: 'getdata', Filters: { 'LookupType': 'Rates' } }, 'lookup').subscribe(responses => {
            if (responses.CODE === 200 && responses.STATUS === 'success') {
                localStorage.setItem('Rates_lookups', JSON.stringify(responses.DATA.LOOKUPS));
                this.rateid = responses.DATA.LOOKUPS;
            }
        });
        let today = new Date();
        // let time = today.toLocaleTimeString().replace(/([\d]+:[\d]{2})(:[\d]{2})(.*)/, "$1$3");
        // let time = today.toTimeString();
        // let todayTime = new Date();
        let timeToday = today.toLocaleTimeString().replace(/([\d]+:[\d]{2})(:[\d]{2})(.*)/, "$1$3");
        // const datetext = time.split(' ')[0];
        // let date_a = moment(datetext, "HH:mm");
        // let date_a_str = date_a.format("HH:mm");
        this.resumeTimerForm = this._formBuilder.group({
            MATTERGUID: ['', Validators.required],
            matterautoVal: [''],
            INVOICEGUID: [''],
            ITEMTYPE: [''],
            QUANTITYTYPE: ['Hours'],
            ITEMDATE: [this.datepipe.transform(today, 'dd/MM/yyyy'), Validators.required],
            ITEMDATETEXT: [today],
            FEEEARNER: [(this.currentUser.WORKINGONBEHALFOFGUID) ? this.currentUser.WORKINGONBEHALFOFGUID : this.currentUser.UserId],
            QUANTITY: [''],
            PRICE: ['', { updateOn: 'blur' }],
            PRICEINCGST: ['', { updateOn: 'blur' }],
            SendPRICEINCGST: [''],
            SendPRICE: [''],
            ITEMTIME: [(this.currentUser.PREFILLTIMEOFDAY) ? moment(timeToday, 'hh:mm A').format("HH:mm") : ''],
            ADDITIONALTEXTSELECT: [''],
            ADDITIONALTEXT: ['', Validators.required],
            COMMENT: [''],
            RATEID: ['0'],
            MATTERDELIVERABLEGUID: [''],
        });
        this.isLoadingResults = true;
        let workerGuid;

        // this.loadMatterDeliverable(this.resumeTimerData.matterData.MATTERGUID);
        if (this.resumeTimerData.type != 'resume') {
            this.behaviorService.workInProgress$.subscribe(workInProgressData => {

                if (workInProgressData) {
                    workerGuid = workInProgressData.WORKITEMGUID;
                } else {
                    workerGuid = localStorage.getItem('edit_WORKITEMGUID');
                }
            });
        } else {
            workerGuid = this.resumeTimerData.matterData.WORKITEMGUID;
        }
        this.isLoadingResults = true;
        let userList = JSON.parse(localStorage.getItem('users_list'));
        if (userList && userList != null) {
            this.userList = this.Timersservice.removeUSerWithoutId(userList);
            this.isLoadingResults = false;
        } else {
            this.Timersservice.GetUsers({}).subscribe(res => {
                if (res.CODE == 200 && res.STATUS == "success") {
                    this.userList = this.Timersservice.removeUSerWithoutId(res.DATA.USERS);
                    localStorage.setItem('users_list', JSON.stringify(res.DATA.USERS));
                } else if (res.MESSAGE == 'Not logged in') {
                    this.dialogRef.close(false);
                } else {
                    this.userList = [];
                }
                this.isLoadingResults = false;
            }, err => {
                this.toastr.error(err);
            });
        }
        this.isLoadingResults = true;
        let LookupsList = JSON.parse(localStorage.getItem('lookups_list'));
        if (LookupsList && LookupsList != null) {
            this.isLoadingResults = false;
            this.LookupsList = LookupsList;
            this.filteredOptions = this.f.ADDITIONALTEXTSELECT.valueChanges.pipe(startWith(''), map(value => this._filter(value)));
        } else {
            this.Timersservice.GetLookupsData({ Action: 'getdata', Filters: { 'LookupType': 'Time Entry' } }).subscribe(res => {
                if (res.CODE == 200 && res.STATUS == "success") {
                    this.LookupsList = res.DATA.LOOKUPS;
                    localStorage.setItem('lookups_list', JSON.stringify(res.DATA.LOOKUPS));
                    this.filteredOptions = this.f.ADDITIONALTEXTSELECT.valueChanges.pipe(startWith(''),
                        //  map(value => this._filter(value))
                        map(value => typeof value === 'string' ? value : value),
                        map(value => value ? this._filter(value) : this.LookupsList.slice()));
                } else if (res.MESSAGE == 'Not logged in') {
                    this.dialogRef.close(false);
                } else {
                    this.LookupsList = [];
                }
                this.isLoadingResults = false;
            }, err => {
                this.toastr.error(err);
            });
        }
        let currentUser: CurrentUser = JSON.parse(localStorage.getItem('currentUser'));
        switch (currentUser.DefaultQuantityType) {
            case 'X': {
                this.resumeTimerForm.controls['QUANTITYTYPE'].setValue('hh:mm')
                break;
            }
            case 'H': {
                this.resumeTimerForm.controls['QUANTITYTYPE'].setValue('Hours')
                break;
            }
            case 'M': {
                this.resumeTimerForm.controls['QUANTITYTYPE'].setValue('Minutes')
                break;
            }
            case 'D': {
                this.resumeTimerForm.controls['QUANTITYTYPE'].setValue('Days')
                break;
            }
            case 'U': {
                this.resumeTimerForm.controls['QUANTITYTYPE'].setValue('Units')
                break;
            }
            case 'F': {
                this.resumeTimerForm.controls['QUANTITYTYPE'].setValue('Fixed')
                break;
            }
            default: {
                this.calculateData.FeeType = event;
                this.calculateData.QuantityType = 'F';
                break;
            }
        }
        if (this.resumeTimerData.type == 'stopResume') {
            let temmatterData = this.resumeTimerData.matterData;
            this.loadMatterDeliverable(temmatterData.matterguid)
            this.ActiveTimerData = { SHORTNAME: temmatterData.matter_id, MATTERGUID: temmatterData.matterguid, secound: temmatterData.time, WORKITEMGUID: '' };
            this.matterShortName = temmatterData.matter_id;
            this.timerTime = this.secondsToHms(temmatterData.time);
            let currentUser = JSON.parse(localStorage.getItem('currentUser'));
            // let Set_currentUser = JSON.parse(localStorage.getItem('set_active_matters'));
            
            this.resumeTimerForm.patchValue({
                matterautoVal: temmatterData.matter_id,
                // matterautoVal: Set_currentUser.SELECTEDDESCRIPTION,
                MATTERGUID: temmatterData.matterguid,
                // QUANTITYTYPE: 'hh:mm',
                QUANTITY: this.timerTime,
                FEEEARNER: (currentUser.WORKINGONBEHALFOFGUID) ? currentUser.WORKINGONBEHALFOFGUID : currentUser.UserId,
                ITEMDATETEXT: new Date(),
                ITEMDATE: this.datepipe.transform(new Date(), 'dd/MM/yyyy'),
            });
            if (temmatterData.ADDITIONALTEXT) {
                this.resumeTimerForm.controls['ADDITIONALTEXTSELECT'].setValue(temmatterData.ADDITIONALTEXT);
                this.resumeTimerForm.controls['ADDITIONALTEXT'].setValue(temmatterData.ADDITIONALTEXT);
            }
            if (temmatterData.startTimeForDefult) {
                let ttyData = this.convert12to24Fomat(temmatterData.startTimeForDefult);
                this.resumeTimerForm.controls['ITEMTIME'].setValue(ttyData);
            } else {
                const tt = (this.currentUser.PREFILLTIMEOFDAY) ? moment(timeToday, 'hh:mm A').format("HH:mm") : '';
                this.resumeTimerForm.controls['ITEMTIME'].setValue(tt);
            }
            if (temmatterData.COMMENT) {
                this.resumeTimerForm.controls['COMMENT'].setValue(temmatterData.COMMENT);
            }
            if (temmatterData.RATEID.toString()) {
                this.resumeTimerForm.controls['RATEID'].setValue(temmatterData.RATEID.toString());
            }
            this.calculateData = { MatterGuid: temmatterData.matterguid, QuantityType: currentUser.DefaultQuantityType, Quantity: this.timerTime, FeeEarner: (currentUser.WORKINGONBEHALFOFGUID) ? currentUser.WORKINGONBEHALFOFGUID : currentUser.UserId, FeeType: '' };
            // if (this.f.QUANTITYTYPE.value == "Units") {
            this.priviousQuntitychnage = this.getQtyType(this.f.QUANTITYTYPE.value);
            let sendData = {
                FromQuantityType: 'X',
                toQuantityType: this.getQtyType(this.f.QUANTITYTYPE.value),
                Quantity: this.timerTime
            }
            this.Timersservice.ChangeQuantityType(sendData).subscribe(response => {
                if (response.CODE == 200 && response.STATUS == "success") {
                    this.resumeTimerForm.controls['QUANTITY'].setValue(response.DATA.QUANTITY);
                    this.matterChange('MatterGuid', temmatterData.matterguid);
                    this.isLoadingResults = false;
                } else if (response.MESSAGE == 'Not logged in') {
                    this.dialogRef.close(false);
                }
            }, err => {
                this.isLoadingResults = false;
                this.toastr.error(err);
            });
            // }
        } else if (this.resumeTimerData.type == 'new') {
            let temmatterData = this.resumeTimerData.matterData
            this.ActiveTimerData = { SHORTNAME: temmatterData.SHORTNAME, MATTERGUID: temmatterData.MATTERGUID, secound: '0', WORKITEMGUID: '' };
            this.matterShortName = this.resumeTimerData.matterData.SHORTNAME;
            this.resumeTimerForm.controls['matterautoVal'].setValue((!this.resumeTimerData.matterData.SELECTEDDESCRIPTION || this.resumeTimerData.matterData.SELECTEDDESCRIPTION == '') ? [] : (this.resumeTimerData.matterData.SELECTEDDESCRIPTION));
            this.resumeTimerForm.controls['MATTERGUID'].setValue(this.resumeTimerData.matterData.MATTERGUID);

            this.loadMatterDeliverable(this.resumeTimerData.matterData.MATTERGUID);

            this.timerTime = "00:00:00";
        } else {
            this.isLoadingResults = true;
            this.Timersservice.getTimeEnrtyData({ 'WorkItemGuid': workerGuid }).subscribe(response => {
                if (response.CODE == 200 && response.STATUS == "success") {
                    let timeEntryData = response.DATA.WORKITEMS[0];
                    this.loadMatterDeliverable(timeEntryData.MATTERGUID)
                    this.toolTipList = response.DATA.FIELDTIPS

                    this.resumeTimerForm.controls['MATTERDELIVERABLEGUID'].setValue(timeEntryData.MATTERDELIVERABLEGUID);


                    this.isLoadingResults = false;



                    let isT: boolean = (timeEntryData.QUANTITYTYPE == "hh:mm" || timeEntryData.QUANTITYTYPE == "Hours" || timeEntryData.QUANTITYTYPE == "Minutes" || timeEntryData.QUANTITYTYPE == "Units");
                    if (!isT && timeEntryData.INVOICEGUID == "") {
                        this.toastr.error("You cannot resume a timer that is not HH:MM, Minutes, Hours or Units or Units. or Time entry has invoiced");
                        this.dialogRef.close(false);
                        return false;
                    }
                    let QUANTITYTEM: any = "";
                    if (this.resumeTimerData.type == 'resume') {
                        this.timerTime = this.resumeTimerData.matterData.time;
                        this.Timersservice.ChangeQuantityType({ FromQuantityType: "X", toQuantityType: this.getQtyType(timeEntryData.QUANTITYTYPE), Quantity: this.resumeTimerData.matterData.time }).subscribe(response2 => {
                            if (response2.CODE == 200 && response2.STATUS == "success") {
                                let a = this.timerTime.split(':');
                                let seconds = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);
                                this.ActiveTimerData = { SHORTNAME: timeEntryData.SHORTNAME, MATTERGUID: timeEntryData.MATTERGUID, secound: seconds, WORKITEMGUID: timeEntryData.WORKITEMGUID };

                                this.resumeTimerForm.controls['QUANTITY'].setValue(response2.DATA.QUANTITY);

                                this.calculateData.FeeEarner = timeEntryData.FEEEARNER;
                                this.matterChange('MatterGuid', timeEntryData.MATTERGUID);
                                this.matterChange('QuantityType', timeEntryData.QUANTITYTYPE);
                            }
                        }, (err) => {
                            this.toastr.error(err);
                        });
                    } else if (timeEntryData.QUANTITYTYPE == "Hours") {
                        QUANTITYTEM = timeEntryData.QUANTITY;
                        this.timerTime = this.secondsToHms(timeEntryData.QUANTITY * 60 * 60);
                        let a = this.timerTime.split(':');
                        let seconds = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);
                        this.ActiveTimerData = { SHORTNAME: timeEntryData.SHORTNAME, MATTERGUID: timeEntryData.MATTERGUID, secound: seconds, WORKITEMGUID: timeEntryData.WORKITEMGUID };
                    } else if (timeEntryData.QUANTITYTYPE == 'Minutes') {
                        QUANTITYTEM = timeEntryData.QUANTITY;
                        this.timerTime = this.secondsToHms(timeEntryData.QUANTITY * 60);
                        let a = this.timerTime.split(':');
                        let seconds = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);
                        this.ActiveTimerData = { SHORTNAME: timeEntryData.SHORTNAME, MATTERGUID: timeEntryData.MATTERGUID, secound: seconds, WORKITEMGUID: timeEntryData.WORKITEMGUID };
                    } else if (timeEntryData.QUANTITYTYPE == 'Units') {
                        QUANTITYTEM = timeEntryData.QUANTITY;
                        this.Timersservice.ChangeQuantityType({ FromQuantityType: "U", toQuantityType: 'M', Quantity: timeEntryData.QUANTITY }).subscribe(response2 => {
                            if (response2.CODE == 200 && response2.STATUS == "success") {
                                let temQ = response2.DATA.QUANTITY;
                                this.timerTime = this.secondsToHms(Number(temQ) * 60);
                                let a = this.timerTime.split(':');
                                let seconds = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);
                                this.ActiveTimerData = { SHORTNAME: timeEntryData.SHORTNAME, MATTERGUID: timeEntryData.MATTERGUID, secound: seconds, WORKITEMGUID: timeEntryData.WORKITEMGUID };
                            } else if (response2.MESSAGE == 'Not logged in') {
                                this.dialogRef.close(false);
                            }
                        }, err => {
                            this.toastr.error(err);
                        });
                    } else {
                        QUANTITYTEM = timeEntryData.QUANTITY;
                        this.timerTime = timeEntryData.QUANTITY + ':00';
                        let a = this.timerTime.split(':');
                        let seconds = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);
                        this.ActiveTimerData = { SHORTNAME: timeEntryData.SHORTNAME, MATTERGUID: timeEntryData.MATTERGUID, secound: seconds, WORKITEMGUID: timeEntryData.WORKITEMGUID };
                    }
                    this.matterShortName = response.DATA.WORKITEMS[0].SHORTNAME;
                    localStorage.setItem('edit_WORKITEMGUID', response.DATA.WORKITEMS[0].WORKITEMGUID);
                    if (timeEntryData.ITEMTYPE == "2" || timeEntryData.ITEMTYPE == "3") {
                        this.resumeTimerForm.controls['QUANTITYTYPE'].setValue(timeEntryData.FEETYPE);
                    } else {
                        this.resumeTimerForm.controls['QUANTITYTYPE'].setValue(timeEntryData.QUANTITYTYPE);
                    }
                    this.resumeTimerForm.controls['matterautoVal'].setValue((!timeEntryData.SELECTEDDESCRIPTION || timeEntryData.SELECTEDDESCRIPTION == '') ? '' : (timeEntryData.SELECTEDDESCRIPTION));
                    if (QUANTITYTEM && QUANTITYTEM != "") {
                        this.resumeTimerForm.controls['QUANTITY'].setValue(QUANTITYTEM);
                    }
                    this.resumeTimerForm.controls['MATTERGUID'].setValue(timeEntryData.MATTERGUID);
                    this.resumeTimerForm.controls['ITEMTYPE'].setValue(timeEntryData.ITEMTYPE);
                    this.resumeTimerForm.controls['INVOICEGUID'].setValue(timeEntryData.INVOICEGUID);
                    // let ttyData = (timeEntryData.ITEMTIME !== "") ? moment(timeEntryData.ITEMTIME, 'hh:mmA') : '';
                    // this.resumeTimerForm.controls['ITEMTIME'].setValue((ttyData) ? moment(ttyData).format('hh:mm A') : '');
                    if(timeEntryData.ITEMTIME) {
                        let ttyData = this.convert12to24Fomat(timeEntryData.ITEMTIME);
                        this.resumeTimerForm.controls['ITEMTIME'].setValue(ttyData);
                    } else {
                        // let today = new Date();
                        // let time = today.toTimeString();
                        // const datetext = time.split(' ')[0];
                        // let date_a = moment(datetext, "HH:mm");
                        // let date_a_str = date_a.format("HH:mm");
                        // console.log('date_a_str',date_a_str);
                        const tt = (this.currentUser.PREFILLTIMEOFDAY) ? moment(timeToday, 'hh:mm A').format("HH:mm") : '';
                        this.resumeTimerForm.controls['ITEMTIME'].setValue(tt);
                    }
                    this.resumeTimerForm.controls['FEEEARNER'].setValue(timeEntryData.FEEEARNER);
                    let tempDate = timeEntryData.ITEMDATE.split("/");
                    this.resumeTimerForm.controls['ITEMDATE'].setValue(timeEntryData.ITEMDATE);
                    this.resumeTimerForm.controls['ITEMDATETEXT'].setValue(new Date(tempDate[1] + '/' + tempDate[0] + '/' + tempDate[2]));

                    this.resumeTimerForm.controls['SendPRICEINCGST'].setValue(timeEntryData.PRICEINCGST);
                    this.resumeTimerForm.controls['SendPRICE'].setValue(timeEntryData.PRICE);
                    this.resumeTimerForm.controls['PRICEINCGST'].setValue((Number(timeEntryData.PRICEINCGST)).toFixed(2));
                    this.resumeTimerForm.controls['PRICE'].setValue((Number(timeEntryData.PRICE)).toFixed(2));

                    this.resumeTimerForm.controls['ADDITIONALTEXT'].setValue(timeEntryData.ADDITIONALTEXT);
                    this.resumeTimerForm.controls['ADDITIONALTEXTSELECT'].setValue(timeEntryData.ADDITIONALTEXT);
                    this.resumeTimerForm.controls['COMMENT'].setValue(timeEntryData.COMMENT);
                    this.resumeTimerForm.controls['RATEID'].setValue(timeEntryData.RATEID.toString());
                } else if (response.MESSAGE == 'Not logged in') {
                    this.isLoadingResults = false;
                    this.dialogRef.close(false);
                }
                this.isLoadingResults = false;
            }, err => {
                this.isLoadingResults = false;
                this.toastr.error(err);
            });
        }
        let tempError: any = [];
        let tempWarning: any = {};

        tempError['ADDITIONALTEXT'] = {};
        tempError['ITEMDATE'] = {};

        this.errorWarningData = { "Error": tempError, 'Warning': [tempWarning] };
        this.errorWarningDataArray = { "Error": tempError, 'Warning': [tempWarning] };
        this.sub = this.resumeTimerForm.valueChanges.subscribe((form) => {
            const newFormControl = form;
            if (newFormControl.ADDITIONALTEXTSELECT) {
                newFormControl.ADDITIONALTEXT = newFormControl.ADDITIONALTEXTSELECT
            }
            this.changeForControl(newFormControl)
        })

        if (this.appPermissions[2]) {
            if (this.appPermissions[2]['Override WIP Price'] == 1) {
                this.resumeTimerForm.controls['PRICEINCGST'].enable();
                this.resumeTimerForm.controls['PRICE'].enable();
            } else {
                this.resumeTimerForm.controls['PRICEINCGST'].disable();
                this.resumeTimerForm.controls['PRICE'].disable();

            }
        }
        this.setTooltipData()
    }

    ngOnDestroy(): void {
        this.sub?.unsubscribe();
    }

    /**
     * This function is used to set the tooltip value
     */
    async setTooltipData() {
        let TooltipData = JSON.parse(localStorage.getItem('FieldTooltipData'));
        if (TooltipData && TooltipData['WorkItems']) {
            this.toolTipList = TooltipData['WorkItems'];
        } else {
            this.tooltipService.setToolTipData = ('WorkItems');
        }
    };

    /**
     * This function is used to update the toggle Update data value
     */
    ToggleForUpadte() {
        this.testBool = !this.testBool;
        this.setTooltipData()
    };

    /**
     * This function is used to Convert the time value
     */
    convert12to24Fomat(time: string): string {
        if(time && time !=null)
        var [shours, sminutes] = time.match(/([0-9]{1,2}):([0-9]{2})/).slice(1);
        var hours = Number(shours);
        var minutes = Number(sminutes);
        var AMPM = time.slice(-2);
        if (AMPM == "PM" && hours < 12) hours = hours + 12;
        if (AMPM == "AM" && hours == 12) hours = hours - 12;
        var sHours = hours.toString();
        var sMinutes = minutes.toString();
        if (hours < 10) sHours = "0" + sHours;
        if (minutes < 10) sMinutes = "0" + sMinutes;
        return sHours + ":" + sMinutes;
    }

    /**
     * This function is used to change For control data value
     */
    changeForControl(formControl) {
        if (formControl.ITEMDATETEXT) {
            formControl.ITEMDATE = formControl.ITEMDATETEXT
        }
        const dubObj = { ...this.errorWarningDataArray.Error };
        let element = this.errorWarningData.Error;
        for (const [key, value] of Object.entries(dubObj)) {
            const KEY = (key).toLocaleUpperCase();

            if (formControl[KEY] != undefined && formControl[KEY] != null && formControl[KEY]) {
                delete element[key];
                dubObj[key] = value;
                this.errorWarningDataArray.Error = { ...dubObj }
            } else if (!formControl[KEY]) {
                element[key] = dubObj[key];
            }
        }

        const dubObj_1 = { ...this.errorWarningDataArray.Warning };
        let element_1 = this.errorWarningData.Warning;
        for (const [key, value] of Object.entries(dubObj_1)) {
            const KEY = (key).toLocaleUpperCase();
            if (formControl[KEY] != undefined && formControl[KEY] != null && formControl[KEY]) {
                delete element_1[key];
                dubObj_1[key] = value;
                this.errorWarningDataArray.Warning = { ...dubObj_1 }
            } else if (!formControl[KEY]) {
                element_1[key] = dubObj_1[key];
            }
        }
    }

    /**
     * this function is used to set the default filter data value
     */
    private _filter(value: string): string[] {
        const filterValue = value.toLowerCase();
        return this.LookupsList.filter(option => (option.LOOKUPFULLVALUE.toLowerCase().indexOf(filterValue) === 0));
        // return this.LookupsList.filter(option => (option.LOOKUPFULLVALUE.toLowerCase().includes(filterValue)));
    }

    /**
     * This function is used to get the Qty type
     */
    getQtyType(event) {
        let temQuantityType: any;
        switch (event) {
            case 'hh:mm': {
                temQuantityType = 'X';
                break;
            }
            case 'Hours': {
                temQuantityType = 'H';
                break;
            }
            case 'Minutes': {
                temQuantityType = 'M';
                break;
            }
            case 'Days': {
                temQuantityType = 'D';
                break;
            }
            case 'Units': {
                temQuantityType = 'U';
                break;
            }
            case 'Fixed': {
                temQuantityType = 'F';
                break;
            }
            default: {
                temQuantityType = 'F';
                break;
            }
        }
        return temQuantityType;
    }

    /**
     * This function is used to seconds to Hms
     */
    secondsToHms(d: any) {
        d = Number(d);
        var hours = Math.floor(d / 3600) < 10 ? ("00" + Math.floor(d / 3600)).slice(-2) : Math.floor(d / 3600);
        var minutes = ("00" + Math.floor((d % 3600) / 60)).slice(-2);
        var seconds = ("00" + (d % 3600) % 60).slice(-2);
        return hours + ":" + minutes + ":" + seconds;
    }

    /**
     * This function is used to calcPE data value
     */
    calcPE(e) {
        this.resumeTimerForm.controls['PRICEINCGST'].setValue(Number(this.f.PRICE.value * 1.1).toFixed(2),{
            onlySelf: true,
            emitEvent: true,
            emitViewToModelChange: true,
            emitModelToViewChange: true,
          });
        this.resumeTimerForm.controls['SendPRICEINCGST'].setValue(Number(this.f.PRICE.value * 1.1).toFixed(6));
    }

    /**
     * This function is used to calcPI
     */
    calcPI(e) {
        // if (e.keyCode == 9 && e.key == 'Tab') {
        //     return;
        // }
        this.resumeTimerForm.controls['PRICE'].setValue((this.f.PRICEINCGST.value / 1.1).toFixed(2),{
            onlySelf: true,
            emitEvent: true,
            emitViewToModelChange: true,
            emitModelToViewChange: true,
          });
        this.resumeTimerForm.controls['SendPRICE'].setValue((this.f.PRICEINCGST.value / 1.1).toFixed(6));
    }

    /**
     * This function is used to set the resume date value 
     */
    resumeDate(type: string, event: MatDatepickerInputEvent<Date>) {
        this.resumeTimerForm.controls['ITEMDATE'].setValue(this.datepipe.transform(event.value, 'dd/MM/yyyy'));
    }

    /**
     * This function is used to get the Time Stops
     */
    getTimeStops(start, end) {
        const startTime = moment(start, 'HH:mm');
        const endTime = moment(end, 'HH:mm');
        if (endTime.isBefore(startTime)) {
            endTime.add(1, 'day');
        }
        const timeStops = [];
        while (startTime <= endTime) {
            timeStops.push(moment(startTime).format('HH:mm A'));
            startTime.add(15, 'minutes');
        }
        return timeStops;
    }

    /**
     * This function is used to change the matter data value
     */
    matterChange(key: any, event: any) {
        if (key == "MatterGuid") {
            this.resumeTimerForm.controls['MATTERGUID'].setValue(event);
            this.calculateData.MatterGuid = event;
        } else if (key == "FeeEarner") {
            this.calculateData.FeeEarner = event;
        } else if (key == "QuantityType") {
            switch (event) {
                case 'hh:mm': {
                    this.calculateData.QuantityType = 'X';
                    break;
                }
                case 'Hours': {
                    this.calculateData.QuantityType = 'H';
                    break;
                }
                case 'Minutes': {
                    this.calculateData.QuantityType = 'M';
                    break;
                }
                case 'Days': {
                    this.calculateData.QuantityType = 'D';
                    break;
                }
                case 'Units': {
                    this.calculateData.QuantityType = 'U';
                    break;
                }
                case 'Fixed': {
                    this.calculateData.QuantityType = 'F';
                    break;
                }
                default: {
                    this.calculateData.FeeType = event;
                    this.calculateData.QuantityType = 'F';
                    break;
                }
            }
        }

        this.priviousQuntitychnage = this.calculateData.QuantityType
        this.calculateData.Quantity = this.f.QUANTITY.value;
        this.calculateData.QuantityType = this.f.QUANTITYTYPE.value;
        this.calculateData.MatterGuid = this.f.MATTERGUID.value;


        if (this.calculateData.MatterGuid != '' && this.calculateData.Quantity != '' && (this.calculateData.QuantityType != '' || this.calculateData.FeeType != '')) {
            this.isLoadingResults = true;
            this.Timersservice.calculateWorkItems(this.calculateData).subscribe(response => {
                if (response.CODE == 200 && response.STATUS == "success") {
                    let CalcWorkItemCharge = response.DATA;
                    this.resumeTimerForm.controls['PRICE'].setValue(Number(CalcWorkItemCharge.PRICE).toFixed(2));
                    this.resumeTimerForm.controls['PRICEINCGST'].setValue(Number(CalcWorkItemCharge.PRICEINCGST).toFixed(2));
                    this.resumeTimerForm.controls['SendPRICE'].setValue(Number(CalcWorkItemCharge.PRICE));
                    this.resumeTimerForm.controls['SendPRICEINCGST'].setValue(Number(CalcWorkItemCharge.PRICEINCGST));
                    this.isLoadingResults = false;
                } else if (response.MESSAGE == 'Not logged in') {
                    this.dialogRef.close(false);
                }
            }, err => {
                this.isLoadingResults = false;
                this.toastr.error(err);
            });
        }
    }

    /**
     * This function is used to change the Quantity type data value.
     */
    quntityTypeChange(key: any, event: any) {
        if (this.f.FEEEARNER.value && key != "FeeEarner") {
            this.calculateData.FeeEarner = this.f.FEEEARNER.value;
        }
        if (key == "MatterGuid") {
            this.resumeTimerForm.controls['MATTERGUID'].setValue(event);
            this.calculateData.MatterGuid = event;
        } else if (key == "FeeEarner") {
            this.calculateData.FeeEarner = event;
        } else if (key == "QuantityType") {
            switch (event) {
                case 'hh:mm': {
                    this.calculateData.QuantityType = 'X';
                    break;
                }
                case 'Hours': {
                    this.calculateData.QuantityType = 'H';
                    break;
                }
                case 'Minutes': {
                    this.calculateData.QuantityType = 'M';
                    break;
                }
                case 'Days': {
                    this.calculateData.QuantityType = 'D';
                    break;
                }
                case 'Units': {
                    this.calculateData.QuantityType = 'U';
                    break;
                }
                case 'Fixed': {
                    this.calculateData.QuantityType = 'F';
                    break;
                }
                default: {
                    this.calculateData.FeeType = event;
                    this.calculateData.QuantityType = 'F';
                    break;
                }
            }
        }
        this.calculateData.Quantity = this.f.QUANTITY.value;
        if (this.calculateData.MatterGuid != '' && this.calculateData.Quantity != '' && (this.calculateData.QuantityType != '' || this.calculateData.FeeType != '')) {
            this.isLoadingResults = true;
            let sendData = {
                // FromQuantityType: 'X',
                FromQuantityType: this.priviousQuntitychnage,
                toQuantityType: this.calculateData.QuantityType,
                // Quantity: this.timerTime
                // Quantity: this.f.QUANTITY.value
                Quantity: this.calculateData.Quantity
            }
            this.Timersservice.ChangeQuantityType(sendData).subscribe(response => {
                if (response.CODE == 200 && response.STATUS == "success") {
                    this.resumeTimerForm.controls['QUANTITY'].setValue(response.DATA.QUANTITY);
                    this.matterChange('QuantityType', event);
                    this.priviousQuntitychnage = response.DATA.TOQUANTITYTYPE
                    this.isLoadingResults = false;
                } else if (response.MESSAGE == 'Not logged in') {
                    this.dialogRef.close(false);
                }
            }, err => {
                this.isLoadingResults = false;
                this.toastr.error(err);
            });
        }
    }
    get f() {
        return this.resumeTimerForm.controls;
    }

    /**
     * This function is used to change the lookup data value
     */
    LookupsChange(val: any) {
        this.resumeTimerForm.controls['ADDITIONALTEXT'].setValue(val);
    }

    /**
     * This function is used to select the matter data value
     */
    public selectMatter() {
        const dialogRef = this.MatDialog.open(MatterDialogComponent, { width: '100%', disableClose: true, data: null });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.loadMatterDeliverable(result.MATTERGUID)
                this.resumeTimerForm.controls['MATTERGUID'].setValue(result.MATTERGUID);
                this.resumeTimerForm.controls['MATTERDELIVERABLEGUID'].setValue(result.MATTERGUID);
                this.resumeTimerForm.controls['matterautoVal'].setValue(result.SELECTEDDESCRIPTION);

                this.ActiveTimerData.SHORTNAME = this.matterShortName = result.SHORTNAME;
                this.ActiveTimerData.MATTERGUID = result.MATTERGUID;
                this.matterChange('MatterGuid', result.MATTERGUID);
            }
        });
    }

    /**
     * This function is used to seva the click time entry data value
     */
    SaveClickTimeEntry() {
        this.RequestGuid = this._mainAPiServiceService.generateUniqSerial();
        this.isspiner = true;
        let temText = this.f.ADDITIONALTEXTSELECT.value;
        let PostData: any = {
            ADDITIONALTEXT: (temText && temText != '') ? temText : this.f.ADDITIONALTEXT.value,
            COMMENT: this.f.COMMENT.value,
            // INVOICEGUID:this.f.INVOICEGUID.value,

            ITEMTYPE: this.f.ITEMTYPE.value,
            ITEMDATE: this.f.ITEMDATE.value,
            ITEMTIME: (this.f.ITEMTIME.value && this.f.ITEMTIME.value != "NaN:NaN") ? this.f.ITEMTIME.value : '',
            MATTERGUID: this.f.MATTERGUID.value,
            PRICE: this.f.PRICE.value,
            PRICEINCGST: this.f.PRICEINCGST.value,
            QUANTITY: this.f.QUANTITY.value,
            RATEID: (this.f.RATEID.value.toString()),
            MATTERDELIVERABLEGUID: (this.f.MATTERDELIVERABLEGUID && this.f.MATTERDELIVERABLEGUID.value) ? this.f.MATTERDELIVERABLEGUID.value : ''
        }
        if (this.currentUser.PRODUCTTYPE !== "Barrister") {
            PostData['FEEEARNER'] = this.f.FEEEARNER.value
        }
        if (this.resumeTimerData.type != 'stopResume') {
            PostData.WorkItemGuid = localStorage.getItem('edit_WORKITEMGUID')
        }
        if (this.f.ITEMTYPE.value == "2" || this.f.ITEMTYPE.value == "3") {
            PostData.FEETYPE = this.f.QUANTITYTYPE.value;
            PostData.QUANTITYTYPE = '';
        } else {
            PostData.QUANTITYTYPE = this.f.QUANTITYTYPE.value;
        }
        let formAction = (this.resumeTimerData.type == 'new' || this.resumeTimerData.type == 'stopResume') ? 'insert' : 'update';
        let PostTimeEntryData: any = { FormAction: formAction, VALIDATEONLY: true, RequestGuid: this.RequestGuid, Data: PostData };
        this.Timersservice.SetWorkItems(PostTimeEntryData).subscribe(res => {
            if (res.CODE == 200 && res.STATUS == "success") {
                this.checkValidation(res.DATA.VALIDATIONS, PostTimeEntryData);
            } else if (res.CODE == 451 && res.STATUS == 'warning') {
                this.checkValidation(res.DATA.VALIDATIONS, PostTimeEntryData);
                this.isspiner = false;
            } else if (res.CODE == 450 && res.STATUS == 'error') {
                this.checkValidation(res.DATA.VALIDATIONS, PostTimeEntryData);
                this.isspiner = false;
            } else if (res.MESSAGE == 'Not logged in') {
                this.dialogRef.close(false);
            }

        }, err => {
            this.isspiner = false;
            this.toastr.error(err);
        });
    }

    /**
     * This function is used to check the validation data
     */
    async checkValidation(bodyData: any, PostTimeEntryData: any) {
        // let errorData: any = [];
        // let warningData: any = [];
        // let tempError: any = [];
        // let tempWarning: any = [];
        // // errorData
        // bodyData.forEach(function (value) {
        //   if (value.VALUEVALID == 'NO' || value.VALUEVALID == 'No') {
        //     errorData.push(value.ERRORDESCRIPTION);
        //     tempError[value.FIELDNAME] = value;
        //   } else if (value.VALUEVALID == 'WARNING') {
        //     tempWarning[value.FIELDNAME] = value;
        //     warningData.push(value.ERRORDESCRIPTION);
        //   }
        // });
        // this.errorWarningData = { "Error": tempError, 'warning': tempWarning };
        // this.errorWarningDataArray = { "Error": tempError, 'warning': tempWarning };
        // if (Object.keys(errorData).length != 0) {
        //   this.toastr.error(errorData);
        //   this.isspiner = false;
        // } else if (Object.keys(warningData).length != 0) {
        //   this.isspiner = false;
        //   // this.toastr.warning(warningData);
        //   this.confirmDialogRef = this.MatDialog.open(FuseConfirmDialogComponent, {
        //     disableClose: true,
        //     width: '100%',
        //     data: warningData
        //   });
        //   this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to Save?';
        //   this.confirmDialogRef.afterClosed().subscribe(result => {
        //     if (result) {
        //       this.isspiner = true;
        //       this.saveTimeEntry(PostTimeEntryData);
        //     }
        //     this.confirmDialogRef = null;
        //   });
        // } else if (Object.keys(warningData).length == 0 && Object.keys(errorData).length == 0) {
        //   this.saveTimeEntry(PostTimeEntryData);

        // }
        // this.isspiner = false;
        await this.globalFunService.checkValidation(bodyData, PostTimeEntryData)
            .subscribe(data => {
                if (data) {
                    this.errorWarningData = data.errorWarningData;
                    this.errorWarningDataArray = data.errorWarningData;
                    if (data.callback) {
                        this.isspiner = true;
                        this.saveTimeEntry(PostTimeEntryData);
                    } else {
                        this.isspiner = false;
                    }
                }
            });
    }

    /**
     * This function is used to Save the Time Entry data value
     */
    saveTimeEntry(PostTimeEntryData: any) {
        PostTimeEntryData.VALIDATEONLY = false;
        this.Timersservice.SetWorkItems(PostTimeEntryData).subscribe(res => {
            if (res.CODE == 200 && res.STATUS == "success") {
                this.toastr.success(this.successMsg);
                this.dialogRef.close(true);
            } else if (res.CODE == 451 && res.STATUS == 'warning') {
                this.toastr.warning(res.MESSAGE);
                this.isspiner = false;
            } else if (res.CODE == 450 && res.STATUS == 'error') {
                this.toastr.warning(res.MESSAGE);
                this.isspiner = false;
            } else if (res.MESSAGE == 'Not logged in') {
                this.dialogRef.close(false);
            }
            this.isspiner = false;
        }, err => {
            this.isspiner = false;
            this.toastr.error(err);
        });
    }

    /**
     * This function is used to start the timer data value
     */
    startTimer() {
        this.ActiveTimerData.ADDITIONALTEXT = (this.f.ADDITIONALTEXTSELECT.value && this.f.ADDITIONALTEXTSELECT.value != '') ? this.f.ADDITIONALTEXTSELECT.value : this.f.ADDITIONALTEXT.value;
        this.ActiveTimerData.COMMENT = this.f.COMMENT.value;
        this.ActiveTimerData.RATEID = this.f.RATEID.value.toString();
        this.ActiveTimerData.startTimeForDefult = this.f.ITEMTIME.value;
        this.ActiveTimerData.SELECTEDDESCRIPTION = (!this.f.matterautoVal.value || this.f.matterautoVal.value == '') ? '' : (this.f.matterautoVal.value);
        this.Timersservice.addTimeEnrtS(this.ActiveTimerData);
        this.dialogRef.close(false);
    }

    /**
     * This function is used to get the time data value
     */
    getTime() {
        let enterVal = this.f.ITEMTIME.value;
        let getArr = enterVal.split(/[\s.]+/);
        let finalVal;
        if (getArr[1]) {
            let num = getArr[1].match(/\d+/g);
            if (!num) {
                return;
            }
            let first2 = num[0].substring(0, 2);
            let letr = getArr[1].match(/[a-zA-Z]+/g);
            let finalSecondStr = first2 + letr[0];
            let newVal = getArr[0] + finalSecondStr;
            finalVal = moment(newVal.toString(), "hmmA").format("HH:mm A")
        } else {
            finalVal = moment(getArr[0], "hA").format("HH:mm A")
        }
        if (finalVal == 'Invalid date') {
            this.resumeTimerForm.controls['ITEMTIME'].setValue(this.storePreviousDate);
        } else {
            this.resumeTimerForm.controls['ITEMTIME'].setValue(finalVal);
            this.storePreviousDate = this.f.ITEMTIME.value;
        }
    }

    /**
     * This function is used to set the reate Id 
     */
    reateId(event) {
        this.calculateData.rateid = this.f.RATEID.value.toString();
        this.matterChange('RateID', event);
    }

    radioChange(event) {
        //  this.timeEntryForm.controls['MATTERDELIVERABLEGUID'].setValue(0);
        // console.log("this.timeEntryForm.controls",this.timeEntryForm.controls);
    }

    /**
     * This function is used to load the matter deliverable data.
     */
    loadMatterDeliverable(input_MATTERGUID: any) {
        const pipe = this.tierpermission.transform('Matter Deliverables');
        if (pipe && input_MATTERGUID && this.currentUser.PRODUCTTYPE != 'Barrister' && pipe.ALLOWED == 1) {
            this.isLoadingResults = true;
            this._mainAPiServiceService.getSetData({ Action: "GetData", Filters: {"INCLUDEBLANKROW": true, MATTERGUID: input_MATTERGUID }, }, 'matter-deliverable').subscribe(responses => {
                if (responses.CODE == "200" && responses.STATUS == 'success') {
                    this.isLoadingResults = false;
                    this.Deliverable = responses.DATA.RECORDS;
                } else {
                    this.isLoadingResults = false;
                }
            }, error => {
                this.isLoadingResults = false;
            });
        }
    }
}
