import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import * as $ from 'jquery';
import { FuseConfirmDialogComponent } from 'src/@fuse/components/confirm-dialog/confirm-dialog.component';
import { GlobalFunctionsService } from '@_services/global-functions.service';
import { BehaviorService } from '@_services/Behavior.service';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { Subject, takeUntil } from 'rxjs';
@Component({
  selector: 'app-calculate-interest-popup',
  templateUrl: './calculate-interest-popup.component.html',
  styleUrls: ['./calculate-interest-popup.component.scss']
})
export class CalculateInterestPopupComponent implements OnInit, OnDestroy {
  invoiceData: any = {};
  invoiceCalData: any = { METHOD: 'Compound', INTERESTAMOUNT: 0, CALCULATEUPTO: '', DATE: '', COMMENT: '' };
  isspiner: boolean;
  isLoadingResults: boolean = false;
  tomorrow = new Date();
  errorWarningData:any= {};
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent, any>;
  private _unsubscribeAll$: Subject<void> = new Subject();
  constructor(private toastr: ToastrService,
    public globalFunService:GlobalFunctionsService,
    public dialogRef: MatDialogRef<CalculateInterestPopupComponent>,
    public datepipe: DatePipe,
    public MatDialog: MatDialog,
    public behaviorService: BehaviorService,
    private _mainAPiServiceService: MainAPiServiceService,
    @Inject(MAT_DIALOG_DATA) public _data: any) {
    localStorage.setItem('istrackid', 'Invoice_CalculateIntrestComponent');
    this.behaviorService.dialogClose$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(result => {
      if (result != null) {
        if (result.MESSAGE == 'Not logged in') {
          this.dialogRef.close(false);
        }
      }
    });
    this.behaviorService.matterInvoice$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(result => {
      if (result) {
        this.invoiceData = result;
      }
    });
    this.behaviorService.APIretryCall$.pipe(takeUntil(this._unsubscribeAll$)).subscribe((result) => {
      if (localStorage.getItem('istrackid') == 'Invoice_CalculateIntrestComponent' && result['click'] && result['data']['utility']) {
        this.CalcInvoiceInterest();
      }
    });
    this.invoiceCalData.DATE = new Date();
    // this.invoiceCalData.CALCULATEUPTO = new Date();
    this.invoiceCalData.CALCULATEUPTO = this.datepipe.transform(new Date(), 'dd/MM/yyyy');
  }

   /**
     * It runs once after the component's view has been fully initialized.
    */
  ngOnInit() {
    this.CalcInvoiceInterest();
  }

  /**
   * This function is used to Destroy the Component
   */
  ngOnDestroy(): void {
      this._unsubscribeAll$.next();
      this._unsubscribeAll$.complete();
  }

  /**
   * This function is used to choosed the date value
   * @param type -type value data
   * @param event -event data value
   */
  choosedDate(type: string, event: MatDatepickerInputEvent<Date>) {
    this.invoiceCalData.CALCULATEUPTO = this.datepipe.transform(event.value, 'dd/MM/yyyy');
    this.CalcInvoiceInterest();
  }

  /**
   * This function is used to Change the method
   * @param val -val data
   */
  MethodChange(val: any) {
    this.invoiceCalData.METHOD = val;
    this.CalcInvoiceInterest();
  }

  /**
   * This function is used to Calc the invoice Intrest data value
   */
  CalcInvoiceInterest():void {
    this.isLoadingResults = true;
    this._mainAPiServiceService.getSetData({"Action": "CalcInvoiceInterest", Data:{ 'INVOICEGUID': this.invoiceData.INVOICEGUID, CALCULATIONMETHOD: this.invoiceCalData.METHOD, CALCULATEDATE: this.invoiceCalData.CALCULATEUPTO }}, 'utility').pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
      if (response.CODE == 200 && response.STATUS == "success") {
        this.isLoadingResults = false;
        this.invoiceCalData.INTERESTAMOUNT = response.DATA.INTEREST;
        this.invoiceCalData.COMMENT = response.DATA.COMMENT;
        // if(this.invoiceData) {
          this.invoiceData.INVOICEDATE = response.DATA.LASTINTERESTDATE; 
        // }
      } else if (response.MESSAGE == 'Not logged in') {
        this.isLoadingResults = false;
        this.dialogRef.close(false);
      }
    }, error => {
      this.isLoadingResults = false;
      this.toastr.error(error);
    });
  }

  /**
   * This function is used to Save the invoice data value
   */
  SaveInvoice():void {
    this.isspiner = true;
    let postData = {
      "FormAction": 'interest',
     "VALIDATEONLY": true,
      "Data": {
        "INVOICEDATE": this.invoiceCalData.CALCULATEUPTO,
        "INVOICETOTAL": this.invoiceCalData.INTERESTAMOUNT,
        "COMMENT": this.invoiceCalData.COMMENT,
        "PARENTINVOICEGUID": this.invoiceData.INVOICEGUID
      }
    };
    this._mainAPiServiceService.getSetData(postData, 'SetInvoice').pipe(takeUntil(this._unsubscribeAll$)).subscribe(res => {
      if (res.CODE == 200 && res.STATUS == 'success') {
        this.checkValidation(res.DATA.VALIDATIONS, postData);
      } else if (res.CODE == 451 && res.STATUS == 'warning') {
        this.checkValidation(res.DATA.VALIDATIONS, postData);
      } else if (res.CODE == 450 && res.STATUS == 'error') {
        this.checkValidation(res.DATA.VALIDATIONS, postData);
      } else if (res.MESSAGE == 'Not logged in') {
        this.dialogRef.close(false);
      }
    }, error => {
      this.isspiner = false;
      this.toastr.error(error);
    });
  }

  /**
   * This function is used to check the valiation data
   * @param bodyData -body data value
   * @param PostInvoiceEntryData -Get the post invoice entry data value.
   */
  async checkValidation(bodyData: any, PostInvoiceEntryData: any) {
    // let errorData: any = [];
    // let warningData: any = [];
    // let tempError: any = [];
    // let tempWarning: any = [];
    // bodyData.forEach(function (value) {
    //   if (value.VALUEVALID == 'No') {
    //     errorData.push(value.ERRORDESCRIPTION);
    //     tempError[value.FIELDNAME] = value;
    //   } else if (value.VALUEVALID == 'Warning') {
    //     if (value.FIELDNAME != "InvoiceCode")
    //       warningData.push(value.ERRORDESCRIPTION);
    //     tempWarning[value.FIELDNAME] = value;
    //   }
    // });
    // this.errorWarningData = { "Error": tempError, 'warning': tempWarning };
    // if (Object.keys(errorData).length != 0) {
    //   this.toastr.error(errorData);
    //   this.isspiner = false;
    // } else if (Object.keys(warningData).length != 0) {
    //   this.isspiner = false;
    //   // this.toastr.warning(warningData);
    //   this.confirmDialogRef = this.MatDialog.open(FuseConfirmDialogComponent, {
    //     disableClose: true,
    //     width: '100%',
    //     data: warningData
    //   });
    //   this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to Save?';
    //   this.confirmDialogRef.afterClosed().subscribe(result => {
    //     if (result) {
    //       this.saveInvoice(PostInvoiceEntryData);
    //     }
    //     this.confirmDialogRef = null;
    //   });
    // } else if (Object.keys(warningData).length == 0 && Object.keys(errorData).length == 0) {
    //   this.saveInvoice(PostInvoiceEntryData);
    // }
    this.isspiner = false;
    await this.globalFunService.checkValidation(bodyData, PostInvoiceEntryData)
    .subscribe(data => {
      if (data) {
        this.errorWarningData = data.errorWarningData;
        // this.errorWarningDataArray = data.errorWarningData;
        if (data.callback) {
          this.saveInvoice(PostInvoiceEntryData);
        }
      }
    });
  }

  /**
   * This function is used to Save the Invoice data
   * @param PostInvoiceEntryData -This function is used to get the Post Invoice entry data value
   */
  saveInvoice(PostInvoiceEntryData: any) {
    PostInvoiceEntryData.VALIDATEONLY = false;
    this._mainAPiServiceService.getSetData(PostInvoiceEntryData, 'SetInvoice').subscribe(res => {
      if (res.CODE == 200 && res.STATUS == "success") {
        this.toastr.success('Save successfully');
        $('#refreshInvoiceTab').click();
        this.dialogRef.close(true);

      } else {
        if (res.CODE == 402 && res.STATUS == 'error' && res.MESSAGE == 'Not logged in') {
          this.dialogRef.close(false);
        }
        this.isspiner = false;
      }
    }, err => {
      this.isspiner = false;
      this.toastr.error(err);
    });
  }
}
