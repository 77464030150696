<div class=" one " fxLayout="column" [formGroup]="matterdetailForm">
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">

        <mat-form-field appearance="outline" fxFlex="33" class="pr-4"
            [ngClass]="{'has-error':errorWarningData.Error?.MATTERTYPE,'has-warning':errorWarningData.Warning?.MATTERTYPE}">
            <mat-label>Matter Type</mat-label>
            <input type="text" placeholder="Matter Type" matInput name="MATTERTYPE" formControlName="MATTERTYPE"
                [matAutocomplete]="auto1" matTooltip="{{(toolTipList)?toolTipList.MATTERTYPE?.HOVER:''}}"
                [matTooltipPosition]="toolTipPostion">
            <mat-autocomplete #auto1="matAutocomplete">
                <mat-option *ngFor="let val of MatterType" value="{{val.LOOKUPFULLVALUE}}">
                    {{val.LOOKUPFULLVALUE}}
                </mat-option>
            </mat-autocomplete>
            <div  *ngIf="testBool && toolTipList.MATTERTYPE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.MATTERTYPE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.MATTERTYPE?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex="33" class="pr-4"
            [ngClass]="{'has-error':errorWarningData.Error?.CLIENTSOURCE,'has-warning':errorWarningData.Warning?.CLIENTSOURCE}">
            <mat-label>Client Source</mat-label>
            <input type="text" placeholder="Client Source" matInput name="CLIENTSOURCE" formControlName="CLIENTSOURCE"
                [matAutocomplete]="auto2" matTooltip="{{(toolTipList)?toolTipList.CLIENTSOURCE?.HOVER:''}}"
                [matTooltipPosition]="toolTipPostion">
            <mat-autocomplete #auto2="matAutocomplete">
                <mat-option *ngFor="let val of ClientSource" value="{{val.LOOKUPFULLVALUE}}">
                    {{val.LOOKUPFULLVALUE}}
                </mat-option>
            </mat-autocomplete>
            <div  *ngIf="testBool && toolTipList.CLIENTSOURCE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.CLIENTSOURCE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.CLIENTSOURCE?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex="33" class="pr-4"
            [ngClass]="{'has-error':errorWarningData.Error?.FIELDOFLAW,'has-warning':errorWarningData.Warning?.FIELDOFLAW}">
            <mat-label>Field Of Law</mat-label>
            <input type="text" placeholder="Field Of Law" aria-label="Number" matInput name="FIELDOFLAW"
                formControlName="FIELDOFLAW" [matAutocomplete]="auto3" matTooltip="{{(toolTipList)?toolTipList.FIELDOFLAW?.HOVER:''}}"
                [matTooltipPosition]="toolTipPostion">
                <div  *ngIf="testBool && toolTipList.FIELDOFLAW" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.FIELDOFLAW.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.FIELDOFLAW?.COPYVALUE:'')">file_copy</mat-icon></div>
            <mat-autocomplete #auto3="matAutocomplete">
                <mat-option *ngFor="let val of FiledOfLaw" value="{{val.LOOKUPFULLVALUE}}">
                    {{val.LOOKUPFULLVALUE}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>

    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">


        <mat-form-field appearance="outline" fxFlex="33" class="pr-4"
            [ngClass]="{'has-error':errorWarningData.Error?.FIELDOFLAW,'has-warning':errorWarningData.Warning?.FIELDOFLAW}">
            <mat-label>Industry</mat-label>
            <input type="text" placeholder="Industry" matInput name="INDUSTRY" formControlName="INDUSTRY"
                [matAutocomplete]="auto4" matTooltip="{{(toolTipList)?toolTipList.INDUSTRY?.HOVER:''}}"
                [matTooltipPosition]="toolTipPostion">
                <div  *ngIf="testBool && toolTipList.INDUSTRY" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.INDUSTRY.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.INDUSTRY?.COPYVALUE:'')">file_copy</mat-icon></div>
            <mat-autocomplete #auto4="matAutocomplete">
                <mat-option *ngFor="let val of Industry" value="{{val.LOOKUPFULLVALUE}}">
                    {{val.LOOKUPFULLVALUE}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>

        <mat-form-field appearance="outline" class="example" fxFlex="60" class="pr-4"
            [ngClass]="{'has-error':errorWarningData.Error?.REFERRERGUID,'has-warning':errorWarningData.Warning?.REFERRERGUID}">
            <mat-label>Referrer</mat-label>
            <input matInput readonly formControlName="REFERRERNAME" matTooltip="{{(toolTipList)?toolTipList.REFERRERGUID?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion">
            <div  *ngIf="testBool && toolTipList.REFERRERGUID" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.REFERRERGUID.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.REFERRERGUID?.COPYVALUE:'')">file_copy</mat-icon></div>
            <mat-icon matSuffix (click)='ContactMatter()' [ngClass]="appPermissions[3]?.Access==0 ? 'disabled-click large-button' : 'large-button'">
                <img src="assets/icons/web_app/icon_contact_d.ico">
            </mat-icon>
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" class="example" fxFlex="40" class="pr-4"
            [ngClass]="{'has-error':errorWarningData.Error?.ARCHIVENO,'has-warning':errorWarningData.Warning?.ARCHIVENO}">
            <mat-label>Archive No </mat-label>
            <input matInput formControlName="ARCHIVENO" matTooltip="{{(toolTipList)?toolTipList.ARCHIVENO?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion">
            <div  *ngIf="testBool && toolTipList.ARCHIVENO" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.ARCHIVENO.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.ARCHIVENO?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" class="example" fxFlex="40" class="pr-4"
            [ngClass]="{'has-error':errorWarningData.Error?.ARCHIVEDATE,'has-warning':errorWarningData.Warning?.ARCHIVEDATE}">
            <mat-label>Archive Date</mat-label>
            <input (dateInput)="ArchiveDate('input', $event)" formControlName="ARCHIVEDATETEXT"
                (dateChange)="ArchiveDate('change', $event)" matInput [matDatepicker]="picker4" matTooltip="{{(toolTipList)?toolTipList.ARCHIVEDATE?.HOVER:''}}"
                [matTooltipPosition]="toolTipPostion">
            <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
            <mat-datepicker #picker4></mat-datepicker>
            <div  *ngIf="testBool && toolTipList.ARCHIVEDATE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.ARCHIVEDATE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.ARCHIVEDATE?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <div fxFlex="18" class="pr-4 button-class">
            <button mat-raised-button color="accent" class="mat-accent" [disabled]="loadArchiveno"
                (click)="ArchiveNow()">
                <mat-spinner *ngIf="loadArchiveno"></mat-spinner>
                Archive Now
            </button>
        </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" class="example" fxFlex="50" class="pr-4"
            [ngClass]="{'has-error':errorWarningData.Error?.ARCHIVEDESTROYEDDUEDATE ,'has-warning':errorWarningData.Warning?.ARCHIVEDESTROYEDDUEDATE }">
            <mat-label>Archive Destroy Due Date </mat-label>
            <!-- <input matInput formControlName="ARCHIVEDESTROYEDDUEDATE " matTooltip="{{(toolTipList)?toolTipList.ARCHIVEDESTROYEDDUEDATE :''}}"
            [matTooltipPosition]="toolTipPostion"> -->
            <input (dateInput)="ArchiveDestroyDueDate('input', $event)" formControlName="ARCHIVEDESTROYEDDUEDATETEXT"
            (dateChange)="ArchiveDestroyDueDate('change', $event)" matInput [matDatepicker]="picker5" matTooltip="{{(toolTipList)?toolTipList.ARCHIVEDESTROYEDDUEDATE?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion">
        <mat-datepicker-toggle matSuffix [for]="picker5"></mat-datepicker-toggle>
        <mat-datepicker #picker5></mat-datepicker>
        <div  *ngIf="testBool && toolTipList.ARCHIVEDESTROYEDDUEDATE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.ARCHIVEDESTROYEDDUEDATE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.ARCHIVEDESTROYEDDUEDATE?.COPYVALUE:'')">file_copy</mat-icon></div>  
        </mat-form-field>
        <mat-form-field appearance="outline" class="example" fxFlex="50" class="pr-4"
            [ngClass]="{'has-error':errorWarningData.Error?.ARCHIVEDESTROYEDDATE,'has-warning':errorWarningData.Warning?.ARCHIVEDESTROYEDDATE}">
            <mat-label>Archive Destroyed Date</mat-label>
            <input (dateInput)="ArchiveDestroyedDate('input', $event)" formControlName="ARCHIVEDESTROYEDDATETEXT"
                (dateChange)="ArchiveDestroyedDate('change', $event)" matInput [matDatepicker]="picker6" matTooltip="{{(toolTipList)?toolTipList.ARCHIVEDESTROYEDDATE?.HOVER:''}}"
                [matTooltipPosition]="toolTipPostion">
            <mat-datepicker-toggle matSuffix [for]="picker6"></mat-datepicker-toggle>
            <mat-datepicker #picker6></mat-datepicker>

            <div  *ngIf="testBool && toolTipList.ARCHIVEDESTROYEDDATE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.ARCHIVEDESTROYEDDATE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.ARCHIVEDESTROYEDDATE?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
      
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" *ngIf="isEditMatter=='edit'">
        <mat-form-field appearance="outline" class="example" fxFlex="100" class="pr-4"
            [ngClass]="{'has-error':errorWarningData.Error?.DOCUMENTFOLDER,'has-warning':errorWarningData.Warning?.REFERRERGUID}" *ngIf="currentUSer?.DOCUMENTSYSTEM != 'NetDocuments'">
            <mat-label>Document Folder </mat-label>
            <input matInput readonly formControlName="DOCUMENTFOLDER" matTooltip="{{(toolTipList)?toolTipList.DOCUMENTFOLDER?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion">
            <div  *ngIf="testBool && toolTipList.DOCUMENTFOLDER" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.DOCUMENTFOLDER.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.DOCUMENTFOLDER?.COPYVALUE:'')">file_copy</mat-icon></div>
            <mat-icon matSuffix (click)="RestFolderPath()" class="float-left reset-path-btn">close</mat-icon>
            <mat-icon matSuffix (click)="OpenFolderListPopup()" class="float-right">
                <img src="assets/icons/web_app/icon_contact_new_d.ico">
            </mat-icon>
        </mat-form-field>

        <mat-form-field appearance="outline" class="example" fxFlex="40" class="pr-4" *ngIf="currentUSer?.DOCUMENTSYSTEM == 'NetDocuments'"
            [ngClass]="{'has-error':errorWarningData.Error?.NETDOCSWORKSPACEID,'has-warning':errorWarningData.Warning?.NETDOCSWORKSPACEID}" >
            <mat-label>NetDocuments Workspace Id </mat-label>
            <input matInput formControlName="NETDOCSWORKSPACEID" matTooltip="{{(toolTipList)?toolTipList.NETDOCSWORKSPACEID?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion">
            <div  *ngIf="testBool && toolTipList.NETDOCSWORKSPACEID" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.NETDOCSWORKSPACEID.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.NETDOCSWORKSPACEID?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
    </div>
</div>