import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { fuseAnimations } from 'src/@fuse/animations';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { SocketService } from '@_services/socket.service';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { BehaviorService } from '@_services/Behavior.service';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { CurrentUser } from 'src/app/_models/CurrentUser';

@Component({
    selector: 'app-report-filter',
    templateUrl: './report-filter.component.html',
    styleUrls: ['./report-filter.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,

})
export class ReportFilterComponent implements OnInit, OnDestroy {
    FilterForm: FormGroup;
    isspiner: boolean = false;
    currantQuarter = {
        "0": "1st Quarter (1 Jan – 31 March)", "1": "1st Quarter (1 Jan – 31 March)", "2": "1st Quarter (1 Jan – 31 March)",
        "3": "2nd Quarter ( 1 April – 30 June)", "4": "2nd Quarter ( 1 April – 30 June)", "5": "2nd Quarter ( 1 April – 30 June)",
        "6": "3rd Quarter  (1 July – 30 Sept)", "7": "3rd Quarter  (1 July – 30 Sept)", "8": "3rd Quarter  (1 July – 30 Sept)",
        "9": "4th Quarter  (1 Oct – 31 Dec)", "10": "4th Quarter  (1 Oct – 31 Dec)", "11": "4th Quarter  (1 Oct – 31 Dec)"
    }
    currentUser: CurrentUser = JSON.parse(localStorage.getItem('currentUser'));
    // webSocket = new WebSocket(environment.MainSocketUrl);
    isLoadingResults: boolean = false;
    isLoadingResultsU: boolean = false;
    isPDFGenerated: boolean = false;
    title: any;
    base_url: any;
    btnText = '';
    progress: any = 5;
    PDF_Generation: any;
    accountTypeData: any;
    dilaogtitle: any;
    private _unsubscribeAll$: Subject<void> = new Subject();
    sub1: Subscription;
    constructor(
        public dialogRef: MatDialogRef<ReportFilterComponent>,
        private _formBuilder: FormBuilder,
        public dialog: MatDialog,
        public datepipe: DatePipe,
        private _mainAPiServiceService: MainAPiServiceService,
        private toastr: ToastrService,
        public behaviorService: BehaviorService,
        private socketService: SocketService
    ) {
        // this.socketService.openWSConnection(this.currentUser.SessionToken, 'ReportGenerateStatus').pipe(takeUntil(this._unsubscribeAll$))
        //     .subscribe(details => {
        //         if (details) {
        //             this.progress = details.PROGRESS;
        //             this.btnText = details.DESCRIPTION;
        //         }
        //     })
        this.socketService.getWebSocketReportGenerationSubject().subscribe((details) => {
            if (details) {
                this.progress = details.PROGRESS;
                this.btnText = details.DESCRIPTION;
            }
        })
        this.behaviorService.TrustDuplicateModuleHandling$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(result => {
            if (result != null) {
                this.accountTypeData = result;
            }
        });
        if (this.accountTypeData.ClickType == 'WithTrust') {
            this.dilaogtitle = 'General Journal Trust Report';
        } else {
            this.dilaogtitle = 'General Journal Report';
        }
    }

    ngOnInit() {

        this.FilterForm = this._formBuilder.group({
            DATERANGE: ['Last Month'],
            FROMDATE: [new Date()],
            TODATE: [new Date()]
        });
        this.dateRangeChnange('Last Month');
    }

    ngOnDestroy(): void {
        this._unsubscribeAll$.next();
        this._unsubscribeAll$.complete();
        this.sub1?.unsubscribe();
    }

    /**
     * This function is used to choosed the date
     */
    choosedDate() {
    }
    get f() {
        return this.FilterForm.controls;
    }
    

/**
 * This function is used to Save thr filters
 */
    SaveFilter() {
        // this.socketService.openSocket(this.currentUser.SessionToken)
        this.isLoadingResults = this.isLoadingResultsU = true;
        const uniqueId = this.socketService.generateUniqueId();
        this.socketService.openSocketReportGeneration(uniqueId);
        let ReportData: any = {
            DateRangeSelection: this.f.DATERANGE.value,
            DateFrom: this.datepipe.transform(this.f.FROMDATE.value, "dd/MM/yyyy"),
            DateTo: this.datepipe.transform(this.f.TODATE.value, "dd/MM/yyyy"),
            REPORTID: 'ReportGeneralJournal'
        }
        if (this.accountTypeData.ClickType == 'WithTrust') {
            ReportData.REPORTID = 'ReportGeneralJournalTrust';
        } else {
            ReportData.REPORTID = 'ReportGeneralJournal';
        }
        ReportData.TrackingGuid = uniqueId;
        //Api Report Generate
       this.sub1 = this._mainAPiServiceService.SetReportGenerate(ReportData, 'ReportGenerate').subscribe(reportgenerateData => {
            if (reportgenerateData.PDF_Generation.CODE == 200 && reportgenerateData.PDF_Generation.STATUS == "success") {
                this.isPDFGenerated = true;
                this.title = this.title;
                this.base_url = environment.ReportUrl;
                this.PDF_Generation = reportgenerateData.PDF_Generation.DATA.PDFFILENAME;
                this.toastr.success('Report generated successfully')
                // this.webSocket.send("unwatch channel " + this.currentUser.SessionToken + " ReportGenerateStatus");
                // this.webSocket.close();
                this.isLoadingResults = this.isLoadingResultsU = false;
                // this.socketService.closeSocket(this.currentUser.SessionToken, "ReportGenerateStatus");
                this.socketService.disconnectWebSocketReportGeneration(uniqueId);
            } else if (reportgenerateData.PDF_Generation.CODE == 415 && reportgenerateData.PDF_Generation.STATUS == 'error') {
                this.toastr.error(reportgenerateData.PDF_Generation.MESSAGE);
                this.dialogRef.close(true);
                this.socketService.disconnectWebSocketReportGeneration(uniqueId);
            }
        }, error => {
            this.toastr.error(error);
            // this.socketService.closeSocket(this.currentUser.SessionToken, "ReportGenerateStatus");
            this.socketService.disconnectWebSocketReportGeneration(uniqueId);
        });
    }
    // openWSConnection(SessionToken: any) {
    //   try {
    //     let demoSoket = this.webSocket;
    //     demoSoket.onopen = (openEvent) => {
    //       demoSoket.send("watch channel " + SessionToken + " ReportGenerateStatus");
    //     };
    //     demoSoket.onclose = (closeEvent) => {
    //     };

    //     demoSoket.onerror = (errorEvent) => {
    //     };

    //     demoSoket.onmessage = (messageEvent) => {
    //       let data = JSON.parse(messageEvent.data);
    //       let details = JSON.parse(data.value);
    //       this.progress = details.PROGRESS;
    //       this.btnText = details.DESCRIPTION;
    //     };
    //   } catch (exception) {
    //     console.error(exception);
    //   }
    // }

    /**
     * This function is used to date Range Change
     */
    dateRangeChnange(val) {
        let FromDate;
        let ToDate;
        let ForFrom = new Date();
        let ForTo = new Date();
        if (val == 'Current Quarter') {
            val = this.currantQuarter[new Date().getMonth()];
        } else if (val == 'Last Quarter') {
            let getCurrantQuarter = this.currantQuarter[new Date().getMonth()];
            let getCurrantQuarterKey: any = Object.keys(this.currantQuarter).find(key => this.currantQuarter[key] === getCurrantQuarter);
            if (getCurrantQuarterKey == 0) {
                val = this.currantQuarter[11];
            } else {
                val = this.currantQuarter[getCurrantQuarterKey - 1];
            }
        }
        switch (val) {
            case 'Last Financial Year': {
                ForFrom.setFullYear(ForFrom.getFullYear() - 1, 6, 1); // setFullYear(year,month,date)

                FromDate = ForFrom;
                ForTo.setFullYear(ForTo.getFullYear(), 5, 30);
                ToDate = ForTo;
                break;
            }
            case 'Current Financial Year': {
                ForFrom.setFullYear(ForFrom.getFullYear(), 6, 1);
                FromDate = ForFrom;

                ForTo.setFullYear(ForTo.getFullYear() + 1, 5, 30);
                ToDate = ForTo;
                break;
            }
            case 'First Half of Current Financial Year': {
                ForFrom.setFullYear(ForFrom.getFullYear(), 6, 1);
                FromDate = this.datepipe.transform(ForFrom, "dd/MM/yyyy");
                ForTo.setFullYear(ForTo.getFullYear(), 11, 31);
                ToDate = this.datepipe.transform(ForTo, "dd/MM/yyyy");
                break;
            }
            case 'Second Half of Current Financial Year': {
                ForFrom.setFullYear(ForFrom.getFullYear() + 1, 0, 1);
                FromDate = this.datepipe.transform(ForFrom, "dd/MM/yyyy");
                ForTo.setFullYear(ForTo.getFullYear() + 1, 5, 30);
                ToDate = this.datepipe.transform(ForTo, "dd/MM/yyyy");
                break;
            }
            case 'Last Calendar Year': {
                ForFrom.setFullYear(ForFrom.getFullYear() - 1, 0, 1);
                FromDate = this.datepipe.transform(ForFrom, "dd/MM/yyyy");
                ForTo.setFullYear(ForTo.getFullYear() - 1, 11, 31);
                ToDate = this.datepipe.transform(ForTo, "dd/MM/yyyy");
                break;
            }
            case 'This Calendar Year': {
                ForFrom.setFullYear(ForFrom.getFullYear(), 0, 1);
                FromDate = this.datepipe.transform(ForFrom, "dd/MM/yyyy");
                ForTo.setFullYear(ForTo.getFullYear(), 11, 31);
                ToDate = this.datepipe.transform(ForTo, "dd/MM/yyyy");
                break;
            }
            case 'First Half of Current Calendar Year': {
                ForFrom.setFullYear(ForFrom.getFullYear(), 0, 1);
                FromDate = this.datepipe.transform(ForFrom, "dd/MM/yyyy");

                ForTo.setFullYear(ForTo.getFullYear(), 5, 30);
                ToDate = this.datepipe.transform(ForTo, "dd/MM/yyyy");
                break;
            }
            case 'Second Half of Current Calendar Year': {
                ForFrom.setFullYear(ForFrom.getFullYear(), 6, 1);
                FromDate = this.datepipe.transform(ForFrom, "dd/MM/yyyy");

                ForTo.setFullYear(ForTo.getFullYear(), 11, 31);
                ToDate = this.datepipe.transform(ForTo, "dd/MM/yyyy");
                break;
            }
            case '1st Quarter (1 Jan – 31 March)': {
                ForFrom.setFullYear(ForFrom.getFullYear(), 0, 1);
                FromDate = ForFrom;

                ForTo.setFullYear(ForTo.getFullYear(), 2, 31);
                ToDate = ForTo;
                break;
            }

            case '2nd Quarter ( 1 April – 30 June)': {
                ForFrom.setFullYear(ForFrom.getFullYear(), 3, 1);
                FromDate = ForFrom;

                ForTo.setFullYear(ForTo.getFullYear(), 5, 30);
                ToDate = ForTo;
                break;
            }
            case '3rd Quarter  (1 July – 30 Sept)': {
                ForFrom.setFullYear(ForFrom.getFullYear(), 6, 1);
                FromDate = ForFrom;

                ForTo.setFullYear(ForTo.getFullYear(), 8, 30);
                ToDate = ForTo;
                break;
            }

            case '4th Quarter  (1 Oct – 31 Dec)': {
                ForFrom.setFullYear(ForFrom.getFullYear(), 9, 1);
                FromDate = ForFrom;

                ForTo.setFullYear(ForTo.getFullYear(), 11, 31);
                ToDate = ForTo;
                break;
            }

            case 'Last Month': {
                ForFrom.setMonth(ForFrom.getMonth() - 1, 1); // setMonth(month,date)
                FromDate = ForFrom;

                ForTo.setMonth(ForTo.getMonth(), 0);
                ToDate = ForTo;
                break;
            }
            case 'Current Month': {
                ForFrom.setMonth(ForFrom.getMonth(), 1); // setMonth(month,date)
                FromDate = ForFrom;

                ForTo.setMonth(ForTo.getMonth() + 1, 0);
                ToDate = ForTo;
                break;
            }
            case 'Date Range': {
                FromDate = FromDate;
                ToDate = FromDate;
                break;
            }
            default: {
                FromDate = FromDate;
                ToDate = FromDate;
                break;
            }
        }
        this.FilterForm.controls['FROMDATE'].setValue(FromDate);
        this.FilterForm.controls["TODATE"].setValue(ToDate);
        if (val == 'Date Range') {
            this.FilterForm.get('FROMDATE').enable();
            this.FilterForm.get('TODATE').enable();
        } else {
            this.FilterForm.get('FROMDATE').disable();
            this.FilterForm.get('TODATE').disable();
        }
    }

}
