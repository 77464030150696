<div class="example-loading-shade" *ngIf="isLoadingResults">
    <mat-spinner [style.zoom]="0.5" *ngIf="isLoadingResults"></mat-spinner>
</div>
<div cdkDrag class="popup_main_div add_edit_contact_main">
    <h2 cdkDragHandle mat-dialog-title> {{(Title == 'Precedent') ? "Precedent Templates" : (Title == 'Lexon') ? 'Lexon Templates' : (Title == 'Court Form') ? 'Court Form Templates' : Title}}
        <button class="btn" mat-icon-button [mat-dialog-close]="false" aria-label="Close dialog">
            <mat-icon>close</mat-icon>
        </button>
    </h2>
    <div mat-dialog-content class="mat-typography main generate-docwrap" fxLayout="column">
        <!--<mat-divider style="position: relative; top: 49px;" *ngIf="type == 0"></mat-divider>-->
        <div fxLayout="column" fxLayoutAlign="start" fxLayout.gt-md="row">
            <!-- <mat-tab-group animationDuration="0ms" [(selectedIndex)]="selectedTabIndex" fxFlex="80"
                (selectedTabChange)="selectTab($event)">
                <mat-tab label="templates">
                    <ng-template mat-tab-label>
                        <a mat-list-item>
                            <mat-icon class="m-icon">
                                <img src="assets/icons/web_app/icon_letters_d.ico">
                            </mat-icon>
                            <span class="icon-word">Templates </span>
                        </a>
                    </ng-template>
                    <div class="document-section">
                        <app-template-list></app-template-list>
                    </div>
                </mat-tab>
                <mat-tab label="emails">
                    <ng-template mat-tab-label>
                        <a mat-list-item>
                            <mat-icon class="m-icon">
                                <img src="assets/icons/web_app/icon_email_d.ico">
                            </mat-icon>
                            <span class="icon-word">Emails </span>
                        </a>
                    </ng-template>
                    <div class="document-section">
                        <app-email-templete></app-email-templete>
                    </div>
                </mat-tab>
                <mat-tab label="packs">
                    <ng-template mat-tab-label>
                        <a mat-list-item>
                            <mat-icon class="m-icon">
                                <img src="assets/icons/web_app/icon_doc_pack_d.ico">
                            </mat-icon>
                            <span class="icon-word">Packs </span>
                        </a>
                    </ng-template>
                    <div class="document-section">
                        <app-packs></app-packs>
                    </div>
                </mat-tab>
                <mat-tab label="precedents">
                    <ng-template mat-tab-label>
                        <a mat-list-item>
                            <mat-icon class="m-icon">
                                <img src="assets/icons/web_app/icon_letter_multi_d.ico">
                            </mat-icon>
                            <span class="icon-word">Precedents</span>
                        </a>
                    </ng-template>
                    <div class="document-section">
                        <app-precedents></app-precedents>
                    </div>
                </mat-tab>
                <mat-tab label="court-forms">
                    <ng-template mat-tab-label>
                        <a mat-list-item>
                            <mat-icon class="m-icon">
                                <img src="assets/icons/web_app/icon_courtforms_d.ico" style="height: 30px !important;">
                            </mat-icon>
                            <span class="icon-word">Court Forms</span>
                        </a>
                    </ng-template>
                    <div class="document-section">
                        <app-court-forms></app-court-forms>
                    </div>
                </mat-tab>
            </mat-tab-group>

            <mat-form-field class="example" class="search small_serach" appearance="outline" fxFlex="20"
                *ngIf="selectedTabIndex == 0 || selectedTabIndex == 1 || selectedTabIndex == 2">
                <mat-label>Search</mat-label>
                <input matInput placeholder="Search">
                <mat-icon matSuffix>search</mat-icon>
            </mat-form-field> -->
            <!-- Without Tab -->
            <!-- <div class="example-loading-shade" >
                <mat-spinner [style.zoom]="0.5" ></mat-spinner>
            </div> -->
            <div class="manage metterdoc_wrap">
                <div class="main_menuwrap">
                    <div class="left_menu"  [ngClass]="type == 1?'active_management' : ''" *ngIf="selectedTabIndex == 0 || selectedTabIndex == 1 || selectedTabIndex == 2">
                        <!-- <div class="leftmenulogo">
                            <span>{{title}}</span>
                        </div> -->
                        <div mat-dialog-content class="mat-typography main main_header" fxLayout="column"
                            style="padding:0px !important">
                            <div fxLayout="column" fxLayoutAlign="start" fxLayout.gt-md="row"
                                style="margin-bottom: 12px !important" class="header_menu">
                                <div fxLayout="row" fxFlex="100" *ngIf="type == 1 && selectedTabIndex == 0 && packsToobar !== 'Template' || type == 1 && selectedTabIndex !== 0 && packsToobar == 'Template'">
                                    <div class="header-box header-bx1" style="display: flex;">
                                        <button mat-button class="matter-edits"
                                            [ngClass]="isGenrateReport || isDocTempletFolder ? 'disabled-click' : ''"
                                            (click)="EditDocument()">
                                            <mat-icon class="main-icon">
                                                <!-- <img src="assets/icons/web_app/Rename48x48.png"> -->
                                                edit
                                            </mat-icon>
                                            <div class="main-text">
                                                <span>Edit Template</span>
                                            </div>
                                        </button>
                                    </div>
                                    <div class="header-box header-bx2">
                                        <!-- <button mat-button
                                            [ngClass]="isGenrateReport || isDocTempletFolder ? 'disabled-click' : ''">
                                            <mat-icon class="main-icon">
                                                    edit
                                            </mat-icon>
                                            <div class="main-text">
                                                <span>Copy Template</span>
                                            </div>
                                        </button> -->

                                        <!-- <button mat-button [ngClass]="isGenrateReport || isDocTempletFolder ? 'disabled-click' : ''">
                                            <mat-icon class="main-icon">
                                                <img src="assets/icons/web_app/Rename48x48.png">
                                            </mat-icon>
                                            <div class="main-text">
                                                <span>Rename Template</span>
                                            </div>
                                        </button> -->

                                        <!-- *ngIf="type == 1 && selectedTabIndex == 0 && packsToobar !== 'Template'" -->
                                        <button mat-button
                                            [ngClass]="!isDocTempletType ? 'disabled-click'  : ''"
                                            (click)="createNewFolder('delete')">
                                            <mat-icon class="main-icon">
                                                <img src="assets/icons/web_app/close-circle48x48.png">
                                            </mat-icon>
                                            <div class="main-text">
                                                <span>Delete Template </span>
                                            </div>
                                        </button>
                                        <button mat-button 
                                            [ngClass]="isGenrateReport || isDocTempletFolder ? 'disabled-click' : ''"
                                            (click)="DownloadTemplates('single')">
                                            <mat-icon class="main-icon">
                                                <img src="assets/icons/web_app/download@2x.png">
                                            </mat-icon>
                                            <div class="main-text">
                                                <span>Download Template</span>
                                            </div>
                                        </button>

                                        <button mat-button
                                            (click)="DownloadTemplates('all')"
                                            [ngClass]="isGenrateReport ? 'disabled-click' : ''">
                                            <mat-icon class="main-icon">
                                                <img src="assets/icons/web_app/download-multiple@2x.png">
                                            </mat-icon>
                                            <div class="main-text">
                                                <span>Download All Templates</span>
                                            </div>
                                        </button>
                                    </div>
                                    <div class="header-box header-bx2 header-bx4">
                                        <button mat-button
                                            (click)="createNewFolder('new','Create New Folder')">
                                            <mat-icon class="main-icon">
                                                <img src="assets/icons/web_app/create_folder.png">
                                            </mat-icon>
                                            <div class="main-text">
                                                <span>Create New Folder</span>
                                            </div>
                                        </button>

                                        <button mat-button
                                            [ngClass]="isDocTempletType ? 'disabled-click'  : ''"
                                            (click)="createNewFolder('update','Rename Folder')">
                                            <mat-icon class="main-icon">
                                                <img src="assets/icons/web_app/Rename48x48.png">
                                            </mat-icon>
                                            <div class="main-text">
                                                <span>Rename Folder</span>
                                            </div>
                                        </button>

                                        <button mat-button
                                            [ngClass]="isDocTempletType ? 'disabled-click'  : ''"
                                            (click)="createNewFolder('delete')">
                                            <mat-icon class="main-icon">
                                                <img src="assets/icons/web_app/close-circle48x48.png">
                                            </mat-icon>
                                            <div class="main-text">
                                                <span>Delete Folder</span>
                                            </div>
                                        </button>
                                        <!-- *ngIf="type == 1 && selectedTabIndex == 0 && packsToobar !== 'Template'" -->
                                    </div>
                                    <div class="header-box header-bx3" *ngIf="type == 1 && selectedTabIndex == 0 && packsToobar !== 'Template'">
                                        <button mat-button (click)="OpenUrl()">
                                            <mat-icon class="main-icon">
                                                <img src="assets/icons/web_app/icon_letters_d.ico">
                                            </mat-icon>
                                            <div class="main-text">
                                                <span>Show Template Fields</span>
                                            </div>
                                        </button>
                                    </div>
                                </div>
                                <div fxLayout="row" fxLayoutAlign="center" fxFlex="100" *ngIf="type == 1 && selectedTabIndex == 1 && packsToobar == '' || type == 1 && selectedTabIndex !== 1 && packsToobar == 'Email'">
                                    <div fxFlex="30">
                                        <button mat-button (click)="EmailTempletePopUp('new')">
                                            <mat-icon class="main-icon">
                                                <img src="assets/icons/web_app/icon_email_new_d.ico">
                                            </mat-icon>&nbsp;&nbsp;&nbsp;
                                            <div class="main-text"><span>New Email Template</span></div>
                                        </button>

                                        <button mat-button (click)="EmailTempletePopUp('edit')">
                                            <mat-icon class="main-icon">
                                                <img src="assets/icons/web_app/icon_email_edit_d.ico">
                                            </mat-icon>&nbsp;&nbsp;&nbsp;
                                            <div class="main-text">
                                                <span>Edit Email Template</span>
                                            </div>
                                        </button>
                                    </div>
                                    <!-- <div fxFlex="10" *ngIf="type == 1 && selectedTabIndex == 1 && packsToobar == '' || type == 1 && selectedTabIndex !== 1 && packsToobar == 'Email'">
                                        
                                    </div> -->
                                        <div fxFlex="30">
                                        <button mat-button (click)="EmailTempletePopUp('copy')">
                                            <mat-icon class="main-icon">
                                                <img src="assets/icons/web_app/icon_email_new_d.ico">
                                            </mat-icon>&nbsp;&nbsp;&nbsp;
                                            <div class="main-text">
                                                <span>Copy Email Template</span>
                                            </div>
                                        </button>

                                        <button mat-button (click)="DeleteEmailTemplete()">
                                            <mat-icon class="main-icon">
                                                <img src="assets/icons/web_app/icon_email_delete_d.ico">
                                            </mat-icon>&nbsp;&nbsp;&nbsp;
                                            <div class="main-text">
                                                <span>Delete Email Template</span>
                                            </div>
                                        </button>
                                    </div>
                                        <!-- <div fxFlex="10" *ngIf="type == 1 && selectedTabIndex == 1 && packsToobar == '' || type == 1 && selectedTabIndex !== 1 && packsToobar == 'Email'">
                                        
                                    </div> -->
                                    <div fxFlex="10">
                                        <button mat-button class="large-button" (click)="signaturDialog()">
                                            <section class="large-button-container">
                                                <mat-icon class="main-icon">
                                                    <img src="assets/icons/web_app/icon_expenses_new_d.ico">
                                                </mat-icon>
                                                <div class="main-text">
                                                    <span>Signatures</span>
                                                </div>
                                            </section>
                                        </button>
                                    </div>
                                </div>

                                <div fxLayout="row" fxLayoutAlign="center" fxFlex="100" *ngIf="type == 1 && selectedTabIndex == 2 && (packsToobar == '' || packsToobar == 'Packs')">
                                    <div fxFlex="30">
                                        <button mat-button (click)="PackModule('new')">
                                            <mat-icon class="main-icon">
                                                <img src="assets/icons/web_app/icon_doc_pack_new_d.ico">
                                            </mat-icon>&nbsp;&nbsp;&nbsp;
                                            <div class="main-text icon-word"><span>New Pack</span></div>
                                        </button>
                                    </div>
                                    <div fxFlex="30">
                                        <button mat-button (click)="PackModule('edit')">
                                            <mat-icon class="main-icon">
                                                <img src="assets/icons/web_app/icon_doc_pack_edit_d.ico">
                                            </mat-icon>&nbsp;&nbsp;&nbsp;
                                            <div class="main-text">
                                                <span class="icon-word">Edit Pack</span>
                                            </div>
                                        </button>
                                    </div>
                                    <div fxFlex="10">
                                        <button mat-button (click)="DeletePack()">
                                            <mat-icon class="main-icon">
                                                <img src="assets/icons/web_app/icon_doc_pack_delete_d.ico">
                                            </mat-icon>&nbsp;&nbsp;&nbsp;
                                            <div class="main-text icon-word">
                                                <span>Delete Pack</span>
                                            </div>
                                        </button>
                                    </div>
                                </div>

                                        <!-- <div fxFlex="13" class="manage-tab"> -->

                                            <!-- <button mat-button *ngIf="type == 0" (click)="selectTab(0)"
                                                [ngClass]="tabs[0].active?'selected-active-tab':''">
                                                <mat-icon class="m-icon">
                                                    <img src="assets/icons/web_app/icon_letters_d.ico">
                                                </mat-icon>
                                                <span class="icon-word">Templates</span>
                                            </button> -->
                                            <!-- <button
                                                *ngIf="type == 1 && selectedTabIndex == 0 && packsToobar !== 'Template' || type == 1 && selectedTabIndex !== 0 && packsToobar == 'Template'"
                                                mat-raised-button color="accent" class="mat-accent" (click)="EditDocument()"
                                                [ngClass]="isGenrateReport || isDocTempletFolder ? 'disabled-click' : ''">
                                                Edit Template
                                            </button> -->
                                        <!-- </div> -->
                                        <!-- fxFlex="10" class="manage-tab management_two" -->
                                        <!-- <div> -->
                                            <!-- <section *ngIf="getTierPermission()">
                                                <button mat-button *ngIf="type == 0" (click)="selectTab(1)"
                                                    [ngClass]="tabs[1].active?'selected-active-tab':''">
                                                    <mat-icon class="m-icon">
                                                        <img src="assets/icons/web_app/icon_email_d.ico">
                                                    </mat-icon>
                                                    <span class="icon-word">Emails </span>
                                                </button>
                                            </section> -->
                                        <!-- </div> -->
                                        <!-- <div fxFlex="10" class="manage-tab management_three"> -->
                                            <!-- <button mat-button *ngIf="type == 0" (click)="selectTab(2)"
                                                [ngClass]="tabs[2].active?'selected-active-tab':''">
                                                <mat-icon class="m-icon">
                                                    <img src="assets/icons/web_app/icon_doc_pack_d.ico">
                                                </mat-icon>
                                                <span class="icon-word">Packs </span>
                                            </button> -->  
                                        <!-- </div> -->


                                        <!-- <div fxFlex="13" class="manage-tab management_four"> -->
                                            <!-- <button mat-button *ngIf="type == 0" (click)="selectTab(3)"
                                                [ngClass]="tabs[3].active?'selected-active-tab':''">
                                                <mat-icon class="m-icon">
                                                    <img src="assets/icons/web_app/icon_letter_multi_d.ico">
                                                </mat-icon>
                                                <span class="icon-word">Precedents</span>
                                            </button> -->

                                            <!-- style="margin: 12px; -->
                                                <!-- padding: 15px;" -->
                                        <!-- </div> -->

                                        <!-- <div fxFlex="13" class="manage-tab management_four">
                                        </div> -->

                                        <!-- <div fxFlex="15" class="manage-tab management_five"> -->
                                            <!-- <button mat-button *ngIf="type == 0 && !isFound" (click)="selectTab(4)"
                                                [ngClass]="tabs[4].active?'selected-active-tab':''">
                                                <mat-icon class="m-icon">
                                                    <img src="assets/icons/web_app/icon_courtforms_d.ico">
                                                </mat-icon>
                                                <span class="icon-word">Court Forms</span>
                                            </button> -->
                                            <!-- <button mat-button
                                                *ngIf="type == 1 && selectedTabIndex == 0 && packsToobar !== 'Template'"
                                                [ngClass]="isDocTempletType ? 'disabled-click'  : ''"
                                                (click)="createNewFolder('delete')">
                                                <mat-icon class="main-icon">
                                                    <img src="assets/icons/web_app/close-circle48x48.png">
                                                </mat-icon>
                                                <div class="main-text">
                                                    <span>Delete<br>Folder</span>
                                                </div>
                                            </button> -->

                                            <!-- <button mat-button class="large-button" (click)="signaturDialog()" *ngIf="type == 1 && selectedTabIndex == 1 && packsToobar == '' || type == 1 && selectedTabIndex !== 1 && packsToobar == 'Email'">
                                                <section class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img src="assets/icons/web_app/icon_expenses_new_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Signatures</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </div> -->
                                        <!-- <div fxFlex="13" class="manage-tab management_six"  *ngIf="type == 1 && selectedTabIndex == 0 && packsToobar !== 'Template'">
                                            

                                        </div> -->

                                        <!-- <div fxFlex="13" class="manage-tab management_six"  *ngIf="type == 1 && selectedTabIndex == 0 && packsToobar !== 'Template'"> -->

                                            <!-- <button mat-button class="selected-active-tab-223" style="margin-top: 12px;
                                            padding: 12px;" [ngClass]="!isDocTempletType ? 'disabled-click'  : ''" (click)="OpenUrl()">
                                                    <section class="selected-active-tab-223" >

                                                        <div class="main-text">
                                                            <span>Show <br>Template<br> Fields</span>
                                                        </div>
                                                    </section>
                                                </button>                -->
                                                <!-- <button mat-button (click)="OpenUrl()"
                                                *ngIf="type == 1 && selectedTabIndex == 0 && packsToobar !== 'Template'">
                                                    <mat-icon class="main-icon">
                                                        <img src="assets/icons/web_app/icon_letters_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Show Template<br>Fields</span>
                                                    </div>
                                                </button> -->
                                        <!-- </div> -->
                            </div>
                            <mat-progress-bar mode="buffer" *ngIf="isGenrateReport" class="buffer-laoder">
                            </mat-progress-bar>
                        </div>
                    </div>
                    <!-- <div *ngIf="selectedTabIndex == 3"> -->
                    <div class="jurisdiction-select" *ngIf="selectedTabIndex == 3 || selectedTabIndex == 4 || (selectedTabIndex == 5 && Showlexonforms)">
                        <div class="precedents_form_drop" [ngClass]="(isHideDiv == true) ? 'dropDownmg' : 'dropDown'">
                            <mat-form-field class="select-drop">
                                <mat-label class="text-whitess">Jurisdiction</mat-label>
                                <mat-select [(ngModel)]="stateSelect" (selectionChange)="changeState()">
                                    <mat-option *ngFor="let val of mainDataArray | keyvalue" [value]="val.key">{{val.key}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div> 
                    <!-- </div> -->
                    <div class="search_input">
                        <button mat-button class="matter-edits" (click)="setHeaderFooter()" *ngIf="type == 1 && selectedTabIndex == 0 && packsToobar !== 'Template' || type == 1 && selectedTabIndex !== 0 && packsToobar == 'Template'">
                            <mat-icon class="main-icon">
                                edit
                            </mat-icon>
                            <div class="main-text">
                                <span>Set headers/ <br> footers</span>
                            </div>
                        </button>
                        <mat-icon class="icon-btn" (click)="reloadData()">refresh</mat-icon>
                        <!-- *ngIf="type ==0" -->
                        <mat-form-field class="example" class="search small_serach" appearance="outline" fxFlex="20">
                            <mat-label>Search</mat-label>
                            <input matInput placeholder="Search" (keyup)="onSearch($event)" [(ngModel)]="searchData">
                            <mat-icon matSuffix>search</mat-icon>
                        </mat-form-field>
                    </div>
                </div>
                <div class="main_bodywrap">
                    <div class="left"  *ngIf="isHideDiv == false">
                        <!-- <mat-list role="list" class="menulist">
                            <mat-list-item role="listitem" class="click-sidebar" *ngFor="let data of menuList"
                                [ngClass]="(data.active)?'active-menu':''" (click)="changeMode(data.name , data.id)">
                                {{data.name}}</mat-list-item>
                        </mat-list> -->

                        <!-- <mat-list-item role="listitem" class="click-sidebar"  (click)="changeMode('Management')">Management</mat-list-item> -->

                        <div fxLayout="column" fxLayoutAlign="start" *ngIf="isHideDiv == false">
                          
                                <!-- *ngIf="type == 0" -->
                                <button mat-button (click)="selectTab(0)"
                                    [ngClass]="tabs[0].active?'selected-active-tab manage-tab':'manage-tab'">
                                    <mat-icon class="m-icon">
                                        <img src="assets/icons/web_app/icon_letters_d.ico">
                                    </mat-icon>
                                    <span class="icon-word">Templates</span>
                                </button>
                         
                                    <!-- *ngIf="type == 0"  -->
                                    <button  *ngIf="getTierPermission()" mat-button (click)="selectTab(1)" class="manage-tab"
                                        [ngClass]="tabs[1].active?'selected-active-tab':''">
                                        <mat-icon class="m-icon">
                                            <img src="assets/icons/web_app/icon_email_d.ico">
                                        </mat-icon>
                                        <span class="icon-word">Emails </span>
                                    </button>
                        
                        
                                <!-- *ngIf="type == 0" -->
                                <button mat-button (click)="selectTab(2)" class=" manage-tab"
                                    [ngClass]="tabs[2].active?'selected-active-tab':''">
                                    <mat-icon class="m-icon">
                                        <img src="assets/icons/web_app/icon_doc_pack_d.ico">
                                    </mat-icon>
                                    <span class="icon-word">Packs </span>
                                </button>
                           
                                <!-- *ngIf="type == 0" -->
                                <button mat-button (click)="selectTab(3)" class=" manage-tab"
                                    [ngClass]="tabs[3].active?'selected-active-tab':''">
                                    <mat-icon class="m-icon">
                                        <img src="assets/icons/web_app/icon_letter_multi_d.ico">
                                    </mat-icon>
                                    <span class="icon-word">Precedents</span>
                                </button>
                            
                                <button  mat-button *ngIf="!isFound" (click)="selectTab(4)" class="manage-tab"
                                    [ngClass]="tabs[4].active?'selected-active-tab':''">
                                    <mat-icon class="m-icon">
                                        <img src="assets/icons/web_app/icon_courtforms_d.ico">
                                    </mat-icon>
                                    <span class="icon-word">Court Forms</span>
                                </button>

                                <button  mat-button *ngIf="!isShowLexonForms" (click)="selectTab(5)" class="manage-tab" 
                                    [ngClass]="tabs[5].active?'selected-active-tab':''">
                                    <mat-icon class="m-icon">
                                        <img style="height: 30px;" src="assets/icons/web_app/Lexon.jpeg">
                                    </mat-icon>
                                    <span class="icon-word">Lexon Forms</span>
                                </button>
                        </div>
                        <br>
                        <!-- <div>

                            <button mat-button class="large-button" style="margin: 12px;
                            padding: 15px;" (click)="signaturDialog()">
                                    <section class="large-button-container">
                                        <mat-icon class="main-icon">
                                            <img src="assets/icons/web_app/icon_expenses_new_d.ico">
                                        </mat-icon>
                                        <div class="main-text">
                                            <span>Signatures</span>
                                        </div>
                                    </section>
                                </button>
                        </div> -->
                    </div>
                    <div class="right">
                        <!-- <div class="example-loading-shade" >
                            <mat-spinner [style.zoom]="0.5" ></mat-spinner>
                        </div> -->
                        <mat-divider class="mat-divider right_divide" *ngIf="selectedTabIndex == 0 || selectedTabIndex == 1 || selectedTabIndex == 2"></mat-divider>
                        <div class="document-section pr-24" *ngIf="selectedTabIndex == 0" id="genetate_tablewrap">
                            <div>
                                <p  style="font-size: 12px;">Current Folder :  <b style="font-size: 12px;">{{pathOfGenerateDocument}}</b></p>
                                <!-- <b style="font-size: 12px;">{{pathOfGenerateDocument}}</b> -->
                            </div>
                            <!-- (click)="UtilityAPI()" -->
                            <app-template-list [popupurl] = "_data.url" (click)="UtilityAPI()"></app-template-list>
                        </div>

                        <div class="document-section pr-24" *ngIf="selectedTabIndex == 1" id="genetate_emailwrap">
                            <app-email-templete [popupurl] = "_data.url"></app-email-templete>
                        </div>

                        <div class="document-section pr-24" *ngIf="selectedTabIndex == 2">
                            <app-packs></app-packs>
                        </div>

                        <div class="document-section innerscrollmain pr-24" *ngIf="selectedTabIndex == 3">
                            <app-precedents (click)="UtilityAPI()"></app-precedents>
                        </div>

                        <div class="document-section innerscrollmain pr-24 courtform-tablewrap" *ngIf="selectedTabIndex == 4">
                            <app-court-forms (click)="UtilityAPI()"></app-court-forms>
                        </div>

                        <div id="genetate_tablewrap" class="document-section innerscrollmain pr-24 courtform-tablewrap" *ngIf="selectedTabIndex == 5 && Showlexonforms">
                            <app-lexon-forms (click)="UtilityAPI()"></app-lexon-forms>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- <div class="manage" *ngIf="type == 1">
            <div mat-dialog-content class="mat-typography main" fxLayout="column" style="padding:0px !important">
                <div fxLayout="column" fxLayoutAlign="start" fxLayout.gt-md="row" *ngIf="type == 1"
                    style="margin-bottom: 12px !important">
                    <div fxFlex="10" class="manage-tab">

                        <mat-icon class="main-icon">
                            <img src="assets/icons/web_app/download@2x.png">
                        </mat-icon>
                        <div class="main-text">
                            <span>Download Single<br>Templates</span>
                        </div>

                    </div>
                    <div fxFlex="10" class="manage-tab">
                        <mat-icon class="main-icon">
                            <img src="assets/icons/web_app/download-multiple@2x.png">
                        </mat-icon>
                        <div class="main-text">
                            <span>Download All<br>Templates</span>
                        </div>
                    </div>
                    <div fxFlex="10" class="manage-tab">
                        <mat-icon class="main-icon">
                            <img src="assets/icons/web_app/create_folder.png">
                        </mat-icon>
                        <div class="main-text">
                            <span>Create<br>New Folder</span>
                        </div>
                    </div>
                    <div fxFlex="10" class="manage-tab">
                        <mat-icon class="main-icon">
                            <img src="assets/icons/web_app/Rename48x48.png">
                        </mat-icon>
                        <div class="main-text">
                            <span>Rename<br>Folder</span>
                        </div>
                    </div>
                    <div fxFlex="10" class="manage-tab">
                        <mat-icon class="main-icon">
                            <img src="assets/icons/web_app/close-circle48x48.png">
                        </mat-icon>
                        <div class="main-text">
                            <span>Delete<br>Folder</span>
                        </div>
                    </div>
                    <div fxFlex="10" class="manage-tab">
                        <mat-icon class="main-icon">
                            <img src="assets/icons/web_app/close-circle48x48.png">
                        </mat-icon>
                        <div class="main-text">
                            <span>Delete<br>Template </span>
                        </div>
                    </div>
                </div>
                <mat-divider *ngIf="type == 1"></mat-divider>
            </div>

            <div class="document-section">
                <app-template-list></app-template-list>
            </div>
        </div> -->
    </div>

    <mat-dialog-actions align="end" class="popup_ftr" *ngIf="isHideDiv == false">
         <!-- [(ngModel)]="item.NEWFILENAME" -->
         <!-- type == 0 &&  -->
        <mat-form-field class="example pr-4" fxFlex="40" class="pr-4" appearance="outline"
       *ngIf="((selectedTabIndex == 0 ||selectedTabIndex ==  3 || selectedTabIndex == 4 || selectedTabIndex == 5)  && packsToobar !== 'Template') || ((selectedTabIndex == 1 || selectedTabIndex == 2) && packsToobar == 'Template')">
        <mat-label>New File Name</mat-label>
        <!-- <div class="filename-container"> -->
            <!-- <span *ngIf="getMatterGuId">{{ getMatterGuId.SHORTNAME }} </span> -->
            <input matInput [(ngModel)]="newFileNameGuid" (input)="changeInput($event)">
        <!-- </div> -->

        </mat-form-field>
        <!-- fxFlex="37.5"  -->
        <div fxFlex="23.5" >
             <!-- <mat-form-field class="example pr-4" fxFlex="30" class="pr-4" appearance="outline"> -->
                <!-- selectedTabIndex !== 0|| selectedTabIndex !==3 || selectedTabIndex !== 4 -->
                <!-- type == 0 &&  -->
            <ejs-dropdowntree #renamepopupdropdowntree *ngIf="((selectedTabIndex == 0 ||selectedTabIndex ==  3 || selectedTabIndex == 4 || selectedTabIndex == 5)  && packsToobar !== 'Template') || ((selectedTabIndex == 1 || selectedTabIndex ==2) && packsToobar == 'Template')"
                        [treeSettings]="treeSettings" (created)="created()"
                        (select)="FloderChnage($event)" showClearButton='true'
                        showDropDownIcon='false' id='renamepopupdropdowntree'
                        [fields]='fields' placeholder='Select a Folder'
                        floatLabelType='Auto'></ejs-dropdowntree>
                        <!-- </mat-form-field> -->
        </div>
        <!-- type == 0  -->
        <button
            *ngIf="(selectedTabIndex == 0 && packsToobar !== 'Template') || (selectedTabIndex !== 0 && packsToobar == 'Template')"
            mat-raised-button color="accent" class="mat-accent" (click)="GenarateDocument()"
            [disabled]="(TemplateGenerateData.TEMPLATETYPE !== 'Template' && packsToobar !== 'Template')?true:false">
            Generate Document
        </button>
        <!-- <button
            *ngIf="type == 1 && selectedTabIndex == 0 && packsToobar !== 'Template' || type == 1 && selectedTabIndex !== 0 && packsToobar == 'Template'"
            mat-raised-button color="accent" class="mat-accent" (click)="EditDocument()"
            [ngClass]="isGenrateReport || isDocTempletFolder ? 'disabled-click' : ''">
            Edit Template
        </button> -->
        <button
            *ngIf="(selectedTabIndex == 1 && packsToobar == '') || (selectedTabIndex !== 1 && packsToobar == 'Email')"
            mat-raised-button color="accent" class="mat-accent" (click)="GenarateEmail()">
            Generate Email
        </button>
        <button *ngIf="selectedTabIndex == 2 && (packsToobar == '' || packsToobar == 'Packs')" mat-raised-button
            color="accent" class="mat-accent" (click)="GenaratePacks()">
            Generate Pack
        </button>
        <button *ngIf="selectedTabIndex == 3" mat-raised-button color="accent" class="mat-accent"
            (click)="GenaratePrecedents()">
            Generate Precedents
        </button>
        <button *ngIf="selectedTabIndex == 4 || selectedTabIndex == 5" mat-raised-button color="accent" class="mat-accent"
            (click)="GenarateCourtForm()">
            Generate Form
        </button>

        <button mat-raised-button color="primary" [mat-dialog-close]="false" cdkFocusInitial>Don't Save</button>
    </mat-dialog-actions>

    <mat-dialog-actions align="end" class="popup_ftr contact_select_footer" *ngIf="isHideDiv == true">
        <button mat-raised-button [mat-dialog-close]="true" color="accent" >Select</button>
        <button mat-raised-button color="primary" [mat-dialog-close]="false" cdkFocusInitial>Cancel</button>
    </mat-dialog-actions>
</div>
